import { getAccessToken } from "axios-jwt";
import CONSTANT from "./constant";

const config = {
   height: 400,
   language: "vi",
   i18n: {
      vi: {
         "Type something": "Nhập nội dung...",
         "Paste as HTML": "Dán dưới dạng HTML",
         "Your code is similar to HTML. Keep as HTML?":
            "Mã của bạn tương tự như HTML. Giữ dưới dạng HTML?",
         Keep: "Giữ",
         "Insert as Text": "Chèn dưới dạng văn bản",
         "Insert only Text": "Chỉ chèn văn bản",
         Cancel: "Hủy bỏ",
         "Drop image": "Kéo thả hình ảnh",
         "or click": "hoặc nhấp vào",
         Upload: "Tải lên",
         Browse: "Mở",
         "Word Paste Detected": "Dán từ văn bản Word",
         "The pasted content is coming from a Microsoft Word/Excel document. Do you want to keep the format or clean it up?":
            "Nội dung dán sao chép từ tài liệu Microsoft Word/Excel. Bạn muốn giữ nguyên định dạng hay xóa nó?",
         Clean: "Xóa định dạng",
      },
   },
   uploader: {
      url: `${CONSTANT.BASE_API}media/filemanager/uploads`,
      headers: {
         Authorization: `Bearer ${getAccessToken()}`,
      },
      data: { type: "product_content" },
      filesVariableName: () => {
         return "files";
      },
      defaultHandlerSuccess: function (data, resp) {
         if (data.files && data.files.length > 0) {
            for (let i = 0; i < data.files.length; i += 1) {
               this.selection.insertImage(data.baseurl + data.files[i], { width: '100%', height: 'auto' });
            }
         }
      },
   },
};
export default { config };
