import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, message, Space, Button, Popconfirm, Form, Select, Input, Switch
} from 'antd';
import moment from 'moment';
import { HomeOutlined, EyeOutlined, SearchOutlined, RedoOutlined, PlusOutlined, CheckOutlined, CloseOutlined,EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import ActionServiceVoucher from './components/action-voucher';
import ModalHistoryVoucher from './components/history-voucher';
const ServiceListVoucher = (props) => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: '#',
            width: '50px',
            dataIndex: 'uid',
        },

        {
            title: 'Tên chương trình',
            width: '170px',
            dataIndex: 'name'
        },
        {
            title: 'Từ ngày', dataIndex: 'from_date', width: '170px',
            render: from_date => `${moment(new Date(from_date)).format("DD/MM/YYYY HH:mm")}`
        },
        {
            title: 'Đến ngày', dataIndex: 'to_date', width: '170px',
            render: to_date => `${moment(new Date(to_date)).format("DD/MM/YYYY HH:mm")}`
        },
        {
            title: 'Phương thức', dataIndex: 'type', width: '170px',
            render: type => type==='count_max' ? "Đếm số lượng tối đa" :type==='generate_code'? "Cấp mã khuyến mãi":''
        },
        {
            title: 'Loại giá trị', dataIndex: 'value_type', width: '170px',
            render: value_type => value_type==='cash' ? "Tiền mặt" : "Phần trăm"
        },
        {
            title: 'Trạng thái', dataIndex: 'status', width: '150px',
            render: (status, record) => <Switch
                checkedChildren={<div><CheckOutlined /> Đã mở</div>}
                unCheckedChildren={<div><CloseOutlined /> Đã đóng</div>}
                defaultChecked={status === true ? true : false}
                onChange={onChangeActive(record._id)}
                loading={activeLoading}
            />
        },

        {
            title: 'Hành động', width: '150px',
            render: record => (
                <Space size="middle">
                    <Button type="link" onClick={() => openHistoryVoucher(record._id)}><EyeOutlined /> Nhật ký</Button>
                    {
                        record.quantity_used == 0 && <Button type="link" onClick={() => openServiceVoucher(record._id)}><EditOutlined /> Sửa</Button>
                    }
                    {
                        record.quantity_used == 0 && <Popconfirm title="Bạn muốn xóa chương trình này?" onConfirm={() => { delVoucher(record._id) }}>
                            <Button type="link" danger><DeleteOutlined /> Xóa</Button>
                        </Popconfirm>
                    }                    
                </Space>
            )
        },
    ];
    const [listService, setListVoucher] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalServiceVisible, setIsModalServiceVisible] = useState(false);
    const [isModalHistoryVisible, setIsModalHistoryVisible] = useState(false);
    const [voucherId, setVoucherId] = useState(null);
    const [activeLoading, setActiveLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0,
        perPage: 1,
        hideOnSinglePage: true,
        showLessItems: true
    });
    const openServiceVoucher = async (data) => {
        await setVoucherId(data);
        setIsModalServiceVisible(true);
    }
    const openHistoryVoucher = async (data) => {
        await setVoucherId(data);
        setIsModalHistoryVisible(true);
    }
    const onChangeActive = (id) => async (value) => {
        setActiveLoading(true);
        if (id) {
            await axiosInstance.patch(`${CONSTANT.API.SERVICE.VOUCHER}/${id}`, { status: value })
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Thay đổi trạng thái thành công', 5);
                        loadVoucher({ page: 1, perPage: 10 });
                    }
                    setActiveLoading(false)
                })
                .catch(error => { message.error('Thay đổi trạng thái không thành công', 5); setActiveLoading(false) })
        }
    }
    const delVoucher = async (id) => {
        setLoading(true);
        await axiosInstance.delete(`${CONSTANT.API.SERVICE.VOUCHER}/${id}`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Xóa chương trình thành công', 5)
                }
                setActiveLoading(false)
            })
            .catch(error => { message.error('Xóa chương trình không thành công', 5); setActiveLoading(false) })
    }
    // effect
    useEffect(() => {
        loadVoucher({ page: 1, perPage: 10 });
    }, []);
    // Xử lý sự kiện search
    const onSearch = async (values) => {
        let options = {};
        if (values.q !== '' && values.q !== undefined) {
            if (values.field === 'name') {
                options.name = values.q;
            }
            else if (values.field === 'uid') {
                if (isNaN(+values.q)) {
                    message.warning('Mã khuyến mãi là kiểu số. Vui lòng kiểm tra lại !');
                    return;
                }
                else {
                    options.uid = values.q
                }
            }
        }        
        loadVoucher({
            page: 1,
            perPage: pagination.pageSize,
            ...options
        });
    }
    const reload = () => {
        loadVoucher({ page: 1, perPage: pagination.pageSize });
        form.resetFields();
    }
    const loadVoucher = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        await axiosInstance.get(`${CONSTANT.API.SERVICE.VOUCHER}?${query}&created_by=${user._id}`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    let _pagination = { ...pagination, current: queryParam.page };
                    _pagination.total = res.data.data.total;
                    setPagination(_pagination);
                    setListVoucher(res.data.data.docs);
                }
            })
            .catch(error => message.warning('Không thể truy cập danh sách khuyến mãi!' + error.message, 5))
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Dịch vụ y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Danh sách chương trình khuyến mãi
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    initialValues={{
                        field: 'name'
                    }}
                    style={{ margin: '0px -8px 20px -8px' }}
                    form={form}
                >
                    <Form.Item name="q">
                        <Input
                            type="text"
                            placeholder="Nhập mã hoặc tên chương trình"
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select
                            style={{ width: 150 }}
                        >
                            <Select.Option value="name">Tên chương trình</Select.Option>
                            <Select.Option value="uid">Mã chương trình</Select.Option>
                        </Select>
                    </Form.Item>                  
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Tải lại trang
                        </Button>
                    </Form.Item><Form.Item>
                        <Button type="primary" primary="true" icon={<PlusOutlined />} data-tour="doctor-1"
                            onClick={() => openServiceVoucher(null)}
                        >
                            Thêm mới
                        </Button>
                    </Form.Item>
                </Form>               
                {
                    listService && <Table
                        dataSource={listService}
                        columns={columns}
                        loading={loading}
                        pagination={{
                            onChange: async (page, pageSize) => {
                                let para = { page, perPage: pageSize };                                
                                loadVoucher(para);
                            },
                            ...pagination
                        }}
                        rowKey={record => record._id}
                    />
                }

            </Layout.Content>
            <ActionServiceVoucher visible={isModalServiceVisible} voucherId={voucherId}
                cancel={(reload) => {
                    setIsModalServiceVisible(false);
                    if (reload) {
                        loadVoucher({ page: 1, perPage: pagination.pageSize });
                        form.resetFields();
                    }
                }} />
            <ModalHistoryVoucher visible={isModalHistoryVisible} voucherId={voucherId}
                cancel={() => {
                    setVoucherId(0);
                    setIsModalHistoryVisible(false);
                }} />
        </div >
    )

}

export default ServiceListVoucher;