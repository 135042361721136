import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Layout, Breadcrumb, message, Button, List, Row, Col, Avatar, Popover,
	Alert, Dropdown, Menu, Form, Select, Input, Modal, Empty, DatePicker
} from 'antd';
import {
	ClockCircleOutlined, UserOutlined, MobileOutlined, UserAddOutlined, BranchesOutlined,
	HomeOutlined, DownOutlined, VideoCameraOutlined, CopyOutlined, CalendarOutlined,
	CheckCircleOutlined, SearchOutlined, RedoOutlined, SwapOutlined, StopOutlined
}
	from '@ant-design/icons';
import moment from 'moment';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';
import { axiosInstance } from '../../../config/api';
const arrStatus = {
	'wait-confirm': { text: "Chờ duyệt", color: "orange", icon: <ClockCircleOutlined /> },
	'confirmed': { text: 'Chấp nhận', color: '#2db7f5', icon: <SwapOutlined /> },
	'change-time': { text: 'Đổi lịch khám', color: 'grey' },
	'completed': { text: 'Đã khám', color: '#87d068', icon: <CheckCircleOutlined /> },
	'not-come': { text: 'Không đến', color: 'grey' },
	'cancelled': { text: 'Từ chối', color: '#f50', icon: <StopOutlined /> },
	'abandoned': { text: 'Khách đã hủy', color: '#f50', icon: <StopOutlined /> }
};
const getRandomColor = () => {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

const ListOrderServiceDoctor = (props) => {
	const [form] = Form.useForm();
	const [listService, setListService] = useState([]);
	const [loading, setLoading] = useState(false);
	const [reasonCancel, setResonCancel] = useState('Có việc đột xuất');
	const [selectedId, setSelectedId] = useState();
	const [calendars, setCalendars] = useState([]);
	const [selectedDate, setSelectedDate] = useState({});
	const [selectedTime, setSelectedTime] = useState({});
	const [selectedTimes, setSelectedTimes] = useState([]);
	const [selectedService, setSelectedService] = useState({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalChangeTimeVisible, setIsModalChangeTimeVisible] = useState(false);
	const [saving, setSaving] = useState(false);
	const [optionFilter, setOptionFiolter] = useState({});
	const [pagination, setPagination] = useState({
		pageSize: 10,
		total: 0,
		perPage: 10,
		hideOnSinglePage: true,
		showLessItems: true
	});

	// effect
	useEffect(() => {
		loadOrder({ page: 1, perPage: 10 });
	}, []);

	const loadOrder = async (queryParam) => {
		setLoading(true);
		let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
		let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
		if (user.is_doctor) {
			await axiosInstance.get(`${CONSTANT.API.DOCTOR.ORDER}?${query}&agency=${user._id}&schedule=true`)
				.then(async res => {
					if (res && res.data && res.data.code === 200) {
						// Set giá trị cho pagination
						let _pagination = { ...pagination };
						_pagination.total = res.data.data.total;
						setPagination(_pagination);

						let docs = res.data.data.docs;
						// Thực hiện nhóm mảng theo ngày
						let results = [];
						let currDate = null;
						let i = 0;
						for (let item of docs) {
							currDate = { day_of_week: item.date.day_of_week, day: item.date.day, month: item.date.month, year: item.date.year };
							if (results.length === 0) results.push({ date: currDate, orders: [item] })
							else if (results[i].date.day === item.date.day && results[i].date.month === item.date.month && results[i].date.year === item.date.year) {
								results[i].orders.push(item);
							}
							else {
								results.push({ date: currDate, orders: [item] }); i++;
							}
						}
						setListService(results);
					}
				})
				.catch(error => {
					setLoading(false);
					setListService([]);
					message.warning('Lỗi dữ liệu nhập không đúng!', 3);
				});
		}
		else {
			message.warning('Bạn chưa đăng ký làm đối tác chuyên môn Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
		}
		setLoading(false);
	}

	// Xử lý sự kiện thay đổi status
	const onChangeStatus = (order) => async (value) => {
		if (value?.key && order?._id) {
			setSelectedId(order?._id);
			if (value.key === 'cancelled') { // Nếu từ chối lịch hẹn
				setIsModalVisible(true);
			}
			else if (value.key === 'change-time') {
				let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
				setSelectedId(order?._id);
				await axiosInstance.get(`${CONSTANT.API.DOCTOR.DOCTOR}?created_by=${user._id}&service_id=${order.items[0]?.service._id}`)
					.then(async res => {
						if (res && res.data && res.data.data.docs && res.data.code === 200) {
							sortCalendar(res.data.data.docs?.calendars)
							console.log(res.data.data.docs);
							setIsModalChangeTimeVisible(true);
						}
					})
					.catch(error => {
						setLoading(false);
						message.warning('Lỗi dữ liệu nhập không đúng!', 3);
					});

			}
			else {
				const dataUpdate = {
					actUpd: "udpStatus",
					status: value.key
				}
				setSaving(true);
				await axiosInstance.patch(`${CONSTANT.API.DOCTOR.ORDER}/${order?._id}`, dataUpdate)
					.then(res => {
						if (res?.data?.code === 200) {
							let mess = 'Cập nhật trạng thái lịch hẹn thành công.';
							if (value.key === 'completed' || value.key === 'not-come')
								mess = `${mess} Lịch hẹn này sẽ được đưa vào danh sách Nhật ký khám chữa bệnh`;
							message.success(mess, 4)
								.then(() => {
									setSaving(false);
									loadOrder({ page: 1, perPage: 10 });
								})
						}
						else {
							setSaving(false);
							message.warning('Cập nhật trạng thái lịch hẹn không thành công. Vui lòng thử lại sau');
						}
					})
					.catch(error => {
						setSaving(false);
						message.warning('Cập nhật trạng thái lịch hẹn không thành công. Vui lòng thử lại sau');
					})
			}

		}

	}
	// Xử lý sự kiện từ chối lịch hẹn
	const okCancel = async () => {
		if (selectedId) {
			const dataUpdate = {
				actUpd: "udpStatus",
				status: 'cancelled',
				reason_cancel: reasonCancel
			}
			setSaving(true);
			await axiosInstance.patch(`${CONSTANT.API.DOCTOR.ORDER}/${selectedId}`, dataUpdate)
				.then(res => {
					if (res?.data?.code === 200) {
						message.success('Từ chối lịch hẹn thành công. Lịch hẹn này sẽ được đưa vào danh sách Nhật ký khám chữa bệnh', 2.5)
							.then(() => {
								resetModal();
								loadOrder({ page: 1, perPage: 10 });
							});
					}
					else {
						message.warning('Từ chối lịch hẹn thất bại. Vui lòng thử lại sau').then(() => setSaving(false))
					}

				})
				.catch(error => message.warning('Từ chối lịch hẹn thất bại. Vui lòng thử lại sau').then(() => setSaving(false)))
		}
	}
	const resetModal = () => {
		setIsModalVisible(false);
		setSelectedId('');
		setResonCancel('Có việc đột xuất')
		setSaving(false);
	}
	const onFinishChangeDateTime = async (values) => {
		let dateChange = JSON.parse(values.date_change);
		delete dateChange.times;
		const dataUpdate = {
			actUpd: "changeTime",
			date_change: dateChange,
			time_change: JSON.parse(values.time_change),
			reason_cancel: values.reason_cancel
		}
		setSaving(true);
		await axiosInstance.patch(`${CONSTANT.API.DOCTOR.ORDER}/${selectedId}`, dataUpdate)
			.then(res => {
				if (res?.data?.code === 200) {
					message.success('Thay đổi lịch hẹn thành công, chờ xác nhận từ bệnh nhân.', 4)
						.then(() => {
							setIsModalChangeTimeVisible(false);
							setSaving(false);
							loadOrder({ page: 1, perPage: 10 });
						})
				}
				else {
					setSaving(false);
					message.warning('Thay đổi lịch hẹn không thành công. Vui lòng thử lại sau');
				}
			})
			.catch(error => {
				setSaving(false);
				message.warning('Thay đổi lịch hẹn không thành công. Vui lòng thử lại sau');
			})
	}
	const handleCancel = () => {
		resetModal();
	};
	const onBlurReason = ({ target: { value } }) => {
		setResonCancel(value)
	}
	const copyToClipboard = (verify_code) => () => {
		navigator.clipboard.writeText(`https://meet.vivmedic.com/${verify_code}`);
		message.success('Đã sao chép!');
	}
	const videoCall = (verify_code) => () => {
		window.open(`https://meet.vivmedic.com/${verify_code}`, '_blank');
	}
	// Xử lý sự kiện search
	const onSearch = async (values) => {
		let options = {};
		if (values.q !== '' && values.q !== undefined) {
			if (values.field === 'name') {
				options.fullname_patient = values.q;
			}

			if (values.field === 'uid') {
				options.uid = values.q.replace('VIVMEDIC-D', '');
			}
		}


		if (values.date_range) {
			options.date_range = JSON.stringify({
				start: moment(values.date_range[0]).format("YYYY-MM-DD"),
				end: moment(values.date_range[1]).format("YYYY-MM-DD")
			});
		}
		loadOrder({
			page: 1,
			perPage: pagination.perPage,
			...options
		});
		setOptionFiolter(options);
	}
	const reload = () => {
		loadOrder({ page: 1, perPage: pagination.perPage });
		form.resetFields();
	}
	const onPaginationChange = async (page) => {
		loadOrder({ page, perPage: pagination.perPage, ...optionFilter });
	}
	// sắp xếp lại calendar
	const sortCalendar = (_calendars) => {
		if (_calendars.length > 0) {
			// sort calendar
			let currentDate = moment(new Date()).format('YYYY-MM-DD');
			let newCalendars = _calendars.filter(c => moment(currentDate).isSameOrBefore(moment(`${c.year}-${c.month}-${c.day}`, 'YYYY-MM-DD')));
			newCalendars.sort((a, b) => (a.month > b.month) ? 1 : (a.month === b.month) ? ((a.day > b.day) ? 1 : -1) : -1);
			setCalendars(newCalendars);
			if (newCalendars.length > 0) {
				let calendar = { ...newCalendars[0] };
				let calendarTimes = [...calendar?.times];
				calendarTimes.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1);
				setSelectedTimes(calendarTimes);
				setSelectedDate({ ...calendar });
				form.setFieldsValue({ date: calendar._id });
			}
			else {
				setSelectedTimes([]);
				form.setFieldsValue({ date: null });
			}

		}
	}
	// Xử lý sự kiện khi chọn ngày
	const onChangeDate = (value, option) => {
		if (value) {
			let calendar = JSON.parse(value);
			getTimes(calendar);
			setSelectedDate({ ...calendar });
		}
	}
	const getTimes = (calendar) => {
		if (calendar) {
			let arrTimes = [];
			if (calendar?.times?.length > 0) {
				for (const time of calendar.times) {
					arrTimes.push({ ...time });
				}
			}
			else {
				message.warning('Tạm thời chưa có lịch khám', 3);
			}
			arrTimes.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1);
			setSelectedTimes(arrTimes);
		}
	}
	return (
		<div>
			<Breadcrumb style={{ margin: '16px 0' }}>
				<Breadcrumb.Item >
					<Link to="/"><HomeOutlined /></Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Dành cho Bác sĩ
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Quản lý lịch hẹn
				</Breadcrumb.Item>
			</Breadcrumb>
			<Layout.Content className="layout-content" style={{ padding: '8px', background: 'none' }}>
				{/* Begin form search */}
				<Form
					layout="inline"
					onFinish={onSearch}
					initialValues={{
						field: 'name'
					}}
					style={{ margin: '0px -8px 20px -8px' }}
					form={form}
				>
					<Form.Item name="q"
						/*required rules={[
							{ required: true, message: 'Vui lòng nhập Tên người khám hoặc Mã lịch hẹn' }
						]}*/
						extra="(VD: Nguyễn Văn A hoặc VIVMEDIC-D123)"
					>
						<Input
							type="text"
							placeholder="Nhập Tên người khám hoặc Mã lịch hẹn "
							style={{ width: 300 }}
						/>
					</Form.Item>
					<Form.Item name="field">
						<Select
							style={{ width: 150 }}
						>
							<Select.Option value="name">Tên người khám</Select.Option>
							<Select.Option value="uid">Mã lịch hẹn</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name="date_range">
						<DatePicker.RangePicker format="DD-MM-YYYY" disabledDate={(current) => current && current < moment().endOf('day')} />
					</Form.Item>
					<Form.Item>
						<Button icon={<SearchOutlined />} type="primary" htmlType="submit">
							Tìm
						</Button>
					</Form.Item>
					<Form.Item>
						<Button icon={<RedoOutlined />} onClick={reload}>
							Tải lại trang
						</Button>
					</Form.Item>
				</Form>
				{/* End form search */}

				<List
					loading={loading}
					dataSource={listService}
					renderItem={list => (
						<>
							<div style={{ fontWeight: 700, margin: '20px 0px 10px 0px' }}>{COMMON.getDayOfWeek(list?.date?.day_of_week.toString())} - {list?.date?.day}/{list?.date?.month}/{list?.date?.year}</div>
							{
								list?.orders?.length > 0 && list.orders.map(item => (
									<Row className="item-doctor-order" gutter={16} key={item._id}>
										<Col xs={24} md={8}>
											<Row align="middle">
												<Col md={3} className="text-center">
													<Avatar style={{ color: '#fff', backgroundColor: `${getRandomColor()}` }}>{item?.contact?.fullname_patients[0] || 'U'}</Avatar>
													<div className="code">{item.uid}</div>
												</Col>
												<Col md={20} className="col-patient">
													<ClockCircleOutlined className="icon" />
													<span className="time">{moment(`${item.time.time_h}:${item.time.time_m}`, "HH:mm").format("HH:mm")}</span>
													<UserOutlined className="icon" />
													<span className="name">{item?.contact?.fullname_patients}</span><br />
													<span className="other">{item?.contact?.gender === 'male' ? 'Nam' : item?.contact?.gender === 'female' ? 'Nữ' : '-'} - {item?.contact?.dob}</span>
												</Col>
											</Row>
										</Col>
										<Col xs={24} md={16} className="col-detail">
											<div>
												<MobileOutlined className="icon" />
												<span className="phone">{item?.contact?.phone}</span>
												{
													item?.contact?.fullname_orderer &&
													<>
														<UserOutlined className="icon" />
														<span className="name">{item?.contact?.fullname_orderer}</span>
													</>
												}
												{
													item?.items?.length > 0 &&
													<>
														<UserAddOutlined className="icon" />
														<span className="name">{COMMON.createDoctorName(item?.items[0]?.doctor?.academic_rank, item?.items[0]?.doctor?.degree, item?.items[0]?.doctor?.fullname)}</span>
													</>
												}

												<BranchesOutlined className="icon" />
												<span className="span cate">{item?.items?.length > 0 && item?.items[0].service?.name}</span>

											</div>
											<div className="note">
												{item.note}
											</div>
											<div className="group-btn">
												{
													item?.items?.length > 0 && item.items[0]?.service?.is_online && (
														<>
															{
																item.status === 'confirmed' ?
																	<Popover
																		content={(
																			<div className="popover-video">
																				<Alert message={`https://meet.vivmedic.com/${item.verify_code}`} type="info" />
																				<div className="group-btn">
																					<Button onClick={videoCall(item.verify_code)} size="small" icon={<VideoCameraOutlined />} type="primary">Thực hiện cuộc gọi</Button>
																					<Button onClick={copyToClipboard(item.verify_code)} size="small" icon={<CopyOutlined />}>Sao chép liên kết</Button>
																				</div>
																			</div>
																		)}
																		placement="bottomLeft"
																		title="Liên kết thực hiện cuộc gọi"
																		trigger="click"

																	>
																		<Button icon={<VideoCameraOutlined />} className="btn-call" type="primary" size="small">Khám từ xa <DownOutlined className="icon" /></Button>
																	</Popover>
																	:
																	<Button disabled={item.status !== 'confirmed'} icon={<VideoCameraOutlined />} className="btn-call" type="primary" size="small">Khám từ xa <DownOutlined className="icon" /></Button>
															}
														</>
													)
												}
												<Button icon={<CalendarOutlined />} size="small">
													{COMMON.getDayOfWeek(item?.date?.day_of_week.toString())} {item?.date?.day}/{item?.date?.month}/{item?.date?.year} - {moment(`${item.time.time_h}:${item.time.time_m}`, "HH:mm").format("HH:mm")}
												</Button>
												{
													item?.date_old && <Popover
														content={(
															<Button icon={<CalendarOutlined />} size="small">
																{COMMON.getDayOfWeek(item?.date_old?.day_of_week.toString())} {item?.date_old?.day}/{item?.date_old?.month}/{item?.date_old?.year} - {moment(`${item.time_old.time_h}:${item.time_old.time_m}`, "HH:mm").format("HH:mm")}
															</Button>
														)}
														placement="bottomLeft"
														title="Lịch cũ trước khi thay đổi"
														trigger="click"

													>
														<Button icon={<CalendarOutlined />} size="small">Lịch cũ</Button>
													</Popover>
												}

												<Dropdown
													overlay={(
														<Menu
															onClick={onChangeStatus(item)}
														>
															{
																Object.entries(arrStatus).map(iStatus => (
																	<React.Fragment key={iStatus[0]}>
																		{
																			iStatus[0] !== 'wait-confirm' && iStatus[0] !== 'change-time' && iStatus[0] !== 'confirmed' && iStatus[0] !== 'abandoned' && (!item.date_old || item?.status === 'confirmed') ?
																				<Menu.Item key={iStatus[0]}>{iStatus[1]?.text}</Menu.Item> :
																				iStatus[0] === 'change-time' && !item.date_old ?
																					<Menu.Item key={iStatus[0]}>{iStatus[1]?.text}</Menu.Item> :
																					iStatus[0] === 'confirmed' && !item.date_old ?
																						<Menu.Item key={iStatus[0]}>{iStatus[1]?.text}</Menu.Item> : ''
																		}
																	</React.Fragment>
																))
															}
														</Menu>
													)}
													trigger={['click']}
												>
													<Button size="small" loading={saving && item._id === selectedId} className="btn-status" style={{ backgroundColor: `${arrStatus[item?.status]?.color}`, borderColor: `${arrStatus[item?.status]?.color}` }} icon={arrStatus[item?.status]?.icon}>
														{arrStatus[item?.status]?.text} <DownOutlined />
													</Button>
												</Dropdown>
											</div>
										</Col>
									</Row>
								))
							}

						</>

					)}
					pagination={{
						...pagination,
						onChange: onPaginationChange
					}}
					locale={{ emptyText: <Empty description="Bác sĩ chưa có lịch hẹn" /> }}
				/>
				<Modal title="Từ chối lịch hẹn"
					visible={isModalVisible}
					okText="Từ chối"
					cancelText="Đóng"
					onOk={okCancel}
					onCancel={handleCancel}
					confirmLoading={saving}
					destroyOnClose={true}
				>
					<Alert style={{ fontSize: 13, marginBottom: 16 }} message="Bác sĩ muốn từ chối Lịch hẹn này. Bác sĩ vui lòng nhập lý do từ chối lịch hẹn này. Xin cảm ơn Bác sĩ!" type="warning" />
					<Input defaultValue={reasonCancel} onBlur={onBlurReason} />
				</Modal>
				<Modal title="Thay đổi lịch hẹn"
					visible={isModalChangeTimeVisible}
					okText="Thay đổi"
					cancelText="Đóng"
					onOk={form.submit}
					onCancel={() => { setIsModalChangeTimeVisible(false); form.resetFields() }}
					confirmLoading={saving}
					destroyOnClose={true}
				>
					<Form
						name="basic"
						layout="vertical"
						onFinishFailed={() => { }}
						onFinish={onFinishChangeDateTime}
						form={form}
					>
						<Row>
							<Col xs={12} md={12}>
								<Form.Item label="Chọn ngày"
									name="date_change"
									rules={[{ required: true, message: 'Vui lòng chọn ngày khám bệnh' }]}
								>
									<Select
										placeholder="Chọn ngày"
										onChange={onChangeDate}
										notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chưa có dữ liệu" />}
									>
										{
											calendars.length > 0 &&
											calendars.map(c => (
												<Select.Option key={c._id} value={JSON.stringify(c)}>{`${COMMON.getDayOfWeek(c.day_of_week.toString())} - ${c.day}/${c.month}/${c.year}`}</Select.Option>
											))
										}

									</Select>
								</Form.Item>
							</Col>
							<Col xs={12} md={12}>
								<Form.Item label="Chọn thời gian"
									name="time_change"
									rules={[{ required: true, message: 'Vui lòng chọn thời gian' }]}
								>
									<Select
										placeholder="Chọn thời gian"
										notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chưa có dữ liệu" />}
									>
										{
											selectedTimes.length > 0 &&
											selectedTimes.map(time => (
												<Select.Option key={time._id} value={JSON.stringify(time)}>{moment(`${time.time_h}:${time.time_m}`, "HH:mm").format("HH:mm")} - {COMMON.calculateTime(`${time.time_h}:${time.time_m}`, time.duration)}</Select.Option>
											))
										}

									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={24}>
								<Form.Item label="Lý do thay đổi lịch"
									name="reason_cancel"
									rules={[{ required: true, message: 'Vui lòng nhập lý do' }]}
								>
									<Input placeholder="Nhập lý do" />
								</Form.Item></Col>
						</Row>
					</Form>

				</Modal>
			</Layout.Content>
		</div >
	)

}

export default ListOrderServiceDoctor;
