import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, message, Spin, Popconfirm, Divider, Button, Tag, Row, Col, Select, Collapse, Form, InputNumber, DatePicker, TimePicker,
} from 'antd';
import { HomeOutlined, DeleteOutlined, MinusCircleOutlined, PlusOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import ModalInfoOrderService from './components/info-order';
import moment from 'moment';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const HospitalServiceDetailSetting = (props) => {
    const [form] = Form.useForm();
    const { serviceId } = useParams();
    const [hospital, setHospital] = useState({});
    const [loading, setLoading] = useState(false);
    const [isModalServiceVisible, setIsModalServiceVisible] = useState(false);
    const [currService, setCurrService] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [hour, setHour] = useState(0);
    const [mintue, setMintue] = useState(0);
    const [duration, setDuration] = useState(0);
    const [prices_apply, setPrices_apply] = useState(0);
    const [calendarExist, setCalendarExist] = useState([]);
    const openOrderService = async (data) => {
        await setOrderId(data);
        setIsModalServiceVisible(true);
    }
    // effect
    useEffect(() => {
        // load thông tin hospital
        const loadHospital = async () => {
            setLoading(true);
            let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
            if (user.is_hospital) {
                await axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?created_by=${user._id}`)
                    .then(res => {
                        if (res?.data?.code === 200) {
                            const { docs } = res?.data?.data;
                            setHospital(docs[0]);
                            let service = docs[0]?.services.find(x => x._id === serviceId);
                            const today = moment();
                            for (let i = 0; i < service.calendars.length; i++) {
                                if (service.calendars[i].year < today.year() || (service.calendars[i].month <= today.month() + 1 && service.calendars[i].day < today.date())) {
                                    service.calendars.splice(i, 1);
                                    i--;
                                }
                            }
                            setCurrService(service);
                        }
                        setLoading(false);
                    })
                    .catch(error => message.warning('Cơ sở y tế không tồn tại', 5).then(() => props.history.push('/')))
            }
            else {
                message.warning('Bạn chưa đăng ký làm đối tác Cơ sở y tế của Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
            }
        }
        let mounted = true;

        if (mounted) {
            loadHospital();
        }

        return () => mounted = false;
    }, []);

    const addTime = async (calendarId, day, month, year) => {
        setLoading(true);
        let check = false;
        let serviceExistName = '';
        if (!duration || duration === 0) {
            message.warning('Vui lòng nhập thời lượng thực hiện công việc !', 10);
            return;
        }
        for (let calendar of currService.calendars) {
            if (calendar.day === day && calendar.month === month && calendar.year === year)
                for (let time of calendar.times) {
                    let start = time.time_h * 60 + time.time_m;
                    let timeCheck = hour * 60 + mintue;
                    check = start <= timeCheck && timeCheck <= start + duration;
                    if (check) {
                        serviceExistName = currService.name + ' vào ngày ' + calendar.day + '/' + calendar.month + '/' + calendar.year + ' ' + time.time_h + ':' + time.time_m;
                        break;
                    }

                }
            if (check) break;
        }
        if (check) {
            setLoading(false);
            message.error('Thời gian bạn đặt bị trùng lịch với  ' + serviceExistName + '', 10);
            return;
        }
        let dataSave = {
            action: 'insTime',
            serviceId: serviceId,
            calendarId: calendarId,
            time_h: hour,
            time_m: mintue,
            duration: duration,
            prices_apply: prices_apply
        }
        await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${hospital._id}`, dataSave)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Thêm lịch thành công !');
                    setHospital(res.data.data);
                    let service = res.data.data.services.find(x => x._id === serviceId);
                    const today = moment();
                    for (let i = 0; i < service.calendars.length; i++) {
                        if (service.calendars[i].year < today.year() || (service.calendars[i].month <= today.month() + 1 && service.calendars[i].day < today.date())) {
                            service.calendars.splice(i, 1);
                            i--;
                        }
                    }
                    await setCurrService(service);
                    setHour(0);
                    setMintue(0);
                    setDuration(0)
                }
                else {
                    message.error('Thêm lịch thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            }).catch(error => message.error('Thêm lịch thất bại! Vui lòng thử lại sau.').then(() => console.log(error)));
        setLoading(false);
    }
    const delCalendar = async (calendarId) => {
        setLoading(true);
        let dataDel = {
            action: 'delCalendar',
            serviceId: serviceId,
            calendarId: calendarId
        }
        await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${hospital._id}`, dataDel)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Xóa lịch thành công !')
                    setHospital(res.data.data);
                    let service = res.data.data.services.find(x => x._id === serviceId);
                    const today = moment();
                    for (let i = 0; i < service.calendars.length; i++) {
                        if (service.calendars[i].year < today.year() || (service.calendars[i].month <= today.month() + 1 && service.calendars[i].day < today.date())) {
                            service.calendars.splice(i, 1);
                            i--;
                        }
                    }
                    await setCurrService(service);
                }
                else {
                    message.error('Xóa lịch thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            });
    }
    const delTime = async (calendarId, timeId) => {
        setLoading(true);
        let dataDel = {
            action: 'delTime',
            serviceId: serviceId,
            calendarId: calendarId,
            timeId: timeId
        }
        await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${hospital._id}`, dataDel)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Xóa lịch thành công !')
                    setHospital(res.data.data);
                    let service = res.data.data.services.find(x => x._id === serviceId);
                    const today = moment();
                    for (let i = 0; i < service.calendars.length; i++) {
                        if (service.calendars[i].year < today.year() || (service.calendars[i].month <= today.month() + 1 && service.calendars[i].day < today.date())) {
                            service.calendars.splice(i, 1);
                            i--;
                        }
                    }
                    await setCurrService(service);
                }
                else {
                    message.error('Xóa lịch thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            });
    }
    const [dates, setDates] = useState([]);
    const [hackValue, setHackValue] = useState();
    const [value, setValue] = useState();
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return current && current < moment().endOf('day');
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 15;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 15;
        return tooEarly || tooLate || current < moment().endOf('day');
    };

    const onOpenChange = open => {
        if (open) {
            setHackValue([]);
            setDates([]);
        } else {
            setHackValue(undefined);
        }
    };
    const onFinish = async (values) => {
        setLoading(true);
        let calendars = [];
        let calendarsSave = [];
        if (values.worklist) {
            for (let work of values.worklist) {
                if (work.times.length > 0) {
                    let check = false;
                    let calendar = {
                        service_id: work.service_id,
                        day_of_week: 1,
                        day: 1,
                        month: 1, year: 1,
                        times: work.times,
                    }
                    for (let i = 0; i < calendars.length; i++) {
                        //kiểm tra có trùng dịch vụ thì join vào dịch vụ đã được lập lịch
                        if (calendars[i].service_id === work.service_id) {
                            calendars[i].times = calendars[i].times.concat(work.times);
                            check = true;
                            break;
                        }
                    }
                    if (!check) {
                        //nếu không trùng thì push mới lịch
                        calendars.push(calendar);
                    }
                }
            }
            if (calendars.length > 0) {
                for (let m = moment(values.dates[0]); m.isSameOrBefore(values.dates[1]); m.add(1, 'days')) {
                    //duyệt ngày để thêm lịch cài đặt
                    if (values.freeDay.indexOf(m.day().toString()) === -1) {
                        for (let item of calendars) {
                            item.day_of_week = m.day();
                            item.day = m.date();
                            item.month = m.month() + 1;
                            item.year = m.year();
                            calendarsSave.push({ ...item });
                        }
                    }

                }
                await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${hospital._id}`, { calendarsSave: calendarsSave, action: 'insCalendar' })
                    .then(async res => {
                        if (res && res.data && res.data.code === 200) {
                            message.success('Lập lịch thành công !');
                            setHospital(res.data.data);
                            let service = res.data.data.services.find(x => x._id === serviceId);
                            const today = moment();
                            for (let i = 0; i < service.calendars.length; i++) {
                                if (service.calendars[i].year < today.year() || (service.calendars[i].month <= today.month() + 1 && service.calendars[i].day < today.date())) {
                                    service.calendars.splice(i, 1);
                                    i--;
                                }
                            }
                            await setCurrService(service);
                            form.resetFields();
                            setLoading(false);
                        }
                        else {
                            message.error('Lập lịch thất bại ! Vui lòng thử lại sau !');
                            setLoading(false);
                        }
                    }).catch(error => {message.error('Lập lịch thất bại ! Vui lòng thử lại sau !').then(console.log(error));setLoading(false);});
            }
            else {
                message.warning('Vui lòng thêm cài đặt thời gian làm việc trong ngày cho lần lập lịch này, cảm ơn !', 7);
                setLoading(false);
            }
        }
        else {
            message.warning('Vui lòng thêm cài đặt thời gian làm việc trong ngày cho lần lập lịch này, cảm ơn !', 7);
            setLoading(false);
        }
    }
    const onChangeRenderTime = async (index) => {
        const worklist = form.getFieldValue('worklist');        
        for (let i = 0; i < worklist.length; i++) {
            if(i !== index){
                let startTime = worklist[i]?.start_time?.hour() * 60 + worklist[i]?.start_time?.minutes();
                let endTime = worklist[i].end_time?.hour() * 60 + worklist[i]?.end_time?.minutes();
                let startCheck = worklist[index]?.start_time?.hour() * 60 + worklist[index]?.start_time?.minutes();
                let endCheck = worklist[index]?.start_time?.hour() * 60 + worklist[index]?.start_time?.minutes();
                let check1 = (startTime <= startCheck && startCheck <= endTime) || (startTime <= endCheck && endCheck <= endTime);
                if(check1){
                    message.warning('Khoảng thời gian lập lịch trong ngày bị trùng. Vui lòng kiểm tra lại !', 7);
                    return;
                }
            }           
        }
        const updatedWorklist = await worklist.map((item, i) => {
            if (i === index) {
                if (!item?.start_time) {
                    return {
                        ...item,
                        times: []
                    }
                }
                else if (!item?.end_time) {
                    return {
                        ...item,
                        times: []
                    }
                }
                else if (!item?.duration && item?.duration === 0) {
                    return {
                        ...item,
                        duration: 0,
                        times: []
                    }
                }
                else if (!item?.prices_apply) {
                    return {
                        ...item,
                        times: []
                    }
                }
                let { times, timesExist } = getTimesArray(item.start_time, item.end_time, item.duration, item.free_time >= 0 ? item.free_time : 0, null, item.prices_apply);
                return {
                    ...item,
                    service_id: serviceId,
                    duration: item.duration,
                    times: times,
                    timesExist: timesExist
                }
            }            
            return item;
        })
        form.setFieldsValue({ worklist: updatedWorklist });
    }
    function getTimesArray(start, end, duration, free_time, address, prices_apply) {
        let times = [];
        let timesExist = [];
        let length = free_time;
        if (length > 5) {
            let startMin = start.hour() * 60 + start.minutes();
            let endMin = end.hour() * 60 + end.minutes();
            if (startMin < endMin) {
                while (startMin <= endMin) {
                    let check = false;
                    for (let calendarCheck of calendarExist) {
                        if (form.getFieldValue('freeDay').indexOf(calendarCheck.calendar.day_of_week.toString()) === -1) {
                            for (let time of calendarCheck.calendar.times) {
                                let startCheck = time.time_h * 60 + time.time_m;
                                check = (startCheck <= startMin && startMin <= startCheck + free_time) || (startCheck <= startMin + length && startMin + length <= startCheck + free_time);
                                if (check) break;
                            }
                            if (check) break;
                        }
                    }
                    let mins = startMin % 60;
                    let hours = Math.floor(startMin / 60);
                    if (!check)
                        times.push({ check_time: check, time_h: hours, time_m: mins, duration: duration, is_order: false, address: address, prices_apply: prices_apply });
                    else timesExist.push({ check_time: check, time_h: hours, time_m: mins, duration: duration, is_order: false, address: address, prices_apply: prices_apply });
                    startMin += length
                }
            }
            else message.error('Từ giờ phải bé hơn đến giờ ! Bác sĩ vui lòng kiểm tra lại !');
        }
        return { times, timesExist };
    }
    const checkExistDate = async (datesRange) => {
        await setCalendarExist([]);
        form.setFieldsValue({ worklist: [] });
        let checkExist = [];
        if (datesRange && datesRange.length > 1) {
            let startDate = datesRange[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            let endDate = datesRange[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            let check = false;
            for (let calendar of currService.calendars) {
                let dateCheck = moment(calendar.day + '-' + calendar.month + '-' + calendar.year, 'DD-MM-YYYY');
                check = dateCheck.isSameOrAfter(startDate) && dateCheck.isSameOrBefore(endDate);
                if (check) {
                    checkExist.push({ serviceName: currService.name, calendar: calendar });
                }
            }
            await setCalendarExist(checkExist);
        }
    }
    const hours = [];
    const mins = [];
    for (let i = 0; i < 24; i++) hours.push(i);
    for (let i = 0; i < 60; i++) mins.push(i);
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cơ sở y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Quản lý gói khám
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Lập lịch cho gói khám
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {currService?.name}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }
                <Collapse defaultActiveKey={['setup']} bordered={false} >
                    <Panel className="collapse-setting" header={<label style={{ fontWeight: 'bold' }}>Cài đặt lịch gói khám trong tuần</label>} key="setup" >
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                freeDay: []
                            }}
                        >
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={6}>
                                    <Form.Item
                                        label="Ngày cài đặt (từ ngày, đến ngày)"
                                        required tooltip="Vui lòng chọn trường này"
                                        name="dates"
                                        rules={[
                                            { required: true, message: 'Vui lòng chọn từ ngày đến ngày cài đặt' },
                                        ]}
                                    >
                                        <RangePicker
                                            format="DD/MM/YYYY"
                                            value={hackValue || value}
                                            disabledDate={disabledDate}
                                            onCalendarChange={val => setDates(val)}
                                            onChange={val => {
                                                if (hospital) {
                                                    checkExistDate(val);
                                                    setValue(val);

                                                }
                                            }}
                                            onOpenChange={onOpenChange}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={18}>
                                    <Form.Item
                                        label="Ngày nghỉ trong tuần" name="freeDay"
                                        tooltip="Ngày nghỉ sẽ không xếp lịch làm việc"
                                    >
                                        <Select
                                            mode="multiple"
                                            optionFilterProp="children"
                                            placeholder="Chọn ngày nghỉ trong tuần"
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                [1, 2, 3, 4, 5, 6, 0].map(item => <Select.Option key={item}>{item > 0 ? "Thứ " + (item + 1) + "" : "Chủ nhật"}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24}> <Divider style={{ fontSize: 14 }}><b>Cài đặt lịch gói khám trong ngày</b></Divider></Col>
                            </Row>
                            <Form.List name="worklist">
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            {
                                                fields.map((field, index) => (
                                                    <Row gutter={24} key={index}>
                                                        <Col xs={6} sm={3} md={3}>
                                                            <Form.Item
                                                                label="Từ giờ"
                                                                name={[index, 'start_time']}
                                                                required tooltip="Vui lòng chọn trường này"
                                                                rules={[
                                                                    { required: true, message: 'Vui lòng chọn' },
                                                                ]}
                                                            >
                                                                <TimePicker
                                                                    style={{ width: '100%' }}
                                                                    format="HH:mm"
                                                                    onChange={() => { onChangeRenderTime(index) }}
                                                                    showNow={false} placeholder="Từ giờ" minuteStep={5} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={6} sm={3} md={3}>
                                                            <Form.Item
                                                                label="Đến giờ"
                                                                name={[index, 'end_time']}
                                                                required tooltip="Vui lòng chọn trường này"
                                                                rules={[
                                                                    { required: true, message: 'Vui lòng chọn' },
                                                                ]}
                                                            >
                                                                <TimePicker style={{ width: '100%' }}
                                                                    format="HH:mm"
                                                                    showNow={false}
                                                                    onChange={() => { onChangeRenderTime(index) }}
                                                                    placeholder="Đến giờ"
                                                                    minuteStep={5} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={6} sm={3} md={5}>
                                                            <Form.Item
                                                                label="Khoảng cách giữa 2 lượt"
                                                                tooltip="Khoảng thời gian giữa 2 lượt khám liên tiếp"
                                                                name={[index, 'free_time']}
                                                                rules={[
                                                                    { required: true, message: 'Vui lòng nhập' },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    max={120} min={0}
                                                                    onBlur={() => { onChangeRenderTime(index) }}
                                                                    style={{ width: '100%' }}
                                                                    formatter={value => `Phút ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\Phút\s?|(,*)/g, '')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={6} sm={3} md={5}>
                                                            <Form.Item
                                                                label="Dự kiến hoàn thành"
                                                                tooltip="Thời lượng dự kiến hoàn thành gói khám"
                                                                name={[index, 'duration']}
                                                                rules={[
                                                                    { required: true, message: 'Vui lòng nhập' },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    max={120}
                                                                    min={0}
                                                                    onBlur={() => { onChangeRenderTime(index); }}
                                                                    style={{ width: '100%' }}
                                                                    formatter={value => `Phút ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\Phút\s?|(,*)/g, '')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={5}>
                                                            <Form.Item
                                                                label="Chọn bảng giá áp dụng"
                                                                name={[index, 'prices_apply']}
                                                                required tooltip="Vui lòng chọn trường này"
                                                                rules={[
                                                                    { required: true, message: 'Vui lòng chọn bảng giá' },
                                                                ]}
                                                            >
                                                                <Select
                                                                    placeholder="Chọn bảng giá áp dụng"
                                                                    optionFilterProp="children"
                                                                    onChange={() => { onChangeRenderTime(index) }}
                                                                    style={{ width: '100%' }}>
                                                                    <Select.Option key='default'>Giá mặc định</Select.Option>
                                                                    {
                                                                        currService && currService.price_list.length > 0 &&
                                                                        currService.price_list.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={3}>
                                                            <Button
                                                                type="link"
                                                                danger
                                                                style={{ marginTop: 30 }}
                                                                onClick={() => remove(field.name)}
                                                                icon={<MinusCircleOutlined />}
                                                            >
                                                                Xóa
                                                            </Button>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24}>
                                                            <Form.List name={[field.name, 'times']}>
                                                                {(times, { add, remove }) => {
                                                                    return (
                                                                        <>
                                                                            {times.map((field1, index1) => (
                                                                                <Tag color='green' key={field1.key} closable style={{ marginBottom: 20 }} onClose={() => remove(field1.name)}>
                                                                                    {form.getFieldValue('worklist')[index].times[index1].time_h > 9 ? form.getFieldValue('worklist')[index].times[index1].time_h : '0' + form.getFieldValue('worklist')[index].times[index1].time_h.toString()}:{form.getFieldValue('worklist')[index].times[index1].time_m > 9 ? form.getFieldValue('worklist')[index].times[index1].time_m.toString() : '0' + form.getFieldValue('worklist')[index].times[index1].time_m.toString()} </Tag>
                                                                            ))}
                                                                        </>
                                                                    );
                                                                }}
                                                            </Form.List>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24}>
                                                            <Form.List name={[field.name, 'timesExist']}>
                                                                {(timesExist, { add, remove }) => {
                                                                    return (
                                                                        <>  {timesExist.length > 0 && <label>Lịch bị trùng không thể cài đặt: </label>}
                                                                            {timesExist.map((field1, index1) => (
                                                                                <Tag color='red' key={field1.key} style={{ marginBottom: 20 }} onClose={() => remove(field1.name)}>
                                                                                    {form.getFieldValue('worklist')[index].timesExist[index1].time_h > 9 ? form.getFieldValue('worklist')[index].timesExist[index1].time_h : '0' + form.getFieldValue('worklist')[index].timesExist[index1].time_h}:{form.getFieldValue('worklist')[index].timesExist[index1].time_m > 9 ? form.getFieldValue('worklist')[index].timesExist[index1].time_m : '0' + form.getFieldValue('worklist')[index].timesExist[index1].time_m} </Tag>
                                                                            ))}
                                                                        </>
                                                                    );
                                                                }}
                                                            </Form.List>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            <Row gutter={24}>
                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <PlusOutlined /> Thêm cài đặt
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </>
                                    )
                                }}
                            </Form.List>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Tạo lịch
                            </Button>
                        </Form>
                    </Panel>
                    {
                        (
                            currService && currService.calendars.length > 0 ?
                                <>
                                    <div style={{
                                        background: '#e6f7ff',
                                        padding: 10,
                                        fontSize: 15,
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        color: '#1890ff', borderRadius: 5, marginTop: 10,
                                        border: '1px solid #91d5ff'
                                    }}>Lịch đã được cài đặt</div>
                                    {
                                        currService.calendars.sort((a, b) => (a.month > b.month) ? 1 : (a.month === b.month) ? ((a.day > b.day) ? 1 : -1) : -1).map((calendar, index) => (
                                            <Panel key={index} header={(calendar.day_of_week > 0 ? "Thứ " + (calendar.day_of_week + 1) : "Chủ nhật") + " - " + calendar.day + "/" + calendar.month + "/" + calendar.year}
                                                extra={
                                                    <div key={'bt' + index} type="link" onClick={event => { event.stopPropagation(); }}>
                                                        <Popconfirm title="Bạn muốn xóa lịch ngày này?" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => { delCalendar(calendar._id) }}>
                                                            <Button type="link" danger ><DeleteOutlined />Xóa lịch</Button>
                                                        </Popconfirm>
                                                    </div>
                                                }
                                            >



                                                <div>
                                                    {
                                                        calendar.times.sort((a, b) => (a.time_h > b.time_h) ? 1 : (a.time_h === b.time_h) ? ((a.time_m > b.time_m) ? 1 : -1) : -1).map((time) => (
                                                            <Tag style={{ marginBottom: 10 }} key={time._id} color={time.is_order ? "red" : "green"}>{time.time_h > 9 ? time.time_h : '0' + time.time_h}:{time.time_m > 9 ? time.time_m : '0' + time.time_m}
                                                                {
                                                                    !time.is_order ?
                                                                        <Popconfirm title="Bạn muốn hủy thời gian này?" onConfirm={() => { delTime(calendar._id, time._id) }}>
                                                                            <Button type="link" danger><CloseCircleOutlined /></Button>
                                                                        </Popconfirm>
                                                                        : <Popconfirm title="Thời gian này đã được đặt !" okText='Xem chi tiết' onConfirm={() => { openOrderService(time.order_id) }}>
                                                                            <Button type="link">
                                                                                <InfoCircleOutlined />
                                                                            </Button>
                                                                        </Popconfirm>
                                                                }
                                                            </Tag>
                                                        ))
                                                    }
                                                    <Popconfirm title={<div><label>Vui lòng chọn thời gian</label><br></br>
                                                        <div style={{ marginTop: 10 }}>
                                                            <Row gutter={24} style={{ width: "300px" }}>
                                                                <Col xs={7} sm={7} md={7}>
                                                                    <label style={{ width: "100%" }}>Giờ</label>
                                                                    <Select placeholder="Giờ" value={hour} onChange={(value) => setHour(value)} showSearch optionFilterProp="children" style={{ width: '100%' }} dropdownClassName="drop-select">
                                                                        {hours.map(option => (
                                                                            <Select.Option
                                                                                key={option}
                                                                                value={option}
                                                                            >
                                                                                {option}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Col>
                                                                <Col xs={7} sm={7} md={7}>
                                                                    <label>Phút</label>
                                                                    <Select placeholder="Phút" value={mintue} onChange={(value) => setMintue(value)} showSearch optionFilterProp="children" style={{ width: "100%" }} dropdownClassName="drop-select">
                                                                        {mins.map(option => (
                                                                            <Select.Option key={option} value={option}>
                                                                                {option}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Col>
                                                                <Col xs={10} sm={10} md={10}>
                                                                    <label>Thời lượng</label>
                                                                    <InputNumber
                                                                        max={120}
                                                                        min={0}
                                                                        onChange={(value) => { setDuration(value); }}
                                                                        style={{ width: '100%' }}
                                                                        formatter={value => `Phút ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={value => value.replace(/\Phút\s?|(,*)/g, '')}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                    } onConfirm={(event) => { event.stopPropagation(); addTime(calendar._id, calendar.day, calendar.month, calendar.year, null); }}>
                                                        <Button type="link" ><PlusOutlined />Thêm lịch</Button>
                                                    </Popconfirm>
                                                </div>
                                                <Divider />

                                            </Panel>
                                        ))
                                    }
                                </> :
                                <div style={{
                                    background: '#ffe6e6',
                                    padding: 10,
                                    fontSize: 15,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: '#ff1818', borderRadius: 5, marginTop: 10,
                                    border: '1px solid #ff1818'
                                }}>Chưa có lịch được cài đặt</div>
                        )
                    }
                </Collapse>
            </Layout.Content>
            <ModalInfoOrderService visible={isModalServiceVisible} orderId={orderId}
                cancel={() => {
                    setIsModalServiceVisible(false);
                }} />
        </div >
    )

}

export default HospitalServiceDetailSetting;
