import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Select, Form, Input, message, Button, DatePicker,  Spin,
} from 'antd';
import { HomeOutlined,  SaveOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import moment from 'moment';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/en/world.json';
import ReactCountryFlag from "react-country-flag";
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        md: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 8 },
        sm: { span: 10 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const User = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [dob, setDob] = useState();
    const [userId, setUserId] = useState();
    const [phones, setPhones] = useState();
    const onChange = date => {
        setDob(date);
    }
    // submit
    const onFinish = async (values) => {
        values.dob = dob;
        values.phone = phones.phone;
        values.dial_code = phones.code;
        await axiosInstance.patch(`${CONSTANT.API.USER.UPDATE}/${userId}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    values._id = userId;
                    localStorage.setItem(CONSTANT.STORAGE_KEY_USER,JSON.stringify(values));
                    message.success('Sửa thông tin thành công!')
                        .then(() => props.history.push('/profile'))
                }
                else {
                    message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                }
            });
    };
    // effect
    useEffect(() => {
        loadUser();
    }, []);

    // load User    
    const loadUser = async () => {
        setLoading(true);
        let user = JSON.parse(localStorage.getItem(CONSTANT.STORAGE_KEY_USER));
        setPhones({
            phone: user.phone,
            code: Number(user.dial_code),
        });
        setUserId(user._id);
        setDob(moment(user.dob));
        form.setFieldsValue({
            firstname: user.firstname,
            lastname: user.lastname,
            sex: user.sex,
        });
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/profile">Hồ sơ</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thông tin tài khoản
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }
                <Form
                    {...formItemLayout} form={form} onFinish={onFinish}                >
                    <Form.Item name="firstname" label="Họ"
                        rules={[{ required: true, message: "Họ không được để trống" }, {
                            type: 'string', max: 100, message: "Họ không quá 100 ký tự"
                        },]}>
                        <Input placeholder="Nhập họ" />
                    </Form.Item>
                    <Form.Item name="lastname" label="Tên" rules={[{ required: true, message: "Tên không được để trống" }, {
                        type: 'string', max: 30, message: "Tên không quá 30 ký tự"
                    },]}>
                        <Input placeholder="Nhập tên" />
                    </Form.Item>
                    <Form.Item
                        name="sex"
                        label="Giới tính"
                        rules={[{ required: true, message: 'Vui lòng chọn giới tính!' }]}
                    >
                        <Select placeholder="Chọn giới tính">
                            <Option value="male">Nam</Option>
                            <Option value="female">Nữ</Option>
                            <Option value="other">Khác</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Ngày sinh">
                        <ConfigProvider locale={en}>
                            <DatePicker
                                className="ant-picker-input"
                                format="DD/MM/YYYY"
                                defaultValue={moment()}
                                onChange={onChange}
                                value={dob} />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item label="Số điện thoại">
                        <ConfigProvider
                            locale={en}
                            areaMapper={(area) => {
                                return {
                                    ...area,
                                    emoji: <ReactCountryFlag svg countryCode={area.short} />,
                                };
                            }}
                        >
                            <CountryPhoneInput
                                value={phones}
                                onChange={(v) => {
                                    setPhones({
                                        phone: v.phone,
                                        code: v.code,
                                    })
                                }} />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" icon={<SaveOutlined />}
                            htmlType="submit"
                        >
                            Lưu thông tin
                        </Button>
                    </Form.Item>

                </Form>
            </Layout.Content>
        </div>
    )

}

export default User;
