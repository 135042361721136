import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col, Tag, Button, Popconfirm, Input, message, Spin, } from 'antd';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';
import { axiosInstance } from '../../../config/api';
import moment from 'moment';
const arrStatus = {
    'wait-paid': { text: "Chờ thanh toán", color: "orange" },
    'paid': { text: 'Đã thanh toán', color: 'green' },
    'confirmed': { text: 'Đã xác nhận', color: 'blue' },
    'packing': { text: 'Đang đóng gói', color: 'orange' },
    'delivery-in-progress': { text: 'Đang giao hàng', color: 'orange' },
    'customer-received': { text: 'Khách đã nhận hàng', color: 'green' },
    'completed': { text: 'Đã hoàn thành', color: 'green' },
    'abandoned': { text: 'Khách đã hủy', color: 'red' },
    'cancelled': { text: 'Bạn đã hủy đơn này', color: 'red' },
    'refunded': { text: 'Đã hoàn hoàn tiền', color: 'orange' },
};
const ModalShopDetailOrder = (props) => {
    const [order, setOrder] = useState(null);
    const [reasonCancel, setReasonCancel] = useState('');
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleCancel = useCallback(() => {
        props.cancel(reload);
    }, [reload]);
    useEffect(() => {
        loadOrder();
    }, [props.orderId]);
    // load Agency    
    const loadOrder = async () => {
        setLoading(true);
        if (props && props.orderId) {
            await axiosInstance.get(`${CONSTANT.API.PRODUCT.ORDER}/${props.orderId}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        setOrder(res.data.data)
                    }
                })
        }
        setLoading(false);
    }
    const updateStatus = async (statusUpd) => {
        setLoading(true);
        let upd = { actUpd: 'udpStatus', status: statusUpd, agency: order.shop[0].agency._id };
        if (statusUpd === 'cancelled') {
            upd.reason_cancel = reasonCancel;
            if (reasonCancel === '') {
                message.error('Vui lòng nhập lý do từ chối! Cảm ơn !');
                return;
            }
            else if (reasonCancel.length > 200) {
                message.error('Lý do từ chối tối đa 200 ký tự !');
                return;
            }
        }
        if (props.orderId) {
            await axiosInstance.patch(`${CONSTANT.API.PRODUCT.ORDER}/${props.orderId}`, upd)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        setOrder(res.data.data);
                        if (statusUpd === 'confirmed') {
                            message.success('Chấp nhận thành công!')
                        }
                        else if (statusUpd === 'cancelled') {
                            message.success('Từ chối thành công!')
                        }
                        else if (statusUpd === 'packing') {
                            message.success('Xác nhận đang đóng gói thành công !', 5)
                        }
                        else if (statusUpd === 'delivery-in-progress') {
                            message.success('Xác nhận đang giao hàng thành công !', 5)
                        }
                        else if (statusUpd === 'customer-received') {
                            message.success('Xác nhận khách đã nhận hàng thành công !', 5)
                        }                       
                        setLoading(false);
                        setReload(true);
                    }
                    else {
                        setLoading(false);
                        message.error('Đã xảy ra lỗi! Vui lòng thử lại sau !');
                    }
                })
                .catch(error => {
                    setLoading(false);
                    message.error('Đã xảy ra lỗi! Vui lòng thử lại sau ! ', 4);
                    console.log(error.message);
                });
        }
        else {
            setLoading(false);
            message.error('Không tìm thấy mã đặt hàng !');
        }
    }
    return (
        <Modal title="Chi tiết đơn đặt hàng"
            visible={props.visible}
            onOk={handleCancel}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Đóng"
            className="modal-book-facility"
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            width={900}
            cancelButtonProps={{ style: { display: 'none' } }}
            maskClosable={false}
        >
            {
                order && <Row gutter={24}>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Họ tên người đặt:
                    </Col>
                    <Col xs={12} md={19}>
                        {order.shipping.first_name + ' ' + order.shipping.last_name}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Địa chỉ giao hàng:
                    </Col>
                    <Col xs={12} md={19}>
                        {order.shipping.address_1 + ',' + order.shipping.address_2 + ',' + order.shipping.city + ',' + order.shipping.country}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Email người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.shipping.email}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Đ.Thoại người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {order?.shipping?.dial_code ? `(${order?.shipping?.dial_code})` : ''} {order.shipping.phone}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Ngày đặt hàng:
                    </Col>
                    <Col xs={12} md={7}>
                        {`${moment(new Date(order.created_at)).format('DD/MM/YYYY HH:mm')}`}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Ngày thanh toán:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.date_paymented && `${moment(new Date(order.date_paymented)).format('DD/MM/YYYY HH:mm')}`}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Trạng thái:
                    </Col>
                    <Col xs={12} md={7}>
                        {(order.shop[0]?.status_order && arrStatus[order.shop[0].status_order]) ? <Tag color={arrStatus[order.shop[0]?.status_order].color}>{arrStatus[order.shop[0]?.status_order].text}</Tag> : <Tag color='black'>Rác</Tag>}
                    </Col>
                    <Col xs={12} md={12}>
                        {
                            order && order?.shop[0] && order.shop[0]?.status_order === 'paid' ? <Col xs={24} md={24}> <Popconfirm title={<div>Sau khi chấp nhận đơn hàng<br></br> Quý đối tác kiểm tra hàng để đóng gói đúng theo đơn hàng !<br></br>  Cảm ơn Quý đối tác!</div>}
                                okText="Đồng ý" cancelText="Đóng" onConfirm={() => updateStatus('confirmed')}>
                                <Button type="primary" style={{ marginRight: '20px', marginBottom: '20px' }}>
                                    Chấp nhận đơn hàng
                                </Button>
                            </Popconfirm>
                                <Popconfirm title={<div><label>Lý do từ chối</label><br></br>
                                    <Input.TextArea
                                        showCount maxLength={200}
                                        onChange={e => setReasonCancel(e.target.value)}
                                        style={{ width: '300px', height: '100px', resize: 'none' }}
                                        placeholder="Vui lòng nhập lý do từ chối để Vivmedic thông báo lại cho khách hàng ! Cảm ơn Quý đối tác!"
                                    />
                                </div>} okText="Đồng ý" cancelText="Đóng" onConfirm={() => updateStatus('cancelled')}>
                                    <Button type="danger">
                                        Từ chối đơn hàng
                                    </Button>
                                </Popconfirm></Col> : order.shop[0].status_order === 'confirmed' ? <Col xs={12} md={12}>
                                    <Popconfirm title={<div>Xác nhận sản phẩm đã được đóng gói. Cảm ơn Quý đối tác !</div>}
                                        okText="Xác nhận" cancelText="Đóng" onConfirm={() => updateStatus('packing')}>
                                        <Button type='primary'>
                                            Xác nhận đã đóng gói
                                        </Button>
                                    </Popconfirm>
                                </Col> : order.shop[0].status_order === 'packing' ? <Col xs={12} md={12}>
                                    <Popconfirm title={<div>Xác nhận sản phẩm đang được giao. Cảm ơn Quý đối tác !</div>}
                                        okText="Xác nhận" cancelText="Đóng" onConfirm={() => updateStatus('delivery-in-progress')}>
                                        <Button type='primary'>
                                            Đang giao hàng
                                        </Button>
                                    </Popconfirm>
                                </Col> : order.shop[0].status_order === 'delivery-in-progress' ? <Col xs={12} md={12}>
                                    <Popconfirm title={<div>Xác nhận sản phẩm đã được nhận bởi khách hàng. Sau khi khách hàng xác nhận, đơn hàng của quý Đối tác sẽ hoàn thành. Cảm ơn Quý đối tác !</div>}
                                        okText="Xác nhận" cancelText="Đóng" onConfirm={() => updateStatus('customer-received')}>
                                        <Button type='primary'>
                                            Khách đã nhận hàng
                                        </Button>
                                    </Popconfirm>
                                </Col> : null
                        }
                    </Col>
                    <Col xs={24} md={24}>
                        <div className="ant-table">
                            <table className='table' style={{ tableLayout: "auto", width: "100%" }}>
                                <thead className="ant-table-thead">
                                    <tr>
                                        <th className="ant-table-cell">Hình ảnh</th>
                                        <th className="ant-table-cell">Tên sản phẩm</th>
                                        <th className="ant-table-cell">Số lượng</th>
                                        <th className="ant-table-cell">Đơn giá</th>
                                        <th className="ant-table-cell">Thành tiền</th>
                                    </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                    {
                                        order.shop[0].items && order.shop[0].items.length > 0 && order.shop[0].items.map((item, index) => (
                                            <tr className="ant-table-row" key={index}>
                                                <td className="ant-table-cell" style={{ width: '120px' }}>
                                                    <img src={COMMON.getImageVariant(item)} style={{ width: '100%' }}
                                                        alt={item.product.name}
                                                    />
                                                </td>
                                                <td className="ant-table-cell" style={{ width: '300px' }}>
                                                    <h4 className="product-title">{item.product.name}</h4>
                                                    <p className="product-attribute">
                                                        {
                                                            item.variant && item.variant.attributes.map((attr, indexAttr) => {
                                                                let str = attr.options[0].name;
                                                                if (indexAttr < item.variant.attributes.length - 1)
                                                                    str += " / ";
                                                                return str + ' ';
                                                            })
                                                        }
                                                    </p>
                                                </td>
                                                <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                    {item.quantity}
                                                </td>
                                                <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                    {COMMON.currencyFormat(parseFloat(item.price))} {COMMON.getCurrency(item.product.currency)}
                                                </td>
                                                <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                    {COMMON.currencyFormat(parseFloat(item.price * item.quantity))} {COMMON.getCurrency(item.product.currency)}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className="ant-table-footer">
                                <div style={{ float: 'left', width: '100%', padding: '10px 0', borderBottom: '1px #ccc solid' }}>
                                    <span style={{ float: 'left', fontWeight: 700 }}>Tổng tiền hàng</span>
                                    <span style={{ float: 'right', fontWeight: 700 }}>
                                        {order.shop[0].shop_total && `${COMMON.currencyFormat(order.shop[0].shop_total)} ${COMMON.getCurrency(order?.currency)}` || 0}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            {
                loading &&
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
        </Modal>
    )
}
export default ModalShopDetailOrder;
