import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { UserOutlined, LockOutlined, CodeSandboxOutlined, RollbackOutlined } from '@ant-design/icons';
import { isLoggedIn, setAuthTokens } from 'axios-jwt';
import queryString from 'query-string';

import './login.css';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';

const Login = (props) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [loging, setLoging] = useState(false);
    const [currentAction, setcurrentAction] = useState({ action: 'login', title: 'Đăng nhập hệ thống Vivmedic Partner', email: '' });
    useEffect(() => {
        forceUpdate({});
        if (isLoggedIn()) window.location.href = '/';
    }, []);
    const onFinishSendEmail = async (values) => {
        setLoging(true);
        axiosInstance.post(`${CONSTANT.API.USER.CHECK}`, { email: values.email })
            .then(res => {
                if (res.data.code === 200 && res.data.data.exist === true) {
                    axiosInstance.post(`${CONSTANT.API.AUTH.FORGOT_PASS}`, { email: values.email, role: "agency" })
                        .then(async res1 => {
                            if (res1.data.code === 200 && res1.data.data) {
                                message.success('Mã xác nhận đã được gửi về email ' + values.email + '. Vui lòng kiểm tra email lấy mã và thực hiện lấy lại mật khẩu !', 3);
                                setcurrentAction({ action: 'getpass', title: "Lấy lại mật khẩu", email: values.email });
                            }
                            setLoging(false);
                        })
                        .catch(error => {
                            setLoging(false);
                            message.error('Gửi yêu cầu không thành công ! error:' + error.response.data.code + '', 3);
                        });
                }
                else if (res.data.code === 200 && res.data.data.exist === false) {
                    setLoging(false);
                    message.error('Email chưa được đăng ký, vui lòng kiểm tra lại !', 3);
                }
            })
            .catch(error => {
                setLoging(false);
                message.error('Gửi yêu cầu không thành công ' + error.response.data.code + ' !', 3);
            });
    }
    const onFinishGetPass = async (values) => {
        await axiosInstance.post(`${CONSTANT.API.AUTH.GET_PASS + '/' + values.codeGetPass}`, { email: currentAction.email, password: values.newpassword, role: "agency" })
            .then(res => {
                if (res.data.code === 200 && res.data.data) {
                    message.success('Lấy lại mật khẩu thành công ! Bây giờ bạn có thể dùng mật khẩu mới để đăng nhập !', 3);
                    setcurrentAction({ action: 'login', title: "Lấy lại mật khẩu thành công ! Bây giờ bạn có thể dùng mật khẩu mới để đăng nhập" });
                    form.resetFields();
                }
            })
            .catch(error => {
                if (error.response.data.code === 404) {
                    message.error('Mã xác nhận không đúng. Vui lòng kiểm tra lại !', 3);
                }
                else if (error.response.data.code === 400) {
                    message.error('Mã xác nhận không đúng. Vui lòng kiểm tra lại !', 3);
                }
            });
    }

    const onFinishLogin = async (values) => {
        setLoging(true);
        await axiosInstance.post(CONSTANT.API.AUTH.LOGIN,
            {
                type: 0,
                role: 'agency',
                email: values.email,
                password: values.password
            },
            {
                validateStatus: function (status) {
                    return status < 500;
                }
            }
        )
            .then(async res => {

                if (res && res.data && res.data.code === 200 && res.data.data) {
                    const { role } = res.data.data.user;
                    if (role !== 'agency' && role !== 'admin') {
                        message.warning('Tài khoản này không có quyền truy hệ thống');
                        setLoging(false);
                        return;
                    }
                    const { accessToken, refreshToken } = res.data.data.token;
                    localStorage.setItem(CONSTANT.STORAGE_KEY_USER, JSON.stringify(res.data.data.user));
                    setAuthTokens({ accessToken, refreshToken });
                    message.success('Đăng nhập thành công!', 1)
                        .then(() => {
                            let params = queryString.parse(props.location.search);
                            let back = params.back ? params.back : '/';
                            window.location.href = back;
                        })

                }
                else if (res.data.code === 400) {
                    if (res.data.message === 'wrong_email')
                        message.error('Email không đúng!');
                    if (res.data.message === 'wrong_pass')
                        message.error('Mật khẩu không đúng!');
                    setLoging(false);
                    return;
                }
                else if (res.data.code === 403) {
                    setLoging(false);
                    message.warning('Truy cập không được phép!'); return;
                }
                else {
                    message.warning('Đăng nhập thất bại. Vui lòng thử lại sau!'); return;
                }
                setLoging(false);
            }).catch(error => { message.warning('Đăng nhập thất bại. Vui lòng thử lại sau!', 7); setLoging(false) })
    }
    return (
        <div className="bg-login login">
            <Row>
                <Col
                    lg={{ span: 12, offset: 6 }} xs={{ span: 20, offset: 2 }} sm={{ span: 12, offset: 6 }}
                    md={{ span: 12, offset: 6 }} xl={{ span: 6, offset: 9 }}
                >
                    <div className="logo" style={{ textAlign: 'center' }}>
                        <img src="vivmedic.png" alt="logo" />
                    </div>

                    <div className="slogan">{currentAction.title}</div>
                    {
                        currentAction.action === 'login' ?
                            <Form
                                name="normal_login"
                                className="login-form"
                                onFinish={onFinishLogin}
                                form={form}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { type: 'email', message: 'Email không hợp lệ!' },
                                        { required: true, message: 'Vui lòng nhập Email!' },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" className="form-control" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="Mật khẩu"
                                        className="form-control"
                                    />
                                </Form.Item>
                                <Form.Item shouldUpdate>
                                    {
                                        () => (
                                            <Button
                                                type="primary" htmlType="submit" className="login-form-button"
                                                disabled={
                                                    !form.isFieldsTouched(true) ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                }
                                                loading={loging}
                                            >
                                                Đăng nhập
                                            </Button>
                                        )
                                    }

                                </Form.Item>
                                <Button className="login-form-forgot" type='link' onClick={() => setcurrentAction({ action: 'sendemail', title: 'Lấy lại mật khẩu đăng nhập' })} style={{ float: 'right' }}>
                                    Bạn quên mật khẩu ?
                                </Button>
                            </Form> : currentAction.action === 'sendemail' ?
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    onFinish={onFinishSendEmail}
                                    form={form}
                                >
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { type: 'email', message: 'Email không hợp lệ!' },
                                            { required: true, message: 'Vui lòng nhập Email bạn đã đăng ký!' },
                                        ]}
                                    >
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Nhập Email bạn đã đăng ký" className="form-control" />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {
                                            () => (
                                                <Button
                                                    type="primary" htmlType="submit" className="login-form-button"
                                                    disabled={
                                                        !form.isFieldsTouched(true) ||
                                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                    }
                                                    loading={loging}
                                                >
                                                    Gửi yêu cầu lấy mật khẩu
                                                </Button>
                                            )
                                        }
                                    </Form.Item>
                                    <Button type='link' className="login-form-forgot" onClick={() => setcurrentAction({ action: 'login', title: 'Đăng nhập hệ thống đại lý Tatinta' })}>
                                        <RollbackOutlined />  Quay lại
                                    </Button>
                                    <Button type='link'
                                        className="login-form-forgot" onClick={() => {
                                            if (form.getFieldValue('email') !== '') {
                                                setcurrentAction({ action: 'getpass', title: 'Lấy lại mật khẩu đăng nhập' });
                                            }
                                            else {
                                                message.warning('Vui lòng nhập email đã đăng ký !');
                                            }

                                        }
                                        }
                                        style={{ float: 'right' }}>
                                        Bạn đã có mã xác nhận lấy mật khẩu ?
                                    </Button>
                                </Form> : currentAction.action === 'getpass' ?
                                    <Form
                                        name="normal_login"
                                        className="login-form"
                                        onFinish={onFinishGetPass}
                                        form={form}
                                    >
                                        <Form.Item name="codeGetPass"
                                            rules={[{ required: true, message: "Mã xác nhận lấy lại mật khẩu không được để trống" }, {
                                                type: 'string', max: 6, message: "Mã xác nhận lấy lại mật khẩu không đúng"
                                            },]}>
                                            <Input prefix={<CodeSandboxOutlined className="site-form-item-icon" />} placeholder="Nhập mã xác nhận lấy lại mật khẩu" />
                                        </Form.Item>
                                        <Form.Item name="newpassword"
                                            rules={[{ required: true, message: "Mật khẩu mới không được để trống" }, {
                                                type: 'string', max: 100, message: "Mật khẩu mới quá 100 ký tự"
                                            },]}>
                                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Nhập mật khẩu mới" />
                                        </Form.Item>
                                        <Form.Item
                                            name="confirm"
                                            dependencies={['newpassword']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng nhập lại mật khẩu mới!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('newpassword') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Nhập lại mật khẩu mới không đúng!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Nhập lại mật khẩu mới" />
                                        </Form.Item>
                                        <Form.Item shouldUpdate>
                                            {
                                                () => (
                                                    <Button
                                                        type="primary" htmlType="submit" className="login-form-button"
                                                        disabled={
                                                            !form.isFieldsTouched(true) ||
                                                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                        }
                                                        loading={loging}
                                                    >
                                                        Thay đổi mật khẩu
                                                    </Button>
                                                )
                                            }
                                        </Form.Item>
                                        <Button type='link' className="login-form-forgot" onClick={() => setcurrentAction({ action: 'login', title: 'Đăng nhập hệ thống đại lý Tatinta' })}>
                                            <RollbackOutlined />  Về đăng nhập
                                        </Button>
                                    </Form> : null
                    }
                </Col>
            </Row>
        </div >
    )

}
export default Login;