import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Tabs, message
} from 'antd';
import {
    UserAddOutlined, HomeOutlined, MedicineBoxOutlined
} from '@ant-design/icons';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import HospitalStatisticDoctor from './statistic-doctor';
import HospitalStatisticExaminationPackage from './statistic-examination-package';

const ListOrderHospitalStatistic = (props) => {
    const [hospital, setHospital] = useState(null);
    const [agency, setAgency] = useState(null);
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        setAgency(user._id);
        if (user.is_hospital) {
            axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?created_by=${user._id}`)
                .then((res) => {
                    if (res?.data?.data?.docs?.length > 0) {
                        setHospital(res.data.data.docs[0]._id);
                    }
                })
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác cơ sở y tế với Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
        }
    }, []);
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cơ sở y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thống kê công việc
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content" style={{ padding: '8px', background: 'none' }}>
                <Tabs type="card" defaultActiveKey="package" className="order-tab">
                    <Tabs.TabPane
                        tab={
                            <span>
                                <MedicineBoxOutlined />
                                Thống kê theo gói khám
                            </span>
                        }
                        key="package"
                    >{
                            hospital && <HospitalStatisticExaminationPackage forceRender={true} agency={agency} />
                        }

                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <UserAddOutlined />
                                Thống kê theo Bác sĩ
                            </span>
                        }
                        key="doctor"
                    >{
                            hospital && <HospitalStatisticDoctor forceRender={true} hospital={hospital} />
                        }

                    </Tabs.TabPane>
                </Tabs>
            </Layout.Content>
        </div >
    )

}

export default ListOrderHospitalStatistic;
