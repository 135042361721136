import React, { useState, useEffect } from 'react';
import {
    Layout, DatePicker, Form, Table, Select, Input, message, Tabs, Button, Tag,
} from 'antd';
import { HomeOutlined, RedoOutlined, SearchOutlined, MobileOutlined, UserOutlined,UserAddOutlined, BranchesOutlined, CalendarOutlined, EnvironmentOutlined, DollarOutlined, MailOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import moment from 'moment';
import * as COMMON from '../../../config/common';
const { TabPane } = Tabs;
const arrStatus = {
    'wait_paid': { text: "Chờ thanh toán", color: "grey" },
    'paid': { text: 'Đã thanh toán', color: 'orange' },
    'wait-confirm': { text: "Đang chờ duyệt", color: "orange" },
    'confirmed': { text: 'Đã chấp nhận', color: '#2db7f5' },
    'completed': { text: 'Đã hoàn thành', color: '#87d068' },
    'not-come': { text: 'Bệnh nhân không đến', color: 'grey' },
    'cancelled': { text: 'Đã từ chối', color: '#f50' }
};
const HospitalStatisticDoctor = (props) => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: '#',
            dataIndex: 'uid',
        },

        {
            title: 'Thông tin người đặt',
            dataIndex: 'contact',
            render: contact => <label><UserOutlined /> {contact.fullname_orderer}<br></br><MobileOutlined /> ({contact.dial_code}) {contact.phone}<br></br><MailOutlined /> {contact.email}</label>
        },       
        {
            title: 'Thông tin dịch vụ',
            dataIndex: 'items',
            render: items => <label><UserAddOutlined /> {COMMON.createDoctorName(items[0]?.doctor.academic_rank, items[0]?.doctor.degree, items[0]?.doctor.category.short_name, items[0]?.doctor.fullname)}<br></br><BranchesOutlined /> {items[0].service.name}<br></br><DollarOutlined /> {items[0].is_free ? 'Khám miễn phí' : items[0].price.toLocaleString('en-US') + ' ₫'}<br></br><MedicineBoxOutlined /> {items[0].is_online ? 'Khám trực tuyến' : 'Khám thường'}</label>
        },
        {
            title: 'Thời gian và địa điểm',
            render: (text, record) => <label><CalendarOutlined /> {COMMON.getDayOfWeek(record?.date?.day_of_week.toString())} {record?.date?.day}/{record?.date?.month}/{record?.date?.year} - {moment(`${record.time.time_h}:${record.time.time_m}`, "HH:mm").format("HH:mm")}<br></br><HomeOutlined /> {record.location?.name}<br></br><EnvironmentOutlined /> {record.location?.address}</label>
        },
        {
            title: 'Tổng tiền', dataIndex: 'total', width: '150px', align: 'right',
            render: total => COMMON.currencyFormat(parseFloat(total)) + ' ₫'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status', width: '150px',
            render: status => <Tag color={arrStatus[status]?.color}>{arrStatus[status]?.text}</Tag>
        }
    ];
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState('all');
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(0);
    const [countPaging, setCountPaging] = useState(0);
    const [statistics, setStatistics] = useState([]);
    const [optionFilter, setOptionFilter] = useState({});
    const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0,
        perPage: 10,
        hideOnSinglePage: true,
        showLessItems: true
    });
    // effect
    useEffect(() => {
        loadStatistic({ page: 1, perPage: 10 });
    }, [props.hospital]);
    const changeStatusOrder = async (statusOrder) => {
        let options = { ...optionFilter };
        if (statusOrder !== status) {
            await setOrders([]);
            await setStatus(statusOrder);
            if (statusOrder !== 'all' && statusOrder) {
                options.status = statusOrder;
            }
            else {
                delete options.status;
            }
            loadStatistic({
                page: 1,
                perPage: pagination.perPage,
                ...options
            });
            setOptionFilter(options);
        }
    }
    const loadStatistic = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_hospital) {
            await axiosInstance.get(`${CONSTANT.API.DOCTOR.STATISTIC}?${query}&hospital=${props?.hospital}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200 && res.data.data.docs.length > 0) {
                        let arrStatistic = [];
                        let _total = 0;
                        let _count = 0;
                        if (!queryParam.status) {
                            ['wait_paid', 'paid', 'wait-confirm', 'confirmed', 'completed', 'not-come', 'cancelled'].map((item) => {
                                for (const statistic of res.data.data.docs) {
                                    if (statistic._id === item) {
                                        arrStatistic.push(statistic);
                                        _total += statistic.total;
                                        _count += statistic.count;
                                        break;
                                    }
                                }
                            });
                            setStatistics(arrStatistic);
                            setTotal(_total);
                            setCount(_count);
                            setCountPaging(_count);
                        }
                        else {
                            arrStatistic.push(res.data.data.docs[0]);
                            setCountPaging(res.data.data.docs[0].count)
                        }
                        loadOrder(queryParam);
                    }
                    else {
                        setStatistics([]);
                        setOrders([]);
                        setCount(0);
                        setTotal(0);
                    }
                })
                .catch(error => message.warning('Đối tác chuyên môn không tồn tại!' + error.message, 5).then(() => props.history.push('/')))
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác chuyên môn Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
        }
        setLoading(false);
    }
    const loadOrder = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_hospital) {
            await axiosInstance.get(`${CONSTANT.API.DOCTOR.ORDER}?${query}&hospital=${props?.hospital}&statistic=true`)
                .then(async res => {     
                    if (res && res.data && res.data.code === 200) {
                        // Set giá trị cho pagination
                        let _pagination = { ...pagination, current:queryParam.page };
                        _pagination.total = res.data.data.total;
                        setPagination(_pagination);
                        setOrders(res.data.data.docs);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    setOrders([]);
                    message.warning('Lỗi dữ liệu nhập không đúng!', 3);
                });
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác chuyên môn Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
        }
        setLoading(false);
    }
    // Xử lý sự kiện search
    const onSearch = async (values) => {
        let options = {};
        if (values.q !== '' && values.q !== undefined) {
            if (values.field === 'name') {
                options.fullname_patient = values.q;
            }

            if (values.field === 'uid') {
                options.uid = values.q.replace('VIVMEDIC-D', '');
            }
        }
        if (values.date_range) {
            options.date_range = JSON.stringify({
                start: moment(values.date_range[0]).format("YYYY-MM-DD"),
                end: moment(values.date_range[1]).format("YYYY-MM-DD")
            });
        }
        if (status !== 'all' && status) {
            options.status = status;
        }
        loadStatistic({
            page: 1,
            perPage: pagination.perPage,
            ...options
        });
        setOptionFilter(options);
    }
    const reload = () => {
        loadOrder({ page: 1, perPage: pagination.perPage });
        form.resetFields();
    }
    return (
        <Layout.Content className="layout-content" style={{ padding: '8px', background: 'none' }}>
            {/* Begin form search */}
            <Form
                layout="inline"
                onFinish={onSearch}
                style={{ marginBottom: 20 }}
                initialValues={{
                    field: 'name'
                }}
                style={{ margin: '0px -8px 20px -8px' }}
                form={form}
            >
                <Form.Item name="q"
                    /*required rules={[
                        { required: true, message: 'Vui lòng nhập Tên người khám hoặc Mã lịch hẹn' }
                    ]}*/
                    extra="(VD: Nguyễn Văn A hoặc VIVMEDIC-D123)"
                >
                    <Input
                        type="text"
                        placeholder="Nhập Tên người khám hoặc Mã lịch hẹn "
                        style={{ width: 300 }}
                    />
                </Form.Item>
                <Form.Item name="field">
                    <Select
                        style={{ width: 150 }}
                    >
                        <Select.Option value="name">Tên người khám</Select.Option>
                        <Select.Option value="uid">Mã lịch hẹn</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="date_range">
                    <DatePicker.RangePicker format="DD-MM-YYYY" />
                </Form.Item>
                <Form.Item>
                    <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                        Tìm
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button icon={<RedoOutlined />} onClick={reload}>
                        Tải lại trang
                    </Button>
                </Form.Item>
            </Form>
            {/* End form search */}

            <Tabs defaultActiveKey="all" onChange={(key) => {
                changeStatusOrder(key);
            }}>
                <TabPane tab={<div style={{ color: '#1890ff' }}>{'Tất cả (' + count + ')'}<br></br>{total.toLocaleString('en-US') + ' ₫'}</div>} key="all">
                </TabPane>
                {
                    statistics && statistics.length > 0 && statistics.map((item) => (
                        <TabPane tab={<div style={{ color: arrStatus[item?._id]?.color }}>{arrStatus[item?._id]?.text + ' (' + item.count + ')'}<br></br>{item.total.toLocaleString('en-US') + ' ₫'}</div>} key={item._id}>
                        </TabPane>
                    ))
                }
            </Tabs>
            {
                orders && <Table
                    dataSource={orders}
                    columns={columns}
                    loading={loading}
                    pagination={{
                        onChange: async (page, pageSize) => {
                            let para = { page, perPage: pageSize };
                            if (status !== 'all') {
                                para.status = status;
                            }                            
                            loadOrder(para);
                        },
                        ...pagination
                    }}
                    rowKey={record => record._id}
                />
            }
        </Layout.Content>
    )

}

export default HospitalStatisticDoctor;