import React, { Component } from 'react';
import AppRouter from './routes/routes';

class App extends Component {
	render() {
		return (
			<AppRouter/>
		)
	}
}
export default App;
