import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col, Tag } from 'antd';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import moment from 'moment';
const arrStatus = {
    'wait_paid': { text: "Chờ thanh toán", color: "orange" },
    'paid': { text: 'Đã thanh toán', color: 'green' },
    'confirmed': { text: 'Đã xác nhận', color: 'blue' },
    'agency_begin': { text: 'Bạn đã xác nhận bắt đầu', color: 'orange' },
    'begin': { text: 'Khách đã xác nhận bắt đầu', color: 'green' },
    'agency_completed': { text: 'Bạn đã xác nhận hoàn thành', color: 'green' },
    'completed': { text: 'Khách xác nhận hoàn thành', color: 'green' },
    'abandoned': { text: 'Khách đã hủy', color: 'red' },
    'cancelled': { text: 'Bạn đã hủy tour này', color: 'red' },
    'refunded': { text: 'Đã hoàn hoàn tiền', color: 'orange' },
};
const ModalInfoOrder = (props) => {    
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleCancel = useCallback(() => {
        props.cancel();
    }, []);
    useEffect(() => {
        //console.log(props.orderId);
        loadOrder();
    }, [props.orderId]);
    // load Agency    
    const loadOrder = async () => {
        setLoading(true);        
        if (props && props.orderId) {
            await axiosInstance.get(`${CONSTANT.API.HOSPITAL.ORDER}/${props.orderId}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        setOrder(res.data.data)
                    }
                })
        }
        setLoading(false);
    }
    
    return (
        <Modal title="Thông tin đặt dịch vụ"
            visible={props.visible}
            onOk={handleCancel}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Đóng"
            className="modal-book-facility"
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            width={800}
            cancelButtonProps={{ style: { display: 'none' } }}
            maskClosable={false}
        >
            {
                order && <Row gutter={24}>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                       Họ tên người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.contact.order_fullname}
                    </Col>                    
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Họ tên người khám: 
                    </Col>
                    <Col xs={12} md={7}>
                        {order.contact.patient_fullname}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Email người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.contact.email}
                    </Col> 
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Đ.Thoại người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {'+'+order.contact.dial_code+order.contact.phone}
                    </Col> 
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Ngày đặt hẹn:
                    </Col>
                    <Col xs={12} md={7}>
                        {`${moment(new Date(order.created_at)).format('DD/MM/YYYY HH:mm')}`}
                    </Col> 
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Thời gian hẹn:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.examination_date && order.examination_time && `${moment(new Date(order.examination_date)).format('DD/MM/YYYY')} ${order.examination_time}`}
                    </Col>                   
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Chuyên khoa: 
                    </Col>
                    <Col xs={12} md={7}>
                        {order.specialty && order.specialty.name}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Trạng thái: 
                    </Col>
                    <Col xs={12} md={7}>
                    {(order.status && arrStatus[order.status]) ? <Tag color={arrStatus[order.status].color}>{arrStatus[order.status].text}</Tag>:<Tag color='black'>Rác</Tag>}
                    </Col>                    
                </Row>
            }

        </Modal>
    )
}
export default ModalInfoOrder;