import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, List, Row, Col, message, Popconfirm, Divider, Space, Button, Form, Select, Input, Empty, Tag
} from 'antd';
import { HomeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, RedoOutlined, CalendarOutlined, CloseCircleOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import ModalActionDoctor from './components/action-doctor'
import ModalActionService from '../doctor/components/action-service';
import * as COMMON from '../../config/common';
const Doctors = (props) => {
    const [form] = Form.useForm();
    const [hospital, setHospital] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [doctorData, setDoctorData] = useState(null);
    const [total, setTotal] = useState(0);
    const [isModalServiceVisible, setIsModalServiceVisible] = useState(false);
    const [isModalDoctorVisible, setIsModalDoctorVisible] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [doctorId, setDoctorId] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const openActionDoctor = async (data) => {
        await setDoctorData(data);
        setIsModalDoctorVisible(true);
    }
    // effect
    useEffect(() => {
        loadDoctors({ page: 1, pageSize: 10 });
    }, []);

    const loadDoctors = async (pagination) => {
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        setLoading(true);
        let params = {
            pageDoc: pagination.page, perPageDoc: pagination.pageSize, created_by: user._id, action: 'doctors'
        }
        if (form.getFieldValue('specialty') && form.getFieldValue('specialty') !== 'all')
            params.specialtyDoc = form.getFieldValue('specialty');
        if (form.getFieldValue('unit') && form.getFieldValue('unit') !== 'all')
            params.unit = form.getFieldValue('unit');
        if (form.getFieldValue('fullname'))
            params.fullname = form.getFieldValue('fullname');
        let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (user.is_hospital) {
            await axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?${query}`)
                .then(async res => {
                    if (res.data.data && res.data.data.docs.length > 0) {
                        console.log(res.data.data);
                        const { total, hospital, docs } = res.data.data;
                        setHospital(hospital);
                        setData(docs[0]);
                        setTotal(total);
                    }
                    setLoading(false);
                })
                .catch(error => message.warning('Cơ sở y tế không tồn tại!' + error.message, 5).then(() => { setLoading(false); props.history.push('/') }))
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác cơ sở y tế với Tatinta, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
            setLoading(false);
        }

    }
    const delDoctor = async (doctorId) => {
        setLoading(true);
        let dataSave = {
            action: 'delDoctor',
            doctorId: doctorId
        }
        await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${hospital?._id}`, dataSave)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    loadDoctors({ page: page, pageSize: pageSize });
                    message.success('Xóa khoa thành công !');
                }
                else {
                    message.error('Xóa khoa thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            }).catch(() => {
                message.error('Xóa thất bại! Vui lòng thử lại sau.');
                setLoading(false)
            });;
    }
    const delService = async (doctorId, serviceId) => {
        setLoading(true);
        let dataSave = {
            action: 'delService',
            serviceId: serviceId
        }
        await axiosInstance.patch(`${CONSTANT.API.DOCTOR.DOCTOR}/${doctorId}`, dataSave)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Xóa thành công !');
                    loadDoctors({ page: page, pageSize: pageSize });
                }
                else {
                    message.error('Xóa thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            }).catch(() => {
                message.error('Xóa thất bại! Vui lòng thử lại sau.');
                setLoading(false)
            });
    }
    const selected = async (value) => {
        loadDoctors({ page: 1, pageSize: 10 });
    }
    const openActionService = async (data) => {
        await setServiceData(data);
        setIsModalServiceVisible(true);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cơ sở y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Danh sách bác sĩ
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {/* Begin form search */}
                <Form
                    layout="inline"
                    style={{ marginBottom: 20 }}
                    initialValues={{
                        fullname: '',
                        specialty: 'all',
                        unit: 'all'
                    }}
                    form={form}
                >
                    <Form.Item name="specialty">
                        <Select placeholder="Chọn chuyên khoa" style={{ width: 200 }} onChange={(value) => selected(value)}>
                            <Select.Option key='all'>Tất cả chuyên khoa</Select.Option>
                            {
                                data.specialties && data.specialties.length > 0 &&
                                data.specialties.map(item => <Select.Option key={item._id}>{item.name_other}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="unit">
                        <Select placeholder="Chọn chuyên khoa" style={{ width: 200 }} onChange={(value) => selected(value)}>
                            <Select.Option key='all'>Tất cả đơn vị</Select.Option>
                            {
                                data.units && data.units.length > 0 &&
                                data.units.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                            }</Select>
                    </Form.Item>
                    <Form.Item name="fullname" required rules={[
                        { required: true, message: 'Vui lòng nhập tên bác sĩ' }
                    ]}>
                        <Input
                            type="text"
                            placeholder="Nhập tên bác sĩ"
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" onClick={() => loadDoctors({ page: 1, pageSize: 10 })}>
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={() => { form.resetFields(); loadDoctors({ page: 1, pageSize: 10 }) }}>
                            Đặt lại
                        </Button>
                    </Form.Item>
                    <Button type="primary" primary="true" icon={<PlusOutlined />}
                        onClick={() => openActionDoctor({ specialties: data.specialties, units: data.units })}
                    >
                        Thêm bác sĩ
                    </Button>
                </Form>
                {/* End form search */}

                <List
                    loading={loading}
                    dataSource={data.doctors}
                    renderItem={(item, index) => (
                        <Row gutter={[{ xs: 16, lg: 24 }, { xs: 16, lg: 24 }]} className="item-doctor" key={index}>
                            <Col xs={6} lg={4}>
                                <div className="box-cover">
                                    <img src={`${CONSTANT.STATIC_URL}${item?.doctor.image}`} alt="" />
                                </div>
                            </Col>
                            <Col xs={18} lg={20}>
                                <div className="doctor-name">{COMMON.createDoctorName(item?.doctor.academic_rank, item?.doctor.degree, item?.doctor.category.short_name, item?.doctor.fullname)}</div>
                                <div className="sapo">
                                    {
                                        item?.doctor?.services.map((item2, index2) => (
                                            <Tag color='green' key={index2} style={{ marginBottom: 10, fontSize: 14 }} >{item2.name} - {item2.price > 0 ? item2.price.toLocaleString('en-US') + ' ₫' : 'Miễn phí'}
                                                <br></br>
                                                <Button style={{ padding: '0', }} type="link" title='Sửa thông tin'
                                                    onClick={async () => {
                                                        await setDoctorId(item.doctor._id);
                                                        openActionService(
                                                            {
                                                                serviceId: item2._id,
                                                                serviceName: item2.name,
                                                                summary: item2.summary,
                                                                price: item2.price,
                                                                price_note: item2.price_note,
                                                                is_online: item2.is_online,
                                                                is_free: item2.is_free,
                                                                is_f0: item2.is_f0,
                                                                is_government_insurance: item2.is_government_insurance,
                                                                note_government_insurance: item2.note_government_insurance,
                                                                is_direct_guarantee_insurance: item2.is_direct_guarantee_insurance,
                                                                note_direct_guarantee_insurance: item2.note_direct_guarantee_insurance,
                                                                list_direct_guarantee_insurance: item2.list_direct_guarantee_insurance,
                                                                price_list: item2.price_list,
                                                            }
                                                        )
                                                    }} ><EditOutlined /> Sửa</Button>
                                                <Button style={{ padding: '0 0 0 15px', }} type="link" title='Lập lịch khám' onClick={() => props.history.push(`/hospital/${hospital._id}/doctor/${item.doctor._id}/service/${item2?._id}/setting/`)}><CalendarOutlined /> Lập lịch</Button>
                                                <Popconfirm title="Sau khi xóa tất cả thông tin lịch khám sẽ bị xóa ! Bạn muốn xóa công việc này ?" okText="Đồng ý" onConfirm={() => { delService(item.doctor._id, item2._id) }}>
                                                    <Button style={{ padding: '0 0 0 15px', }} type="link" danger title='Xóa công việc'><CloseCircleOutlined /> Xóa</Button>
                                                </Popconfirm>
                                            </Tag>
                                        ))
                                    }
                                    <Button type="link" icon={<PlusOutlined />} onClick={async () => { await setDoctorId(item.doctor._id); openActionService(null) }}> Thêm công việc
                                    </Button>
                                </div>
                                <Button
                                    type="primary" primary="true"
                                    onClick={() => openActionDoctor({ doctorId: item.doctor._id, avatar: item?.image, doctorname: item?.fullname, specialtyId: item._id, specialty: item?.specialty, unit: item?.unit, regency: item?.regency, sort: item?.sort, specialties: data.specialties, units: data.units })}
                                    style={{ marginRight: 20 }}
                                >
                                    <EditOutlined /> Sửa thông tin
                                </Button>
                                <Popconfirm title="Bạn muốn xóa bác sĩ này? Sau khi xóa tất cả thông tin liên quan sẽ bị xóa theo và ko thể khôi phục !" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => delDoctor(item?._id)}>
                                    <Button type="primary" danger><DeleteOutlined /> Xóa bác sĩ</Button>
                                </Popconfirm>
                            </Col>
                        </Row>

                    )}
                    pagination={{
                        onChange: async (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                            loadDoctors({ page: page, pageSize: pageSize });
                        },
                        pageSize: 10,
                        total: total,
                        hideOnSinglePage: true
                    }}
                    locale={{ emptyText: <Empty description="Chưa có bác sĩ nào" /> }}
                />

            </Layout.Content>
            <ModalActionDoctor visible={isModalDoctorVisible} data={doctorData} hospital={hospital}
                cancel={(data) => {
                    setIsModalDoctorVisible(false);
                    if (data) {
                        loadDoctors({ page: page, pageSize: pageSize });
                    }
                }} />
            <ModalActionService visible={isModalServiceVisible} data={serviceData} doctorId={doctorId}
                cancel={(data) => {
                    setIsModalServiceVisible(false);
                    if (data) {
                        loadDoctors({ page: page, pageSize: pageSize });
                    }
                }} />
        </div >
    )

}

export default Doctors;