import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col, Tag, Button, Popconfirm, Input, message, Spin, } from 'antd';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import moment from 'moment';
const arrStatus = {
    'wait-confirm': { text: "Chờ xác nhận", color: "orange" },
    'confirmed': { text: 'Đã xác nhận', color: 'blue' },
    'patient-begin': { text: 'Khách/Bệnh nhân đã xác nhận bắt đầu', color: 'orange' },
    'doctor-begin': { text: 'Bác sĩ đã xác nhận bắt đầu', color: 'orange' },
    'patient-completed': { text: 'Khách/Bệnh nhân đã xác nhận hoàn thành', color: 'green' },
    'completed': { text: 'Đã hoàn thành', color: 'green' },
    'not-come': { text: 'Quá hẹn không đến', color: 'grey' },
    'abandoned': { text: 'Khách đã hủy', color: 'red' },
    'cancelled': { text: 'Bác sĩ đã hủy', color: 'red' },
    'refunded': { text: 'Đã hoàn hoàn tiền', color: 'orange' },
};
const ModalInfoOrderService = (props) => {
    const [order, setOrder] = useState(null);
    const [reasonCancel, setReasonCancel] = useState('');
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleCancel = useCallback(() => {
        props.cancel(reload);
    }, [reload]);
    useEffect(() => {
        loadOrder();
    }, [props.orderId]);
    // load Agency    
    const loadOrder = async () => {
        setLoading(true);
        if (props && props.orderId) {
            await axiosInstance.get(`${CONSTANT.API.DOCTOR.ORDER}/${props.orderId}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        setOrder(res.data.data)
                    }
                })
        }
        setLoading(false);
    }
    const updateStatus = async (statusUpd) => {
        setLoading(true);
        let upd = { actUpd: 'udpStatus', status: statusUpd };
        if (statusUpd === 'cancelled') {
            upd.reason_cancel = reasonCancel;
            if (reasonCancel === '') {
                message.error('Vui lòng nhập lý do từ chối! Cảm ơn Bác sĩ!');
                return;
            }
            else if (reasonCancel.length > 200) {
                message.error('Lý do từ chối tối đa 200 ký tự !');
                return;
            }
        }
        else if(statusUpd === 'not-come') {
            upd.reason_cancel = 'Đã xác nhận lúc :' + moment(Date.now()).format('DD/MM/YYYY : HH:mm')
        }
            
        setLoading(true);
        if (props.orderId) {
            await axiosInstance.patch(`${CONSTANT.API.DOCTOR.ORDER}/${props.orderId}`, upd)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        setOrder(res.data.data);
                        if (statusUpd === 'confirmed') {

                            message.success('Chấp nhận thành công!')
                        }
                        else if (statusUpd === 'cancelled') {
                            message.success('Từ chối thành công!')
                        }
                        else if (statusUpd === 'not-com') {
                            message.success('Từ chối thành công!')
                        }
                        else if (statusUpd === 'doctor-begin') {
                            message.success('Bắt đầu công thành công !', 5)
                        }
                        else if (statusUpd === 'patient-completed') {
                            message.success('Bắt đầu công thành công !', 5)
                        }
                        setLoading(false);
                        setReload(true);
                    }
                    else {
                        setLoading(false);
                        message.error('Đã xảy ra lỗi! Vui lòng thử lại sau !');
                    }
                })
                .catch(error => {
                    setLoading(false);
                    message.error('Đã xảy ra lỗi! Vui lòng thử lại sau ! ', 4);
                    console.log(error.message);
                });
        }
        else {
            setLoading(false);
            message.error('Không tìm thấy mã lịch hẹn !');
        }
    }
    return (
        <Modal title="Thông tin đặt hẹn"
            visible={props.visible}
            onOk={handleCancel}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Đóng"
            className="modal-book-facility"
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            width={800}
            cancelButtonProps={{ style: { display: 'none' } }}
            maskClosable={false}
        >   {
                loading &&
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
            {
                order && <Row gutter={24}>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Họ tên người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.contact.fullname_orderer}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Họ tên bệnh nhân:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.contact.fullname_orderer}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Email người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.contact.email}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Đ.Thoại người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {'+' + order.contact.dial_code + order.contact.phone}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Ngày đặt hẹn:
                    </Col>
                    <Col xs={12} md={7}>
                        {`${moment(new Date(order.created_at)).format('DD/MM/YYYY HH:mm')}`}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Thời gian hẹn:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.date && order.date.day_of_week && 'Thứ ' + (order.date?.day_of_week + 1) + ' - ' + (order.date?.day) + '/' + (order.date?.month) + '/' + (order.date?.year) + '  ' + (order.time?.time_h) + ':' + (order.time?.time_m)}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Khám và điều trị:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.items && order.items.length > 0 && order.items[0].service.name}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Thời gian thực hiện:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.items && order.items.length > 0 && order.items[0].service?.duration + ' phút'}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Giá khám:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.items && order.items.length > 0 && order.items[0].price.toLocaleString('en-US') + ' ₫'}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Trạng thái:
                    </Col>
                    <Col xs={12} md={7}>
                        {(order.status && arrStatus[order.status]) ? <Tag color={arrStatus[order.status].color}>{arrStatus[order.status].text}</Tag> : <Tag color='black'>Rác</Tag>}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Địa điểm khám:
                    </Col>
                    <Col xs={12} md={7}>
                        {order?.location?.name}
                    </Col>
                    {
                        order.status === 'wait-confirm' ? <Col xs={12} md={12}> <Popconfirm title={<div>Sau khi chấp nhận, quý Bác sĩ vui lòng liên hệ để nhắc nhở <br></br> khách hàng/Bệnh nhân đến khám đúng giờ ! Cảm ơn Bác sĩ!</div>}
                            okText="Đồng ý" cancelText="Đóng" onConfirm={() => updateStatus('confirmed')}>
                            <Button type="primary" style={{ marginRight: '20px', marginBottom: '20px' }}>
                                Chấp nhận
                            </Button>
                        </Popconfirm>
                            <Popconfirm title={<div><label>Lý do từ chối</label><br></br>
                                <Input.TextArea
                                    showCount maxLength={200}
                                    onChange={e => setReasonCancel(e.target.value)}
                                    style={{ width: '300px', height: '100px', resize: 'none' }}
                                    placeholder="Vui lòng nhập lý do từ chối để Vivmedic thông báo lại cho khách hàng/Bệnh nhân ! Cảm ơn Bác sĩ!"
                                />
                            </div>} okText="Đồng ý" cancelText="Đóng" onConfirm={() => updateStatus('cancelled')}>
                                <Button type="danger">
                                    Từ chối
                                </Button>
                            </Popconfirm></Col> : order.status === 'confirmed' ? <Col xs={12} md={12}>
                                <Popconfirm title={<div>Khách/bệnh nhân đặt hẹn quá giờ không đến</div>}
                                    okText="Đồng ý" cancelText="Đóng" onConfirm={() => updateStatus('not-come')}>
                                    <Button style={{ marginRight: '20px', marginBottom: '20px' }}>
                                      Hủy lịch vì quá hẹn không đến
                                    </Button>
                                </Popconfirm>
                            </Col> : order.status === 'patient-completed' ? <Col xs={12} md={12}>
                                <Popconfirm title={<div>Hoàn thành công việc, cảm ơn Bác sĩ !</div>}
                                    okText="Đồng ý" cancelText="Đóng" onConfirm={() => updateStatus('completed')}>
                                    <Button type='primary'>
                                      Hoàn thành công việc
                                    </Button>
                                </Popconfirm>
                            </Col> : null
                    }
                    {
                        order.items[0].service?.is_online === true && order.status === 'paid' &&
                        <Col span={24} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                            Link video call: <a href={`${CONSTANT.BASE_MEET}?id=${order.verify_code}`} target="_blank">{`${CONSTANT.BASE_MEET}?id=${order.verify_code}`}</a>
                        </Col>
                    }

                </Row>
            }

        </Modal>
    )
}
export default ModalInfoOrderService;