import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  HomeOutlined,
  ShopOutlined,
  MedicineBoxOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import CONSTANT from "../../config/constant";
const { Sider } = Layout;

const { SubMenu } = Menu;

export default class MenuSider extends React.PureComponent {
  state = {
    current: "dashboard",
    user: JSON.parse(localStorage.getItem(CONSTANT.STORAGE_KEY_USER)),
  };

  // Menu click handle
  handleClick = (e) => {
    this.setState({ current: e.key });
  };
  render() {
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        width="230"
      >
        <div className="logo">
          <Link to="/">
            <img src="vivmedic-white.png" alt="logo vivmedic" />
          </Link>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/">Trang chủ</Link>
          </Menu.Item>

          {this.state.user && this.state.user.is_hospital === true ? (
            <SubMenu key="hospital" icon={<HomeOutlined />} title="Cơ sở y tế">
              <Menu.Item key="hpt">
                <Link to="/hospital">Thông tin đăng ký</Link>
              </Menu.Item>
              <Menu.Item key="hpts">
                <Link to="/hospital/specialties">Quản lý chuyên khoa</Link>
              </Menu.Item>
              <Menu.Item key="hptu">
                <Link to="/hospital/units">Quản lý đơn vị</Link>
              </Menu.Item>
              <Menu.Item key="hptd">
                <Link to="/hospital/doctors">Danh sách bác sĩ</Link>
              </Menu.Item>
              {/* <Menu.Item key="hptservice">
                <Link to="/hospital/services">Quản lý gói khám</Link>
              </Menu.Item> */}
              <Menu.Item key="hptb">
                <Link to="/hospital/bookings">Quản lý lịch hẹn</Link>
              </Menu.Item>
              <Menu.Item key="hnkkcb">
                <Link to="/hospital/diary/bookings">
                  Nhật ký khám chữa bệnh
                </Link>
              </Menu.Item>
              <Menu.Item key="hptkcv">
                <Link to="/hospital/statistic">Thống kê công việc</Link>
              </Menu.Item>
            </SubMenu>
          ) : null}
          {this.state.user && this.state.user.is_doctor === true ? (
            <SubMenu
              key="doctor"
              icon={<BranchesOutlined />}
              title="Dành cho Bác sĩ"
            >
              <Menu.Item key="dt">
                <Link to="/doctor">Thông tin đăng ký</Link>
              </Menu.Item>
              <Menu.Item key="dtdv">
                <Link to="/doctor/services">Quản lý công việc</Link>
              </Menu.Item>
              <Menu.Item key="dtb">
                <Link to="/doctor/bookings">Quản lý lịch hẹn</Link>
              </Menu.Item>
              <Menu.Item key="nkkcb">
                <Link to="/doctor/diary/bookings">Nhật ký khám chữa bệnh</Link>
              </Menu.Item>
              <Menu.Item key="dttkcv">
                <Link to="/doctor/statistic">Thống kê công việc</Link>
              </Menu.Item>
            </SubMenu>
          ) : null}
          {this.state.user && (this.state.user.is_service === true || this.state.user.is_doctor === true || this.state.user.is_hospital === true) ? (
            <SubMenu
              key="service"
              icon={<MedicineBoxOutlined />}
              title="Dịch vụ y tế"
            >
              {
                (this.state.user.is_doctor === true || this.state.user.is_hospital === true) ? ''
                  :
                  this.state.user.is_service === false ? '' :
                    <Menu.Item key="sm">
                      <Link to="/service/agency">Thông tin đăng ký</Link>
                    </Menu.Item>
              }

              <Menu.Item key="sml">
                <Link to="/services">Quản lý dịch vụ</Link>
              </Menu.Item>
              <Menu.Item key="svc">
                <Link to="/service/vouchers">Voucher khuyến mãi</Link>
              </Menu.Item>
              <Menu.Item key="smb">
                <Link to="/service/bookings">Quản lý lịch hẹn</Link>
              </Menu.Item>
              <Menu.Item key="nklh">
                <Link to="/service/diary/bookings">Nhật ký lịch hẹn</Link>
              </Menu.Item>
              <Menu.Item key="tklh">
                <Link to="/service/statistic">Thống kê dịch vụ</Link>
              </Menu.Item>
            </SubMenu>
          ) : null}
          {this.state.user && this.state.user.is_seller === true ? (
            <SubMenu key="seller" icon={<ShopOutlined />} title="Bán hàng y tế">
              <Menu.Item key="sl">
                <Link to="/seller">Thông tin đăng ký</Link>
              </Menu.Item>
              <Menu.Item key="slp">
                <Link to="/seller/product">Quản lý sản phẩm</Link>
              </Menu.Item>
              <Menu.Item key="slb">
                <Link to="/seller/booking">Quản lý đơn hàng</Link>
              </Menu.Item>
              <Menu.Item key="sls">
                <Link to="/seller/statistic">Thống kê đơn hàng</Link>
              </Menu.Item>
            </SubMenu>
          ) : null}
        </Menu>
      </Sider>
    );
  }
}
