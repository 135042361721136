import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, message, Popconfirm, Divider, Space, Button, Select
} from 'antd';
import { HomeOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import ModalActionUnit from './components/action-unit'
const Units = (props) => {
    const columns = [
        {
            title: 'STT',
            key: 'index',
            width: '30px',
            render : (text, record, index) => index+1,
          },
        {
            title: 'Tên đơn vị',
            dataIndex: 'name',
        },
        {
            title: 'Sắp xếp',
            width: '100px',
            dataIndex: 'sort',
        },
        {
            title: (<div style={{ textAlign: 'center' }}>Hành động</div>), width: '200px',
            render: (record) => (
                <Space size="middle">
                    <Button
                        type='link'
                        onClick={() => openActionUnit({ _id: record?._id, specialty: record?.specialty, specialty_name: record?.specialty_name, name: record?.name, summary: record?.summary, sort: record?.sort, hospitalId: hospital?._id })}
                    ><EditOutlined /> Sửa</Button>
                    <Divider type="vertical" />
                    <Popconfirm title="Bạn muốn xóa đơn vị này? Các thông tin liên quan đến đơn vị sẽ bị xóa không thể khôi phục !" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => delUnit(record._id)}>
                        <Button type="link"><DeleteOutlined /> Xóa</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const [hospital, setHospital] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [unitsSearch, setUnitsSearch] = useState([]);
    const [units, setUnits] = useState([]);
    const [loading, setConfirmLoading] = useState(false);
    const [unitData, setUnitData] = useState(null);
    const [specialties, setSpecialties] = useState([]);
    const openActionUnit = async (data) => {
        await setUnitData(data);
        setIsModalVisible(true);
    }
    // effect
    useEffect(() => {
        loadSpecialties();
        loadUnits();
    }, []);

    const loadUnits = async () => {
        setConfirmLoading(true);
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_hospital) {
            await axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?created_by=${user._id}&action=units`)
                .then(async res => {
                    if (res.data.data && res.data.code === 200) {
                        setHospital(res.data.data.hospital);
                        setUnitsSearch(res.data.data.docs.sort((a, b) => (a.sort > b.sort) ? 1 : -1));
                        setUnits(res.data.data.docs.sort((a, b) => (a.sort > b.sort) ? 1 : -1));
                    }
                    setConfirmLoading(false);
                })
                .catch(error => message.warning('Cơ sở y tế không tồn tại!' + error.message, 5).then(() => { setConfirmLoading(false); props.history.push('/') }))
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác cơ sở y tế với Tatinta, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
            setConfirmLoading(false);
        }

    }
    const selected = async (value) => {
        if(value && value !=='all'){
            let search = [];
            for (const item of units) {
                if (item.specialty === value) {
                    search.push(item);
                }
            }
            setUnitsSearch(search);
        }
        else{
            loadUnits();
        }
    }
    const delUnit = async (unitId) => {
        setConfirmLoading(true);
        let dataSave = {
            action: 'delUnit',
            unitId: unitId
        }
        await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${hospital?._id}`, dataSave)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    loadUnits();
                    message.success('Xóa khoa thành công !');
                }
                else {
                    message.error('Xóa khoa thất bại! Vui lòng thử lại sau.');
                }
                setConfirmLoading(false);
            }).catch(() => {
                message.error('Xóa thất bại! Vui lòng thử lại sau.');
                setConfirmLoading(false)
            });;
    }
    // load Specialties
    const loadSpecialties = async () => {
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_hospital) {
            await axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?created_by=${user._id}&action=specialties`)
                .then(async res => {
                    if (res.data.data && res.data.data.docs.length > 0) {
                        setSpecialties(res.data.data.docs.sort((a, b) => (a.sort > b.sort) ? 1 : -1));
                    }
                    else {
                        message.warning('Vui lòng tạo chuyên khoa trước khi thêm đơn vị !', 4).then(() => { setConfirmLoading(false); props.history.push('/hospital/specialties') })
                    }
                    setConfirmLoading(false);
                })
                .catch(error => message.warning('Cơ sở y tế không tồn tại!' + error.message, 5).then(() => { setConfirmLoading(false); props.history.push('/') }))
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác cơ sở y tế với Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
            setConfirmLoading(false);
        }
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cơ sở y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Quản lý đơn vị
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                <Select placeholder="Chọn chuyên khoa" defaultValue='all' style={{width:200}} onChange={(value) => selected(value)}>
                    <Select.Option key='all'>Tất cả chuyên khoa</Select.Option>
                    {
                        specialties.length > 0 &&
                        specialties.map(item => <Select.Option key={item._id}>{item.name_other}</Select.Option>)
                    }
                </Select>
                <Button style={{float:'right'}} type="primary" primary="true" icon={<PlusOutlined />}
                    onClick={() => openActionUnit(null)}
                >
                    Thêm đơn vị
                </Button>
                {
                    specialties && <Table dataSource={unitsSearch}
                        columns={columns} loading={loading}
                        pagination={false}
                        rowKey={record => record._id} />
                }

            </Layout.Content>
            <ModalActionUnit visible={isModalVisible} data={unitData} specialties={specialties} hospitalId={hospital?._id}
                cancel={(data) => {
                    setIsModalVisible(false);
                    if (data) {
                        loadUnits();
                    }
                }} />
        </div >
    )

}

export default Units;