import CONSTANT from "./constant";
import moment from "moment";
// off auto complete select
export function offAutoCompleteSelect() {
  const el = document.getElementsByClassName(
    "ant-select-selection-search-input"
  );
  for (let i = 0; i < el.length; i++) {
    el[i].setAttribute("autocomplete", "registration-select");
  }
}
export function calculateDate(checkin, checkout) {
  let dateCheckin = new Date(checkin);
  let dateCheckout = new Date(checkout);
  // time diff
  let timeDiff = Math.abs(dateCheckout.getTime() - dateCheckin.getTime());
  let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
}
// currency format
export function currencyFormat(num, fixed = 0) {
  return parseFloat(num)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}
// url slug
export function url_slug(title) {
  let slug;
  //Đổi chữ hoa thành chữ thường
  slug = title.toLowerCase();
  //Đổi ký tự có dấu thành không dấu
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
  slug = slug.replace(/đ/gi, "d");
  //Xóa các ký tự đặt biệt
  slug = slug.replace(
    /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
    ""
  );
  //Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug.replace(/ /gi, "-");
  //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  slug = slug.replace(/\-\-\-\-\-/gi, "-");
  slug = slug.replace(/\-\-\-\-/gi, "-");
  slug = slug.replace(/\-\-\-/gi, "-");
  slug = slug.replace(/\-\-/gi, "-");
  //Xóa các ký tự gạch ngang ở đầu và cuối
  slug = "@" + slug + "@";
  slug = slug.replace(/\@\-|\-\@|\@/gi, "");
  return slug;
}
export function getCurrency(currency) {
  let arrCurrency = {
    USD: "$",
    EUR: "€",
    CHF: "Fr",
    CAD: "C$",
    KRW: "₩",
    JPY: "¥",
    VND: "₫",
  };

  return arrCurrency[`${currency}`] || "$";
}
export function getImageVariant(itemOrder) {
  if (itemOrder) {
    let filename = "";
    if (
      itemOrder.variant &&
      itemOrder.variant.images &&
      itemOrder.variant.images.length > 0
    ) {
      filename = itemOrder.variant.images[0].filename;
    } else {
      if (
        itemOrder.product &&
        itemOrder.product.images &&
        itemOrder.product.images.length > 0
      ) {
        filename = itemOrder.product.images[0].filename;
      } else {
        return `static/images/no-image.jpg`;
      }
    }
    return `${CONSTANT.STATIC_URL}/${filename}`;
  } else return `static/images/no-image.jpg`;
}

/* generate name doctor */
export function createDoctorName(
  academic_rank = "",
  degree = "",
  category = "",
  fullname = ""
) {
  let doctorName = "";
  if (academic_rank) {
    doctorName = `${academic_rank}.`;
  }
  if (degree) {
    doctorName = `${doctorName}${degree}.`;
  }
  if (category) {
    doctorName = `${doctorName}${category}`;
  }
  if (fullname) doctorName = `${doctorName} ${fullname}`;
  return doctorName;
}
/* hàm tính thời gian khi cộng thêm phút vào thời gian nhập vào */
export function calculateTime(time, minutePlus) {
  let durationTime = moment.duration(time);
  let durationMinutePlus = moment.duration(minutePlus, 'minute');
  let result = moment.utc(durationTime.asMilliseconds() + durationMinutePlus.asMilliseconds()).format("HH:mm");
  return result;
}

/* get day of week */
export function getDayOfWeek(dayOfWeek) {
  if (dayOfWeek) {
    switch (dayOfWeek) {
      case "0":
        return "Chủ nhật";
      case "1":
        return "Thứ 2";
      case "2":
        return "Thứ 3";
      case "3":
        return "Thứ 4";
      case "4":
        return "Thứ 5";
      case "5":
        return "Thứ 6";
      case "6":
        return "Thứ 7";
      default:
        break;
    }
  }
}
