import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Row, Col, Input, Select, message, InputNumber } from 'antd';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
const ModalActionUnit = (props) => {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [specialtyName, setSpecialtyName] = useState('');
    const handleCancel = useCallback(() => {
        form.resetFields();
        props.cancel(false);
    }, [form]);
    useEffect(() => {   
        setSpecialtyName(props.data?.specialty_name);
        form.setFieldsValue({
            name: props.data?.name,
            summary: props.data?.summary,
            sort: props.data?.sort
        })
    }, [props.data]);
    // Xử lý lưu
    const onSubmit = useCallback(async (values) => {
        debugger;
        setConfirmLoading(true);
        if (props.data) {
            let dataSave = {
                action: 'updUnit',
                unitId: props.data._id,
                specialty: props.data?.specialty,
                specialty_name: specialtyName,
                unit_summary: values.summary,
                unit_name: values.name,
                unit_sort: values.sort,
            }
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospitalId}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Sửa thông tin thành công. Nội dung của bạn sẽ được kiểm duyệt trước khi được công bố đến khách truy cập !')
                        form.resetFields();
                        props.cancel(true);
                    }
                    else {
                        message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                    }
                    setConfirmLoading(false);
                });
        }
        else {
            let dataSave = {
                action: 'insUnit',
                specialty: values.specialty,
                specialty_name: specialtyName,
                unit_summary: values.summary,
                unit_name: values.name,
                unit_sort: values.sort,
            }
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospitalId}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.data && res.data.code === 200) {
                        message.success('Thêm đơn vị thành công. Nội dung của bạn sẽ được kiểm duyệt trước khi được công bố đến khách truy cập !')
                        form.resetFields();
                        props.cancel(true);
                    }
                    else {
                        message.error('Thêm đơn vị thất bại! Vui lòng thử lại sau.');
                    }
                    setConfirmLoading(false);
                });

        }
        setConfirmLoading(false);
    }, [props,specialtyName]);
    const selected = async (value) => {
        setSpecialtyName(value.children);
    }    
    return (
        <Modal title={props.data ? 'Sửa thông tin đơn vị' : 'Thêm mới đơn vị'}
            visible={props.visible}
            onOk={form.submit}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Lưu thông tin"
            confirmLoading={confirmLoading}
            className="modal-book-facility"
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            maskClosable={false}
        >
            <Form
                name="basic"
                layout="vertical"
                onFinish={onSubmit}
                onFinishFailed={() => { }}
                form={form}
            >
                <Row gutter={[24]} align="middle">
                    <Col xs={24} lg={24}>                       
                        <Form.Item
                            label="Tên đơn vị" name="name"
                            required tooltip="Vui lòng nhập trường này"
                            rules={[
                                {
                                    required: true, message: 'Vui lòng nhập tên đơn vị',

                                }, {
                                    type: 'string', max: 100, message: "Tên đơn vị tối đa 100 ký tự !"
                                }
                            ]}>
                            <Input placeholder="Nhập tên đơn vị" />
                        </Form.Item>
                        {
                            !props.data &&
                            <Form.Item label="Chọn chuyên khoa" name="specialty" required tooltip="Vui lòng chọn trường này">
                                <Select placeholder="Chọn chuyên khoa" className="select-location" size='large' onChange={(value, label) => selected(label)}>
                                    {
                                        props.specialties && props.specialties.length > 0 &&
                                        props.specialties.map(item => <Select.Option key={item._id}>{item.name_other}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        }
                        <Form.Item label="Mô tả" tooltip="Mô tả ngắn về đơn vị" name="summary"
                            rules={[
                                {
                                    type: 'string', max: 1000, message: "Mô tả tối đa 1000 ký tự !"
                                }
                            ]}>
                            <Input.TextArea showCount maxLength={1000} style={{ width: '100%' }} rows={8} />
                        </Form.Item>
                        <Form.Item
                            label="Thứ tự sắp xếp"
                            name='sort'
                            rules={[
                                { required: true, message: 'Vui lòng nhập thứ tự sắp xếp' },
                                {
                                    type: 'number', max: 10000, message: "Thứ tự sắp xếp tối đa 10000 !"
                                }
                            ]}>
                            <InputNumber placeholder="Nhập thứ tự sắp xếp" min={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\\s?|(,*)/g, '')}
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}
export default ModalActionUnit;