import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Result } from 'antd';
import { HEREMap, Marker } from 'here-maps-react-v2';

const ModalShowMap = (props) => {
    const [center, setCenter] = useState();
    const [positionReturn, setPositionReturn] = useState();
    useEffect(() => {
        setCenter({ lat: props.data?.coordinates[0], lng: props.data?.coordinates[1] });
        setPositionReturn({});
    }, [props.data]);
    const handleCancel = useCallback(() => {
        debugger;
        props.cancel(positionReturn);
    }, [positionReturn]);
    // Can render any map element, make sure to pass map and platform as props to the children to avoid unwarranted behavior
    return (

        <Modal
            title="Bạn có thể kéo thả để xác định vị trí phù hợp"
            visible={props.visible}
            footer={false}
            closable={true}
            onCancel={handleCancel}
            destroyOnClose={true}
            centered
            width={'70%'}
        >{
                props.data?.coordinates ? (
                    <div style={{ height: '500px' }}>
                        <HEREMap
                            appId="erWElBPTdcSVJhWkLQJJ"
                            appCode="iYhTbrpb25W671WE_MK7Cw"
                            apikey="VqOELDbMHoG-Sf9ygZqSHKnrBGcH1m4PcV0xbjCIHqM"
                            center={center}
                            zoom={16}
                            interactive={true}
                        >
                            <Marker
                                lat={center.lat}
                                lng={center.lng}
                                draggable
                                onDragEnd={async e => {                  
                                    if(e && e.currentPointer && e.currentPointer.dragTarget){
                                        setCenter({ lat: e.currentPointer.dragTarget.b.lat, lng: e.currentPointer.dragTarget.b.lng})
                                    }
                                    
                                }}
                            >
                            </Marker>
                        </HEREMap>
                    </div>) : <Result title="Bạn chưa chọn địa chỉ để xác định vị trí bản đồ, hoặc địa chỉ bạn chọn không tồn tại trên bản đồ. Vui lòng kiểm tra và quay lại sau cảm ơn !" />
            }
        </Modal>
    )
}
export default ModalShowMap;
