import React from 'react';
import { Layout, Avatar, Dropdown, Menu } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LogoutOutlined, IdcardOutlined,RetweetOutlined
} from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { clearAuthTokens } from 'axios-jwt';

export default class HeaderTop extends React.PureComponent {

    handleClick = e => {
        switch (e.key) {
            case 'logout':
                clearAuthTokens();
                localStorage.removeItem(CONSTANT.STORAGE_KEY_USER);
                this.props.history.push('/login')
                break;
            case 'profile':
                this.props.history.push('/profile')
                break;
            case 'changepass':
                this.props.history.push('/profile/change-pass')
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <Layout.Header className="site-layout-background" style={{ padding: 0 }}>

                {/* Begin button toogle menu sider */}
                {React.createElement(this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: this.props.toggle,
                })}
                {/* End button toogle menu sider */}

                <div className="header-menu-right">
                    <Dropdown arrow={true} overlayClassName="user-drop" overlay={(
                        <Menu onClick={this.handleClick}>
                            <Menu.Item key="profile" icon={<IdcardOutlined />}>Thông tin tài khoản</Menu.Item>
                            <Menu.Item key="changepass" icon={<RetweetOutlined />}>Thay đổi mật khẩu</Menu.Item>
                            <Menu.Item key="logout" icon={<LogoutOutlined />}>Thoát</Menu.Item>
                        </Menu>
                    )}>
                        <Avatar style={{ color: '#fff', backgroundColor: '#007d99' }}>T</Avatar>
                    </Dropdown>
                </div>
            </Layout.Header>
        )
    }
}