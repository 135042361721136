import React, { useState, useEffect } from 'react';
import {
	Layout, message, Button, List, Row, Col, Avatar,
	Alert, Dropdown, Menu, Form, Select, Input, Modal, Empty, DatePicker
} from 'antd';
import {
	ClockCircleOutlined, UserOutlined, MobileOutlined, BranchesOutlined, DownOutlined, CalendarOutlined,
	CheckCircleOutlined, SearchOutlined, RedoOutlined, SwapOutlined, StopOutlined
}
	from '@ant-design/icons';
import moment from 'moment';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';
import { axiosInstance } from '../../../config/api';
const arrStatus = {
	'wait-confirm': { text: "Chờ duyệt", color: "orange", icon: <ClockCircleOutlined /> },
	'confirmed': { text: 'Chấp nhận', color: '#2db7f5', icon: <SwapOutlined /> },
	'completed': { text: 'Đã khám', color: '#87d068', icon: <CheckCircleOutlined /> },
	'not-come': { text: 'Không đến', color: 'grey' },
	'cancelled': { text: 'Từ chối', color: '#f50', icon: <StopOutlined /> }
};
const getRandomColor = () => {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

const ListExaminationPackage = (props) => {
	const [form] = Form.useForm();
	const [listService, setListService] = useState([]);
	const [loading, setLoading] = useState(false);
	const [reasonCancel, setResonCancel] = useState('Có việc đột xuất');
	const [selectedId, setSelectedId] = useState();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [saving, setSaving] = useState(false);
	const [optionFilter,setOptionFilter] = useState({});
	const [pagination, setPagination] = useState({
		pageSize: 10,
		total: 0,
		perPage: 10,
		hideOnSinglePage: true,
		showLessItems: true
	});

	// effect
	useEffect(() => {
		loadOrder({ page: 1, perPage: 10 });
	}, []);

	const loadOrder = async (queryParam) => {
		setLoading(true);
		let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
		let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
		if (user.is_hospital) {
			await axiosInstance.get(`${CONSTANT.API.HOSPITAL.ORDER}?${query}&agency=${user._id}&schedule=true`)
				.then(async res => {
					if (res && res.data && res.data.code === 200) {
						// Set giá trị cho pagination
						let _pagination = { ...pagination };
						_pagination.total = res.data.data.total;
						setPagination(_pagination);

						let docs = res.data.data.docs;
						// Thực hiện nhóm mảng theo ngày
						let results = [];
						let currDate = null;
						let i = 0;
						for (let item of docs) {
							currDate = { day_of_week: item.date.day_of_week, day: item.date.day, month: item.date.month, year: item.date.year };
							if (results.length === 0) results.push({ date: currDate, orders: [item] })
							else if (results[i].date.day === item.date.day && results[i].date.month === item.date.month && results[i].date.year === item.date.year) {
								results[i].orders.push(item);
							}
							else {
								results.push({ date: currDate, orders: [item] }); i++;
							}
						}
						setListService(results);
					}


				})
				.catch(error => message.warning('Không thể truy cập danh sách lịch hẹn!' + error.message, 5).then(() => props.history.push('/')))
		}
		else {
			message.warning('Bạn chưa đăng ký làm đối tác chuyên môn Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
		}
		setLoading(false);
	}

	// Xử lý sự kiện thay đổi status
	const onChangeStatus = (id) => async (value) => {
		if (value?.key && id) {
			setSelectedId(id);
			if (value.key === 'cancelled') { // Nếu từ chối lịch hẹn
				setIsModalVisible(true);
			}
			else {
				const dataUpdate = {
					actUpd: "udpStatus",
					status: value.key
				}
				setSaving(true);
				await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.ORDER}/${id}`, dataUpdate)
					.then(res => {
						if (res?.data?.code === 200) {
							let mess = 'Cập nhật trạng thái lịch hẹn thành công.';
							if (value.key === 'completed' || value.key === 'not-come')
								mess = `${mess} Lịch hẹn này sẽ được đưa vào danh sách Nhật ký khám chữa bệnh`;
							message.success(mess, 4)
								.then(() => {
									setSaving(false);
									loadOrder({ page: 1, perPage: 10 });
								})
						}
						else {
							setSaving(false);
							message.warning('Cập nhật trạng thái lịch hẹn không thành công. Vui lòng thử lại sau');
						}
					})
					.catch(error => {
						setSaving(false);
						message.warning('Cập nhật trạng thái lịch hẹn không thành công. Vui lòng thử lại sau');
					})
			}

		}

	}
	// Xử lý sự kiện từ chối lịch hẹn
	const okCancel = async () => {

		if (selectedId) {
			const dataUpdate = {
				actUpd: "udpStatus",
				status: 'cancelled',
				reason_cancel: reasonCancel
			}
			setSaving(true);
			await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.ORDER}/${selectedId}`, dataUpdate)
				.then(res => {
					if (res?.data?.code === 200) {
						message.success('Từ chối lịch hẹn thành công. Lịch hẹn này sẽ được đưa vào danh sách Nhật ký khám chữa bệnh', 2.5)
							.then(() => {
								resetModal();
								loadOrder({ page: 1, perPage: 10 });
							});
					}
					else {
						message.warning('Từ chối lịch hẹn thất bại. Vui lòng thử lại sau').then(() => setSaving(false))
					}

				})
				.catch(error => message.warning('Từ chối lịch hẹn thất bại. Vui lòng thử lại sau').then(() => setSaving(false)))
		}
	}
	const resetModal = () => {
		setIsModalVisible(false);
		setSelectedId('');
		setResonCancel('Có việc đột xuất')
		setSaving(false);
	}
	const handleCancel = () => {
		resetModal();
	};
	const onBlurReason = ({ target: { value } }) => {
		setResonCancel(value)
	}
	
	// Xử lý sự kiện search
	const onSearch = async (values) => {
		let options = {};
		if (values.q !== '' && values.q !== undefined) {
			if (values.field === 'name') {
				options.fullname_patient = values.q;
			}

			if (values.field === 'uid') {
				options.uid = values.q.replace('VIVMEDIC-H', '');
			}
		}


		if (values.date_range) {
			options.date_range = JSON.stringify({
				start: moment(values.date_range[0]).format("YYYY-MM-DD"),
				end: moment(values.date_range[1]).format("YYYY-MM-DD")
			});
		}
		loadOrder({
			page: 1,
			perPage: pagination.perPage,
			...options
		});
		setOptionFilter(options);
	}
	const reload = () => {
		loadOrder({ page: 1, perPage: pagination.perPage });
		form.resetFields();
	}
	const onPaginationChange = async (page) => {
		loadOrder({ page, perPage: pagination.perPage, ...optionFilter});
	}
	return (
        <Layout.Content className="layout-content" style={{ padding: '8px', background: 'none' }}>

            {/* Begin form search */}
            <Form
                layout="inline"
                onFinish={onSearch}
                style={{ marginBottom: 20 }}
                initialValues={{
                    field: 'name'
                }}
                style={{ margin: '0px -8px 20px -8px' }}
                form={form}
            >
                <Form.Item name="q"
                    extra="(VD: Nguyễn Văn A hoặc VIVMEDIC-H123)"
                >
                    <Input
                        type="text"
                        placeholder="Nhập Tên người khám hoặc Mã lịch hẹn "
                        style={{ width: 300 }}
                    />
                </Form.Item>
                <Form.Item name="field">
                    <Select
                        style={{ width: 150 }}
                    >
                        <Select.Option value="name">Tên người khám</Select.Option>
                        <Select.Option value="uid">Mã lịch hẹn</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="date_range">
                    <DatePicker.RangePicker format="DD-MM-YYYY" disabledDate={(current) => current && current < moment().endOf('day')} />
                </Form.Item>
                <Form.Item>
                    <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                        Tìm
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button icon={<RedoOutlined />} onClick={reload}>
                        Tải lại trang
                    </Button>
                </Form.Item>
            </Form>
            {/* End form search */}

            <List
                loading={loading}
                dataSource={listService}
                renderItem={list => (
                    <>
                        <div style={{ fontWeight: 700, margin: '20px 0px 10px 0px' }}>{COMMON.getDayOfWeek(list?.date?.day_of_week.toString())} - {list?.date?.day}/{list?.date?.month}/{list?.date?.year}</div>
                        {
                            list?.orders?.length > 0 && list.orders.map(item => (
                                <Row className="item-doctor-order" gutter={16} key={item._id}>
                                    <Col xs={24} md={8}>
                                        <Row align="middle">
                                            <Col md={3} className="text-center">
                                                <Avatar style={{ color: '#fff', backgroundColor: `${getRandomColor()}` }}>{item?.contact?.fullname_patients[0] || 'U'}</Avatar>
                                                <div className="code">{item.uid}</div>
                                            </Col>
                                            <Col md={20} className="col-patient">
                                                <ClockCircleOutlined className="icon" />
                                                <span className="time">{moment(`${item.time.time_h}:${item.time.time_m}`, "HH:mm").format("HH:mm")}</span>
                                                <UserOutlined className="icon" />
                                                <span className="name">{item?.contact?.fullname_patients}</span><br />
                                                <span className="other">{item?.contact?.gender === 'male' ? 'Nam' : item?.contact?.gender === 'female' ? 'Nữ' : '-'} - {item?.contact?.dob}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} md={16} className="col-detail">
                                        <div>
                                            <MobileOutlined className="icon" />
                                            <span className="phone">{item?.contact?.phone}</span>
                                            {
                                                item?.contact?.fullname_orderer &&
                                                <>
                                                    <UserOutlined className="icon" />
                                                    <span className="name">{item?.contact?.fullname_orderer}</span>
                                                </>
                                            }
                                            <BranchesOutlined className="icon" />
                                            <span className="span cate">{item?.items?.length > 0 && item?.items[0].service?.name}</span>

                                        </div>
                                        <div className="note">
                                            {item.note}
                                        </div>
                                        <div className="group-btn">
                                            <Button icon={<CalendarOutlined />} size="small">
                                                {COMMON.getDayOfWeek(item?.date?.day_of_week.toString())} {item?.date?.day}/{item?.date?.month}/{item?.date?.year} - {moment(`${item.time.time_h}:${item.time.time_m}`, "HH:mm").format("HH:mm")}
                                            </Button>
                                            <Dropdown
                                                overlay={(
                                                    <Menu
                                                        onClick={onChangeStatus(item._id)}
                                                    >
                                                        {
                                                            Object.entries(arrStatus).map(iStatus => (
                                                                <React.Fragment key={iStatus[0]}>
                                                                    {
                                                                        iStatus[0] !== 'wait-confirm' &&
                                                                        <Menu.Item disabled={iStatus[0] === item.status} key={iStatus[0]}>{iStatus[1]?.text}</Menu.Item>
                                                                    }
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </Menu>
                                                )}
                                                trigger={['click']}
                                            >
                                                <Button size="small" loading={saving && item._id === selectedId} className="btn-status" style={{ backgroundColor: `${arrStatus[item?.status]?.color}`, borderColor: `${arrStatus[item?.status]?.color}` }} icon={arrStatus[item?.status]?.icon}>
                                                    {arrStatus[item?.status]?.text} <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            ))
                        }

                    </>

                )}
                pagination={{
					...pagination,
					onChange: onPaginationChange
				}}
                locale={{ emptyText: <Empty description="Chưa có lịch hẹn" /> }}
            />
            <Modal title="Từ chối lịch hẹn"
                visible={isModalVisible}
                okText="Từ chối"
                cancelText="Đóng"
                onOk={okCancel}
                onCancel={handleCancel}
                confirmLoading={saving}
                destroyOnClose={true}
            >
                <Alert style={{ fontSize: 13, marginBottom: 16 }} message="Bạn muốn từ chối Lịch hẹn này. Bạn vui lòng nhập lý do từ chối lịch hẹn này. Xin cảm ơn !" type="warning" />
                <Input defaultValue={reasonCancel} onBlur={onBlurReason} />
            </Modal>

        </Layout.Content>
	)

}

export default ListExaminationPackage;
