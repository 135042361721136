import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, message, Spin, Popconfirm, Divider, Space, Button
} from 'antd';
import { HomeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, CalendarOutlined} from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import ModalActionService from './components/action-service';
const HospitalService = (props) => {
    const columns = [
        {
            title: 'Danh mục gói khám',
            dataIndex: 'name',
        },
        {
            title: 'Giá tiền', width: '150px',
            dataIndex: 'price',
            render: record => record.toLocaleString('en-US')
        },
        {
            title: (<div style={{textAlign:'center'}}>Hành động</div>), width: '100px',
            render: (record) => (
                <Space size="middle">
                    <Button type="link" icon={<CalendarOutlined />}
                        onClick={() => props.history.push(`/hospital/services/${record._id}/setting`)} >
                        Cài đặt lịch
                    </Button>
                    <Divider type="vertical" />
                    <Button type="link" onClick={() => openActionService(
                        {
                            serviceId: record._id,
                            serviceName: record.name,
                            summary: record.summary,
                            price: record.price,
                            price_note: record.price_note,
                            is_government_insurance: record.is_government_insurance,
                            note_government_insurance: record.note_government_insurance,
                            is_direct_guarantee_insurance: record.is_direct_guarantee_insurance,
                            note_direct_guarantee_insurance: record.note_direct_guarantee_insurance,
                            list_direct_guarantee_insurance: record.list_direct_guarantee_insurance,
                            price_list: record.price_list,
                        }
                    )}><EditOutlined /> Sửa</Button>
                    <Divider type="vertical" />
                    <Popconfirm title="Bạn muốn xóa  này?" onConfirm={() => { delService(record._id) }}>
                        <Button type="link" danger><DeleteOutlined /> Xóa</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    const [hospital, setHospital] = useState({});
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [serviceData, setServiceData] = useState(null);

    const openActionService = async (data) => {
        await setServiceData(data);
        setIsModalVisible(true);
    }   

    // effect
    useEffect(() => {
        // load thông tin hospital
        const loadHospital = async () => {
            setLoading(true);
            let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
            if (user.is_hospital) {
                await axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?created_by=${user._id}`)
                .then(res => {
                    if(res?.data?.code === 200){
                        const {docs} = res?.data?.data;
                        setHospital(docs[0]);
                    }
                    setLoading(false);
                })
                .catch(error => message.warning('Lỗi lấy thông tin cơ sở y tế', 5).then(() => props.history.push('/')))
            }
        }
        let mounted = true;
        if(mounted){
            loadHospital();
        }

        return () => mounted = false;
    }, []);

    
    const delService = async (serviceId) => {
        setLoading(true);
        let dataSave = {
            action: 'delService',
            serviceId: serviceId
        }
        await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${hospital._id}`, dataSave)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Xóa thành công !')
                    setHospital(res.data.data);
                }
                else {
                    message.error('Xóa thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            });
    }    
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cơ sở y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Quản lý gói khám
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                <Button type="primary" icon={<PlusOutlined />} style={{ float: 'right', marginBottom: 20 }}
                    onClick={() => openActionService(null)} >
                    Thêm danh mục gói khám
                </Button>               
                {
                    hospital && <Table dataSource={hospital.services}  
                        columns={columns} loading={loading}
                        pagination={false}
                        rowKey={record => record._id} />
                }

            </Layout.Content>
            <ModalActionService visible={isModalVisible} data={serviceData} hospitalId={hospital && hospital._id}
                cancel={(data) => {
                    setIsModalVisible(false);
                    if (data) {
                        setHospital(data);
                    }
                }} />            
        </div >
    )

}

export default HospitalService;