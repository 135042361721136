import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, message, Button,Popover, List, Row, Col, Avatar,
    Form, Select, Input, DatePicker, Empty
} from 'antd';
import {
    ClockCircleOutlined, UserOutlined, MobileOutlined, UserAddOutlined,
    HomeOutlined, VideoCameraOutlined, CalendarOutlined,
    CheckCircleOutlined, SearchOutlined, RedoOutlined, SwapOutlined, StopOutlined, BranchesOutlined
}
    from '@ant-design/icons';
import moment from 'moment';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';
import { axiosInstance } from '../../../config/api';
const arrStatus = {
    'wait-confirm': { text: "Chờ duyệt", color: "orange", icon: <ClockCircleOutlined /> },
    'confirmed': { text: 'Chấp nhận', color: '#2db7f5', icon: <SwapOutlined /> },
    //'patient-begin': { text: 'Bệnh nhân đã xác nhận bắt đầu', color: 'orange' },
    //'doctor-begin': { text: 'Bác sĩ đã xác nhận bắt đầu', color: 'orange' },
    //'patient-completed': { text: 'Bệnh nhân đã xác nhận hoàn thành', color: 'green' },
    'completed': { text: 'Đã khám', color: '#87d068', icon: <CheckCircleOutlined /> },
    'not-come': { text: 'Không đến', color: 'grey' },
    'abandoned': { text: 'Khách đã hủy', color: 'red', icon: <StopOutlined /> },
    'cancelled': { text: 'Từ chối', color: '#f50', icon: <StopOutlined /> }
};
const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
const ListOrderServiceDoctorDiary = (props) => {
    const [form] = Form.useForm();
    const [listService, setListService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0,
        perPage: 10,
        hideOnSinglePage: true,
        showLessItems: true
    });
    // effect
    useEffect(() => {
        loadOrder({ page: 1, perPage: 10 });
    }, []);

    const loadOrder = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_doctor) {
            await axiosInstance.get(`${CONSTANT.API.DOCTOR.ORDER}?${query}&diary=true&agency=${user._id}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        // Set giá trị cho pagination
                        let _pagination = { ...pagination };
                        _pagination.total = res.data.data.total;
                        setPagination(_pagination);

                        let docs = res.data.data.docs;//.sort((a, b) => (a.date.year < b.date.year) ? 1 : (a.date.year === b.date.year) ? ((a.date.month < b.date.month) ? 1 : (a.date.month === b.date.month) ? ((a.date.day < b.date.day) ? 1 : -1) : -1) : -1);
                        let results = [];
                        let currDate = null;
                        let i = 0;
                        for (let item of docs) {
                            currDate = { day_of_week: item.date.day_of_week, day: item.date.day, month: item.date.month, year: item.date.year };
                            if (results.length === 0) results.push({ date: currDate, orders: [item] })
                            else if (results[i].date.day === item.date.day && results[i].date.month === item.date.month && results[i].date.year === item.date.year) {
                                results[i].orders.push(item);
                            }
                            else {
                                results.push({ date: currDate, orders: [item] }); i++;
                            }
                        }
                        setListService(results);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setListService([]);
                    message.warning('Lỗi dữ liệu nhập không đúng!', 3);
                });
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác chuyên môn của Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
        }
        setLoading(false);
    }
    // Xử lý sự kiện search
    const onSearch = async (values) => {
        let options = {};
        if (values.q !== '' && values.q !== undefined) {
            if (values.field === 'name') {
                options.fullname_patient = values.q;
            }

            if (values.field === 'uid') {
                options.uid = values.q.replace('VIVMEDIC-D', '');
            }
        }


        if (values.date_range) {
            options.date_range = JSON.stringify({
                start: moment(values.date_range[0]).format("YYYY-MM-DD"),
                end: moment(values.date_range[1]).format("YYYY-MM-DD")
            });
        }
        loadOrder({
            page: 1,
            perPage: pagination.perPage,
            ...options
        });
    }
    const reload = () => {
        loadOrder({ page: 1, perPage: pagination.perPage });
        form.resetFields();
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Dành cho Bác sĩ
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Nhật ký khám chữa bệnh
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content" style={{ padding: '8px', background: 'none' }}>
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    initialValues={{
                        field: 'name'
                    }}
                    style={{ margin: '0px -8px 20px -8px' }}
                    form={form}
                >
                    <Form.Item name="q"
                        /*required rules={[
                            { required: true, message: 'Vui lòng nhập Tên người khám hoặc Mã lịch hẹn' }
                        ]}*/
                        extra="(VD: Nguyễn Văn A hoặc VIVMEDIC-D123)"
                    >
                        <Input
                            type="text"
                            placeholder="Nhập Tên người khám hoặc Mã lịch hẹn "
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select
                            style={{ width: 150 }}
                        >
                            <Select.Option value="name">Tên người khám</Select.Option>
                            <Select.Option value="uid">Mã lịch hẹn</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range">
                        <DatePicker.RangePicker format="DD-MM-YYYY" disabledDate={(current) => current && current < moment().endOf('day')} />
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Tải lại trang
                        </Button>
                    </Form.Item>
                </Form>
                {/* End form search */}

                <List
                    loading={loading}
                    dataSource={listService}
                    renderItem={list => (
                        //cái list nó là 1 row à for cai ham group dau
                        <>
                            <div style={{ fontWeight: 700, margin: '20px 0px 10px 0px' }}>{COMMON.getDayOfWeek(list?.date?.day_of_week.toString())} - {list?.date?.day}/{list?.date?.month}/{list?.date?.year}</div>
                            {
                                list.orders.length > 0 && list.orders.map(item => (
                                    <Row key={item._id} className="item-doctor-order" gutter={16}>

                                        <Col xs={24} md={8}>
                                            <Row align="middle">
                                                <Col md={3} className="text-center">
                                                    <Avatar style={{ color: '#fff', backgroundColor: `${getRandomColor()}` }}>{item?.contact?.fullname_patients[0] || 'U'}</Avatar>
                                                    <div className="code">{item.uid}</div>
                                                </Col>
                                                <Col md={20} className="col-patient">
                                                    <ClockCircleOutlined className="icon" />
                                                    <span className="time">{moment(`${item.time.time_h}:${item.time.time_m}`, "HH:mm").format("HH:mm")}</span>
                                                    <UserOutlined className="icon" />
                                                    <span className="name">{item?.contact?.fullname_patients}</span><br />
                                                    <span className="other">{item?.contact?.gender === 'male' ? 'Nam' : 'Nữ'} - {item?.contact?.dob}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={16} className="col-detail">
                                            <div>
                                                <MobileOutlined className="icon" />
                                                <span className="phone">{item?.contact?.phone}</span>
                                                {
                                                    item?.contact?.fullname_orderer &&
                                                    <>
                                                        <UserOutlined className="icon" />
                                                        <span className="name">{item?.contact?.fullname_orderer}</span>
                                                    </>
                                                }
                                                {
                                                    item?.items?.length > 0 &&
                                                    <>
                                                        <UserAddOutlined className="icon" />
                                                        <span className="name">{COMMON.createDoctorName(item?.items[0]?.doctor?.academic_rank, item?.items[0]?.doctor?.degree, item?.items[0]?.doctor?.fullname)}</span>
                                                    </>
                                                }

                                                <BranchesOutlined className="icon" />
                                                <span className="span cate">{item?.items?.length > 0 && item?.items[0].service?.name}</span>
                                            </div>
                                            <div className="note">
                                                {item.note}
                                            </div>
                                            <div className="group-btn">
                                                <Button icon={<VideoCameraOutlined />} className="btn-call" type="primary" size="small">Khám từ xa</Button>
                                                <Button icon={<CalendarOutlined />} size="small">
													{COMMON.getDayOfWeek(item?.date?.day_of_week.toString())} {item?.date?.day}/{item?.date?.month}/{item?.date?.year} - {moment(`${item.time.time_h}:${item.time.time_m}`, "HH:mm").format("HH:mm")}
												</Button>
												{
													item?.date_old && <Popover
														content={(
															<Button icon={<CalendarOutlined />} size="small">
																{COMMON.getDayOfWeek(item?.date_old?.day_of_week.toString())} {item?.date_old?.day}/{item?.date_old?.month}/{item?.date_old?.year} - {moment(`${item.time_old.time_h}:${item.time_old.time_m}`, "HH:mm").format("HH:mm")}
															</Button>
														)}
														placement="bottomLeft"
														title="Lịch cũ trước khi thay đổi"
														trigger="click"

													>
														<Button icon={<CalendarOutlined />} size="small">Lịch cũ</Button>
													</Popover>
												}
                                                <Button size="small" className="btn-status" style={{ backgroundColor: `${arrStatus[item?.status]?.color}`, borderColor: `${arrStatus[item?.status]?.color}` }} icon={arrStatus[item?.status]?.icon}>
                                                {arrStatus[item?.status]?.text}
                                            </Button>
                                            </div>                                           
                                        </Col>
                                    </Row>
                                ))
                            }
                        </>

                    )}
                    pagination={pagination}
                    locale={{ emptyText: <Empty description="Không có nhật ký lịch hẹn nào !" /> }}
                />
            </Layout.Content>
        </div >
    )

}

export default ListOrderServiceDoctorDiary;
