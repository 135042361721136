import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { isLoggedIn } from 'axios-jwt';

import {
    Dashboard,
    //hospital
    InfoHospital, Specialties, Units, Doctors, ListOrderHospital, HospitalService, HospitalServiceDetailSetting, ServiceDetailSettingHospital, ListOrderHospitalDiary,ListOrderHospitalStatistic,
    // Doctor
    InfoDoctor, InfoService, CalendarSetting, ListOrderServiceDoctor, ServiceDetailSetting, ListOrderServiceDoctorDiary,StatisticOrderDoctor,
    //Shop
    InfoShop, ShopListProduct, ShopActionProduct, ShopListOrder, ShopDetailOrder,StatisticOrderShop,
    //service
    ListService, ActionService, InfoAgency, ListOrderService, ListOrderDiaryService,StatisticOrderService,ServiceListVoucher,
    //profile
    Infomation, User, ChangePass,
    // 404
    PageNotFound
} from '../modules';

// import layout
import Login from '../layouts/login';
import MainLayout from '../layouts/main';


const PrivateRoute = ({ component: Component, layout: Layout = MainLayout, authed, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => authed === true ? (
                <Layout>
                    <Component {...props} />
                </Layout>
            ) : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
        />
    )
}

export default function AppRouter() {
    return (
        <Router>
            <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute authed={isLoggedIn()} path="/" exact component={Dashboard} />
                {/* Hospital */}
                <PrivateRoute authed={isLoggedIn()} path="/hospital" exact component={InfoHospital} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/specialties" exact component={Specialties} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/units" exact component={Units} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/doctors" exact component={Doctors} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/services" exact component={HospitalService} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/services/:serviceId/setting" exact component={HospitalServiceDetailSetting} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/bookings" exact component={ListOrderHospital} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/diary/bookings" exact component={ListOrderHospitalDiary} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/:hospitalId/doctor/:doctorId/service/:serviceId/setting" exact component={ServiceDetailSettingHospital} />
                <PrivateRoute authed={isLoggedIn()} path="/hospital/statistic" exact component={ListOrderHospitalStatistic} />
                {/* Doctor */}
                <PrivateRoute authed={isLoggedIn()} path="/doctor" exact component={InfoDoctor} />
                <PrivateRoute authed={isLoggedIn()} path="/doctor/services" exact component={InfoService} />
                <PrivateRoute authed={isLoggedIn()} path="/doctor/services/setting" exact component={CalendarSetting} />
                <PrivateRoute authed={isLoggedIn()} path="/doctor/service/:serviceId/setting" exact component={ServiceDetailSetting} />
                <PrivateRoute authed={isLoggedIn()} path="/doctor/bookings" exact component={ListOrderServiceDoctor} />
                <PrivateRoute authed={isLoggedIn()} path="/doctor/diary/bookings" exact component={ListOrderServiceDoctorDiary} />
                <PrivateRoute authed={isLoggedIn()} path="/doctor/statistic" exact component={StatisticOrderDoctor} />
                {/* Service */}
                <PrivateRoute authed={isLoggedIn()} path="/service/agency" exact component={InfoAgency} />
                <PrivateRoute authed={isLoggedIn()} path="/services" exact component={ListService} />
                <PrivateRoute authed={isLoggedIn()} path="/service/vouchers" exact component={ServiceListVoucher} />
                <PrivateRoute authed={isLoggedIn()} path="/service/add" exact component={ActionService} />
                <PrivateRoute authed={isLoggedIn()} path="/service/edit/:id" exact component={ActionService} />
                <PrivateRoute authed={isLoggedIn()} path="/service/bookings" exact component={ListOrderService} />
                <PrivateRoute authed={isLoggedIn()} path="/service/diary/bookings" exact component={ListOrderDiaryService} />
                <PrivateRoute authed={isLoggedIn()} path="/service/statistic" exact component={StatisticOrderService} />
                {/* Shop */}
                <PrivateRoute authed={isLoggedIn()} path="/seller" exact component={InfoShop} />
                <PrivateRoute authed={isLoggedIn()} path="/seller/product" exact component={ShopListProduct} />
                <PrivateRoute authed={isLoggedIn()} path="/seller/product/action" exact component={ShopActionProduct} />
                <PrivateRoute authed={isLoggedIn()} path="/seller/product/action/:id" component={ShopActionProduct} />
                <PrivateRoute authed={isLoggedIn()} path="/seller/booking" exact component={ShopListOrder} />
                <PrivateRoute authed={isLoggedIn()} path="/seller/booking/:id" component={ShopDetailOrder} />
                <PrivateRoute authed={isLoggedIn()} path="/seller/statistic" exact component={StatisticOrderShop} />
                {/* Info user */}
                <PrivateRoute authed={isLoggedIn()} path="/profile" exact component={Infomation} />
                <PrivateRoute authed={isLoggedIn()} path="/profile/change-pass" exact component={ChangePass} />
                <PrivateRoute authed={isLoggedIn()} path="/profile/user" exact component={User} />
                {/* Page 404 */}
                <PrivateRoute authed={isLoggedIn()} path={`*`} component={PageNotFound} />
            </Switch>
        </Router>
    )
}

