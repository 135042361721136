import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Layout, message, Button, List, Row, Col, Avatar, Alert, Dropdown, Menu, Form, Select, Input, Modal,
    Empty, DatePicker, Breadcrumb, Tag
} from "antd";
import {
    ClockCircleOutlined, UserOutlined, MobileOutlined, BranchesOutlined, DownOutlined, CalendarOutlined,
    CheckCircleOutlined, SearchOutlined, RedoOutlined, SwapOutlined, StopOutlined, HomeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import CONSTANT from "../../../config/constant";
import { axiosInstance } from "../../../config/api";
const arrStatus = {
    "wait-confirm": {
        text: "Chờ duyệt",
        color: "orange",
        icon: <ClockCircleOutlined />,
    },
    confirmed: { text: "Chấp nhận", color: "#2db7f5", icon: <SwapOutlined /> },
    completed: {
        text: "Đã khám",
        color: "#87d068",
        icon: <CheckCircleOutlined />,
    },
    "not-come": { text: "Không đến", color: "grey" },
    cancelled: { text: "Từ chối", color: "#f50", icon: <StopOutlined /> },
};
const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const ListOrderDiaryService = (props) => {
    const [form] = Form.useForm();
    const [listService, setListService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reasonCancel, setResonCancel] = useState("Có việc đột xuất");
    const [selectedId, setSelectedId] = useState();
    const [optionFilter, setOptionFilter] = useState({});
    const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0,
        perPage: 10,
        hideOnSinglePage: true,
        showLessItems: true,
    });

    // effect
    useEffect(() => {
        loadOrder({ page: 1, perPage: 10 });
    }, []);

    const loadOrder = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam)
            .map((key) => key + "=" + queryParam[key])
            .join("&");
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_hospital || user.is_doctor || user.is_service) {
            await axiosInstance
                .get(
                    `${CONSTANT.API.SERVICE.ORDER}?${query}&agency=${user._id}&diary=true`
                )
                .then(async (res) => {
                    if (res && res.data && res.data.code === 200) {
                        // Set giá trị cho pagination
                        let _pagination = { ...pagination };
                        _pagination.total = res.data.data.total;
                        setPagination(_pagination);

                        let docs = res.data.data.docs;
                        // Thực hiện nhóm mảng theo ngày
                        let results = [];
                        let i = 0;
                        for (let item of docs) {
                            if (results.length === 0)
                                results.push({ date: item.date, orders: [item] });
                            else if (results[i].date === item.date) {
                                results[i].orders.push(item);
                            } else {
                                results.push({ date: item.date, orders: [item] });
                                i++;
                            }
                        }
                        setListService(results);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setListService([]);
                    message.warning('Lỗi dữ liệu nhập không đúng!', 3);
                });
        } else {
            message
                .warning(
                    "Bạn chưa đăng ký làm Đối tác Dịch vụ y tế Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!",
                    10
                )
                .then(() => props.history.push("/"));
        }
        setLoading(false);
    };

    // Xử lý sự kiện search
    const onSearch = async (values) => {
        let options = {};
        if (values.q !== "" && values.q !== undefined) {
            if (values.field === "name") {
                options.fullname = values.q;
            }

            if (values.field === "uid") {
                options.uid = values.q.replace("VIVMEDIC-S", "");
            }
        }

        if (values.date_range) {
            options.date_range = JSON.stringify({
                start: moment(values.date_range[0]).format("YYYY-MM-DD"),
                end: moment(values.date_range[1]).format("YYYY-MM-DD"),
            });
        }
        loadOrder({
            page: 1,
            perPage: pagination.perPage,
            ...options,
        });
        setOptionFilter(options);
    };
    const reload = () => {
        loadOrder({ page: 1, perPage: pagination.perPage });
        form.resetFields();
    };
    const onPaginationChange = async (page) => {
        loadOrder({ page, perPage: pagination.perPage, ...optionFilter });
    };

    return (
        <div>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <Link to="/">
                        <HomeOutlined />
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Dịch vụ</Breadcrumb.Item>
                <Breadcrumb.Item>Thông tin đặt dịch vụ</Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content
                className="layout-content"
                style={{ padding: "8px", background: "none" }}
            >
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    //style={{ marginBottom: 20 }}
                    initialValues={{
                        field: "name",
                    }}
                    style={{ margin: "0px -8px 20px -8px" }}
                    form={form}
                >
                    <Form.Item name="q" extra="(VD: Nguyễn Văn A hoặc VIVMEDIC-S123)">
                        <Input
                            type="text"
                            placeholder="Nhập Tên người khám hoặc Mã lịch hẹn "
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select style={{ width: 150 }}>
                            <Select.Option value="name">Tên người khám</Select.Option>
                            <Select.Option value="uid">Mã lịch hẹn</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range">
                        <DatePicker.RangePicker
                            format="DD-MM-YYYY"
                            disabledDate={(current) =>
                                current && current < moment().startOf("day")
                            }
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Tải lại trang
                        </Button>
                    </Form.Item>
                </Form>
                {/* End form search */}

                <List
                    loading={loading}
                    dataSource={listService}
                    renderItem={(list) => (
                        <>
                            <div style={{ fontWeight: 700, margin: "20px 0px 10px 0px" }}>
                                {moment(list.date).format("DD-MM-YYYY")}
                            </div>
                            {list?.orders?.length > 0 &&
                                list.orders.map((item) => (
                                    <Row className="item-doctor-order" gutter={16} key={item._id}>
                                        <Col xs={24} md={8}>
                                            <Row align="middle">
                                                <Col md={3} className="text-center">
                                                    <Avatar
                                                        style={{
                                                            color: "#fff",
                                                            backgroundColor: `${getRandomColor()}`,
                                                            textTransform: "uppercase",
                                                        }}
                                                    >
                                                        {item?.contact?.fullname[0] || "U"}
                                                    </Avatar>
                                                    <div className="code">
                                                        {item?.items?.length > 0 &&
                                                            item?.items[0]?.service?.uid}
                                                    </div>
                                                </Col>
                                                <Col md={20} className="col-patient">
                                                    <UserOutlined className="icon" />
                                                    <span className="name">
                                                        {item?.contact?.fullname}
                                                    </span>
                                                    <br />
                                                    <ClockCircleOutlined className="icon" />
                                                    <span className="time">
                                                        {moment(item.time).format("HH:mm")}
                                                    </span>

                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={16} className="col-detail">
                                            <div>
                                                <MobileOutlined className="icon" />
                                                <span className="phone">
                                                    {item?.contact?.dial_code
                                                        ? "+" + item?.contact?.dial_code
                                                        : ""}
                                                    {item?.contact?.phone}
                                                </span>

                                                <BranchesOutlined className="icon" />
                                                <span className="span cate">
                                                    {item?.items?.length > 0 &&
                                                        item?.items[0].service?.name}
                                                </span>
                                            </div>
                                            <div>
                                                Địa điểm:{" "}
                                                {item?.work_place === "at_guest"
                                                    ? "Tại nhà khách hàng"
                                                    : "Tại cơ sở dịch vụ"}{" "}
                                                - {item?.address_work}
                                            </div>
                                            <div className="note">{item.note}</div>
                                            <div className="group-btn">
                                                <Button icon={<CalendarOutlined />} size="small">
                                                    {moment(item.date).format("DD-MM-YYYY")} -{" "}
                                                    {moment(item.time).format("HH:mm")}
                                                </Button>
                                                <Tag color={arrStatus[item.status].color}>{arrStatus[item.status].text}</Tag>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                        </>
                    )}
                    pagination={{
                        ...pagination,
                        onChange: onPaginationChange,
                    }}
                    locale={{ emptyText: <Empty description="Chưa có lịch hẹn" /> }}
                />

            </Layout.Content>
        </div>
    );
};

export default ListOrderDiaryService;
