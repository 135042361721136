import React from "react";
import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";

import "./dashboard.css";

export default class Dashboard extends React.PureComponent {
  render() {
    return (
      <div className="dashboard">
        <div className="banner">
          <Result
            icon={<SmileOutlined style={{ color: "white" }} />}
            title="Tuyệt vời, Bạn đang ở trang quản trị dành cho Đối tác của Vivmedic"
            subTitle="Bạn có thể bắt đầu quản lý thông tin đăng ký dịch vụ của bạn và theo dõi lịch hẹn, đơn hàng!"
            style={{ margin: "0 auto", padding: "20px 0px 15px 0px" }}
          />
        </div>
        {/* <Layout.Content>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card
                                style={{ marginTop: 16 }}
                                type="inner"
                                title={(
                                    <>
                                        <CarryOutOutlined style={{fontSize:24}} />
                                        <span>Tổng Booking</span>
                                    </>
                                )}
                                extra={<a href="#">More</a>}
                            >
                                Inner Card content
                            </Card>
                        </Col>
                    </Row>
                </Layout.Content> */}
      </div>
    );
  }
}
