import React from 'react';
import { Link } from 'react-router-dom';
import {
	Layout, Breadcrumb, Tabs
} from 'antd';
import {
	UserAddOutlined, HomeOutlined, MedicineBoxOutlined
} from '@ant-design/icons';
import DiaryDoctor from './diary-doctor';
import DiaryExaminationPackage from './diary-examination-package';

const ListOrderHospitalDiary = () => {

	return (
		<div>
			<Breadcrumb style={{ margin: '16px 0' }}>
				<Breadcrumb.Item >
					<Link to="/"><HomeOutlined /></Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Cơ sở y tế
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Nhật ký khám chữa bệnh
				</Breadcrumb.Item>
			</Breadcrumb>
			<Layout.Content className="layout-content" style={{ padding: '8px', background: 'none' }}>

				<Tabs type="card" defaultActiveKey="doctor" className="order-tab">
					<Tabs.TabPane
						tab={
							<span>
								<UserAddOutlined />
								Lịch hẹn cho Bác sĩ
							</span>
						}
						key="doctor"
					>
						<DiaryDoctor />
					</Tabs.TabPane>

					<Tabs.TabPane
						tab={
							<span>
								<MedicineBoxOutlined />
								Lịch hẹn cho gói khám
							</span>
						}
						key="package"
					>
						<DiaryExaminationPackage />
					</Tabs.TabPane>

				</Tabs>
			</Layout.Content>
		</div >
	)

}

export default ListOrderHospitalDiary;
