import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, message, Spin, Popconfirm, Divider, Space, Button
} from 'antd';
import { HomeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, CalendarOutlined, EyeFilled } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import ModalActionService from './components/action-service';
const InfoService = (props) => {
    const columns = [
        {
            title: 'Danh mục công việc',
            dataIndex: 'name',
        },
        {
            title: 'Giá tiền', width: '150px',
            dataIndex: 'price',
            render: record => record.toLocaleString('en-US')
        },
        {
            title: (<div style={{ textAlign: 'center' }}>Hành động</div>), width: '100px',
            render: (record) => (
                <Space size="middle">
                    <Button type="link" icon={<CalendarOutlined />}
                        onClick={() => props.history.push(`/doctor/service/${record._id}/setting`)} >
                        Cài đặt lịch
                    </Button>
                    <Divider type="vertical" />
                    <Button type="link" onClick={() => openActionService(
                        {
                            serviceId: record._id,
                            serviceName: record.name,
                            summary: record.summary,
                            price: record.price,
                            price_note: record.price_note,
                            is_online: record.is_online,
                            is_free: record.is_free,
                            is_f0: record.is_f0,
                            is_government_insurance: record.is_government_insurance,
                            note_government_insurance: record.note_government_insurance,
                            is_direct_guarantee_insurance: record.is_direct_guarantee_insurance,
                            note_direct_guarantee_insurance: record.note_direct_guarantee_insurance,
                            list_direct_guarantee_insurance: record.list_direct_guarantee_insurance,
                            price_list: record.price_list,
                        }
                    )}><EditOutlined /> Sửa</Button>
                    <Divider type="vertical" />
                    <Popconfirm title="Bạn muốn xóa  này?" onConfirm={() => { delService(record._id) }}>
                        <Button type="link" danger><DeleteOutlined /> Xóa</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const [doctor, setDoctor] = useState();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const openActionService = async (data) => {
        await setServiceData(data);
        setIsModalVisible(true);
    }
    // effect
    useEffect(() => {
        loadDoctor();
    }, []);

    const loadDoctor = async () => {
        setLoading(true);
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_doctor) {
            await axiosInstance.get(`${CONSTANT.API.DOCTOR.DOCTOR}?created_by=${user._id}`)
                .then(async res => {
                    if (res.data.data && res.data.data.docs.length > 0) {
                        if (res && res.data && res.data.code === 200) {
                            setDoctor(res.data.data.docs[0])
                        }
                    }

                })
                .catch(error => message.warning('Đối tác chuyên môn không tồn tại!' + error.message, 5).then(() => props.history.push('/')))
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác chuyên môn Tatinta, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
        }
        setLoading(false);
    }
    const delService = async (serviceId) => {
        setLoading(true);
        let dataSave = {
            action: 'delService',
            serviceId: serviceId
        }
        await axiosInstance.patch(`${CONSTANT.API.DOCTOR.DOCTOR}/${doctor._id}`, dataSave)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Xóa thành công !')
                    setDoctor(res.data.data);
                }
                else {
                    message.error('Xóa thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            });
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Dành cho Bác sĩ
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Quản lý công việc
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                <Button type="primary" icon={<PlusOutlined />} style={{ float: 'right', marginBottom: 20 }}
                    onClick={() => openActionService(null)} >
                    Thêm danh mục công việc
                </Button>
                <Button hidden color='green' icon={<CalendarOutlined />} style={{ float: 'right', marginBottom: 20, marginRight: 20 }}
                    onClick={() => props.history.push("/doctor/services/setting", doctor)} >
                    Cài đặt lịch nhiều công việc
                </Button>
                {
                    doctor && <Table dataSource={doctor.services}
                        columns={columns} loading={loading}
                        pagination={false}
                        rowKey={record => record._id} />
                }

            </Layout.Content>
            <ModalActionService visible={isModalVisible} data={serviceData} doctorId={doctor && doctor._id}
                cancel={(data) => {
                    setIsModalVisible(false);
                    if (data) {
                        setDoctor(data);
                    }
                }} />
        </div >
    )

}

export default InfoService;