import React, { useState, useCallback, useEffect } from 'react';
import {
    Modal, Row, Col, Form, Input, Select, Upload, message, Button, Avatar, DatePicker, Radio, Checkbox, InputNumber
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import * as COMMON from '../../../config/common';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const ModalActionVoucher = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [valueType, setValueType] = useState(null);
    const [isBonus, setIsBonus] = useState(false);
    const handleCancel = useCallback(() => {
        form.resetFields();
        props.cancel();
    }, [form, props.voucherId]);
    useEffect(() => {
        loadServices();
        loadVoucher();
    }, [props]);
    const loadVoucher = async () => {
        setLoading(true);
        setConfirmLoading(true);
        if (props?.voucherId) {
            await axiosInstance.get(`${CONSTANT.API.SERVICE.VOUCHER}/${props?.voucherId}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        const data = res.data.data;
                        if (data?.is_bonus_extra) setIsBonus(true)
                        else setIsBonus(false)
                        form.setFieldsValue({
                            name: data?.name,
                            dates: [moment(data?.from_date), moment(data?.to_date)],
                            type: data?.type,
                            quantity: data?.quantity,
                            value_type: data?.value_type,
                            value: data?.value,
                            value_type_condition_false: data?.value_type_condition_false,
                            value_condition_false: data?.value_condition_false,
                            description: data?.description,
                            service_apply: data?.service_apply,
                            is_bonus_extra: data?.is_bonus_extra,
                            bonus_extra_type: data?.bonus_extra_type,
                            bonus_extra_value: data?.bonus_extra_value,
                            min_total_order: data?.min_total_order,
                            max_total_order: data?.max_total_order,
                            status: data?.status
                        });
                        setDoctorAvatar({
                            filename: data ? data.avatar : null,
                            status: 'done-add',
                            url: CONSTANT.STATIC_URL + (data ? data.avatar : null),
                            thumbUrl: CONSTANT.STATIC_URL + (data ? data.avatar : null)
                        });
                    }
                    else {
                        message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!', 4)
                    }
                })
                .catch(error => { message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!'); console.log(error) })
        }
        else {
            form.resetFields();
            setDoctorAvatar({
                filename: null,
                status: 'done-add',
                url: null,
                thumbUrl: null
            });
            //form.setFieldsValue({ home_office: props.hospital?.name })
        }
        setLoading(false);
        setConfirmLoading(false);
    }
    const loadServices = async () => {
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        await axiosInstance.get(`${CONSTANT.API.SERVICE.SERVICE}?created_by=${user._id}`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    const { docs, total } = res.data.data;
                    setServices(docs);
                }

            })
            .catch(error => message.warning('Không thể lấy danh sách dịch vụ!' + error.message, 5))
    }
    const [voucherAvatar, setDoctorAvatar] = useState({
        filename: null,
        status: 'done-add',
        url: null,
        thumbUrl: null
    });

    const beforeUploadAvatar = async file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Bạn chỉ được tải ảnh JPG/PNG!');
        }
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
            message.error('Hình ảnh phải nhỏ hơn 1MB!');
        }
        return isJpgOrPng && isLt1M;
    }
    const changeUploadAvatar = async info => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            const { response } = info.file;
            if (response && response.code === 200) {
                let avatarUp = { ...voucherAvatar };
                avatarUp = {
                    filename: response.data.filename,
                    status: 'done',
                    url: CONSTANT.STATIC_URL + 'tmp/' + response.data.filename,
                    thumbUrl: CONSTANT.STATIC_URL + 'tmp/' + response.data.filename,
                    code: 200
                }
                setDoctorAvatar(avatarUp);
            }
        }
    }
    const saveImage = async () => {
        if (voucherAvatar) {
            if (voucherAvatar.status === 'done') {
                if (voucherAvatar.code === 200) {
                    return await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                        filename: voucherAvatar.filename,
                        type: "voucher"
                    }).then(async res => {
                        if (res && res.data && res.data.code === 200) {
                            let avatar = { ...voucherAvatar };
                            avatar.url = res.data.data.url;
                            avatar.status = 'done-add';
                            setDoctorAvatar(avatar);
                            return res.data.data.url;
                        }
                    });
                }
            }
            else {
                return voucherAvatar.filename;
            }
        }
    };
    const onFinishFailed = async (values) => {
        message.error('Vui lòng nhập đầy đủ thông tin theo yêu cầu, cảm ơn !', 5);
    }
    const onFinish = useCallback(async (values) => {
        setConfirmLoading(true);
        values.avatar = await saveImage();
        values.from_date = values.dates[0];
        values.to_date = values.dates[1];
        if (!props?.voucherId) {
            await axiosInstance.post(`${CONSTANT.API.SERVICE.VOUCHER}`, values)
                .then(res => {
                    if (res && res.data.data && res.data.code === 200) {
                        message.success('Thêm thành công !')
                        form.resetFields();
                        props.cancel(res.data.data);
                    }
                    else {
                        message.error('Thêm thất bại! Vui lòng thử lại sau.');
                    }
                    setConfirmLoading(false);
                }).catch(() => setConfirmLoading(false));
        }
        else {
            await axiosInstance.patch(`${CONSTANT.API.SERVICE.VOUCHER}/${props?.voucherId}`, values)
                .then(res => {
                    if (res && res.data.data && res.data.code === 200) {
                        message.success('Sửa thành công !')
                        form.resetFields();
                        props.cancel(res.data.data);
                    }
                    else {
                        message.error('Sửa thất bại! Vui lòng thử lại sau.');
                    }
                    setConfirmLoading(false);
                }).catch(() => setConfirmLoading(false));
        }
    }, [props, voucherAvatar]);
    const disabledDate = current => {
        return current < moment().startOf("day");
    };
    const onChangeValueType = async (value) => {
        setValueType(value);
    }
    return (
        <Modal title={props?.voucherId ? "Sửa chương trình khuyến mãi" : "Thêm chương trình khuyến mãi"}
            visible={props.visible}
            onOk={form.submit}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Lưu thông tin"
            confirmLoading={confirmLoading}
            className="modal-book-facility"
            width={'1000px'}
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{ is_bonus_extra: false }}>
                <Row gutter={24}>
                    <Col xs={8} sm={6} md={4}>
                        <Form.Item>
                            <Avatar
                                style={{ width: '100%', height: 'auto', borderRadius: 0 }}
                                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                src={voucherAvatar && voucherAvatar.url}
                            />
                            <ImgCrop
                                rotate
                                modalTitle="Sửa ảnh"
                                modalOk="Đồng ý"
                                aspect={800 / 800}
                                quality={0.8}
                                modalWidth={800}
                            >
                                <Upload
                                    name="file"
                                    action={`${CONSTANT.API.EXTRA.UPLOAD_AVATAR}`}
                                    showUploadList={false}
                                    beforeUpload={beforeUploadAvatar}
                                    onChange={changeUploadAvatar}
                                    headers={{
                                        'X-TK': CONSTANT.API.KEY
                                    }}
                                >
                                    <Button type="link">
                                        {loading ? <LoadingOutlined /> : 'Chọn ảnh đại diện'}
                                    </Button>
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={10} md={20}>
                        <Row gutter={24}>
                            <Col xs={24} sm={14} md={8}>
                                <Form.Item
                                    label="Tên chương trình"
                                    name="name"
                                    required
                                    tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập tên chương trình !' },
                                        {
                                            type: 'string', max: 100, message: "Tên chương trình tối đa 100 ký tự !"
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nhập tên chương trình" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={10} md={16}>
                                <Form.Item
                                    label="Thời gian áp dụng"
                                    required
                                    tooltip="Chương trình sẽ tự động triển khai trong khoảng thời gian mà bạn cài đặt."
                                    name="dates"
                                    rules={[
                                        { required: true, message: 'Vui lòng cài đặt thời gian áp dụng !' },
                                    ]}
                                >
                                    <RangePicker
                                        format="DD/MM/YYYY HH:mm"
                                        showTime
                                        //value={hackValue || value}
                                        disabledDate={disabledDate}

                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={14} md={8}>
                                <Form.Item label="Kiểu chương trình" name="type"
                                    tooltip="Kiểu đếm số lượng là khi khách đặt đủ số lượng. Cấp mã là khi khách đăng ký sẽ nhận được mã tự động tạo của chương trình."
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn kiểu chương trình !' },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'count_max'}>Đếm số lượng</Radio>
                                        <Radio value={'generate_code'}>Cấp mã</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={14} md={6}>
                                <Form.Item label="Số lượng" name="quantity"
                                    tooltip="Là số lượng tối đa mà chương trình cho phép sử dụng."
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập' },
                                    ]}>
                                    <InputNumber style={{ width: '100%' }} placeholder="Nhập số lượng"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\\s?|(,*)/g, '')} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={14} md={10}>
                                <Form.Item label="Loại giảm giá | Mức giảm" required tooltip="Giảm theo phần trăm giá trị đặt hoặc giảm theo tiền mặt cài đặt.">
                                    <Input.Group compact>
                                        <Form.Item
                                            name="value_type"
                                            noStyle
                                            rules={[{ required: true, message: 'Vui lòng chọn' }]}
                                        >
                                            <Select placeholder="Chọn loại" style={{ width: '40%' }} onChange={(value) => onChangeValueType(value)}>
                                                <Select.Option value={'cash'}>Tiền mặt</Select.Option>
                                                <Select.Option value={'percent'}>Phần trăm</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="value"
                                            noStyle
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <InputNumber style={{ width: '60%' }} placeholder="Nhập giá trị"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\\s?|(,*)/g, '')} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            {
                                valueType === 'cash' && <> <Col xs={24} sm={14} md={8}>
                                    <Form.Item label="Giá trị tối thiểu" name="min_total_order"
                                        tooltip="Giá trị thấp nhất của hóa đơn có thể áp dụng mã voucher"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập giá trị tối thiểu !' },
                                        ]}>
                                        <InputNumber style={{ width: '100%' }} placeholder="Nhập giá trị"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\\s?|(,*)/g, '')} />
                                    </Form.Item>
                                </Col>
                                </>
                            }
                            {
                                valueType === 'percent' && <Col xs={24} sm={14} md={8}>
                                    <Form.Item label="Giá trị tối đa" name="max_total_order"
                                        tooltip="Giá trị tối đa của hóa đơn có thể áp dụng mã voucher"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập giá trị tối đa !' },
                                        ]}>
                                        <InputNumber style={{ width: '100%' }} placeholder="Nhập giá trị"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\\s?|(,*)/g, '')} />
                                    </Form.Item>
                                </Col>
                            }
                            <Col xs={24} sm={14} md={8}>
                                <Form.Item label="Giảm giá nếu không đủ điều kiện" required tooltip="Nếu hóa đơn không đủ điều kiện thì giảm theo giá trị được cài đặt tại đây.">
                                    <Input.Group compact>
                                        <Form.Item
                                            name="value_type_condition_false"
                                            noStyle
                                            rules={[{ required: true, message: 'Vui lòng chọn' }]}
                                        >
                                            <Select placeholder="Chọn loại" style={{ width: '45%' }}>
                                                {
                                                    valueType === 'cash' && <Select.Option value={'percent'}>Phần trăm</Select.Option>
                                                }
                                                {
                                                    valueType === 'percent' && <Select.Option value={'cash'}>Tiền mặt</Select.Option>
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="value_condition_false"
                                            noStyle
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <InputNumber style={{ width: '55%' }} placeholder="Nhập giá trị"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\\s?|(,*)/g, '')} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Col>
                    <Col xs={24} sm={12} md={24}>
                        <Form.Item
                            label="Mô tả ngắn"
                            tooltip="Giới thiệu về chương trình"
                            name='description'
                            rules={[
                                {
                                    type: 'string', max: 1000, message: "Mô tả tối đa 1000 ký tự !"
                                }
                            ]}
                        >
                            <TextArea showCount maxLength={1000} style={{ width: '100%' }} rows={3} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={24}>
                        <Form.Item label="Dịch vụ được áp dụng" name="service_apply" required tooltip="Vui lòng chọn trường này">
                            <Select
                                mode="multiple"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    services.length > 0 &&
                                    services.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8}>
                        <Form.Item
                            name='is_bonus_extra'
                            tooltip="Nếu chọn trường này sau khi khách đặt sẽ tự động gửi mã cho lần đặt tiếp theo"
                            label="Khuyến mãi thêm mã giới thiệu"
                            valuePropName="checked"
                        >
                            <Checkbox onChange={event => { setIsBonus(event.target.checked); }}>Có thêm mã giới thiệu</Checkbox>
                        </Form.Item>
                    </Col>
                    {isBonus &&
                        <Col xs={24} sm={8} md={8}>
                            <Form.Item label="Loại mã giảm giá | Mức giảm" required>
                                <Input.Group compact>
                                    <Form.Item
                                        name="bonus_extra_type"
                                        noStyle
                                        rules={[{ required: true, message: 'Vui lòng chọn' }]}
                                    >
                                        <Select placeholder="Chọn loại" style={{ width: '40%' }}>
                                            <Select.Option value={'cash'}>Tiền mặt</Select.Option>
                                            <Select.Option value={'percent'}>Phần trăm</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="bonus_extra_value"
                                        noStyle
                                        rules={[{ required: true, message: '' }]}
                                    >
                                        <InputNumber style={{ width: '60%' }} placeholder="Nhập giá trị"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\\s?|(,*)/g, '')} />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={24} sm={8} md={8}>
                        <Form.Item
                            name='status'
                            valuePropName="checked"
                        >
                            <Checkbox style={{ marginTop: '35px' }}>Kích hoạt chương trình</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default ModalActionVoucher;