import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Row, Col, Input, InputNumber, message, Checkbox, Tabs, Button, Divider } from 'antd';
import { DeleteRowOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
const { TextArea } = Input;
const ModalActionService = (props) => {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const handleCancel = useCallback(() => {
        form.resetFields();
        props.cancel();
    }, [form]);
    useEffect(() => {
        form.setFieldsValue({
            serviceName: props.data ? props.data.serviceName : '',
            summary: props.data ? props.data.summary : '',
            price: props.data ? props.data.price : '',
            price_note: props.data ? props.data.price_note : '',
            is_government_insurance: props.data ? props.data.is_government_insurance : false,
            note_government_insurance: props.data ? props.data.note_government_insurance : '',
            is_direct_guarantee_insurance: props.data ? props.data.is_direct_guarantee_insurance : false,
            note_direct_guarantee_insurance: props.data ? props.data.note_direct_guarantee_insurance : '',
            list_direct_guarantee_insurance: props.data ? props.data.list_direct_guarantee_insurance : '',
            price_list: props.data ? props.data.price_list : []
        });
    }, [props.data]);

    const onSubmit = useCallback(async (values) => {
        setConfirmLoading(true);
        if (props.data) {
            let dataSave = {
                action: 'updService',
                serviceId: props.data.serviceId,
                serviceName: values.serviceName,
                summary: values.summary,
                price: values.price,
                price_note: values.price_note,
                is_active: false,
                is_government_insurance: values.is_government_insurance,
                note_government_insurance: values.note_government_insurance,
                is_direct_guarantee_insurance: values.is_direct_guarantee_insurance,
                note_direct_guarantee_insurance: values.note_direct_guarantee_insurance,
                list_direct_guarantee_insurance: values.list_direct_guarantee_insurance,
                price_list: values.price_list
            }
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospitalId}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Sửa thông tin thành công. Nội dung sẽ được kiểm duyệt trước khi được hiển thị đến khách truy cập !')
                        form.resetFields();
                        setConfirmLoading(false);
                        props.cancel(res.data.data);
                    }
                    else {
                        setConfirmLoading(false);
                        message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
        else {
            //debugger;
            let dataSave = {
                action: 'insService',
                serviceName: values.serviceName,
                summary: values.summary,
                price: values.price,
                price_note: values.price_note,
                is_active: false,
                is_government_insurance: values.is_government_insurance,
                note_government_insurance: values.note_government_insurance,
                is_direct_guarantee_insurance: values.is_direct_guarantee_insurance,
                note_direct_guarantee_insurance: values.note_direct_guarantee_insurance,
                list_direct_guarantee_insurance: values.list_direct_guarantee_insurance === "" ? [] : values.list_direct_guarantee_insurance,
            }
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospitalId}`, dataSave)
                .then(res => {
                    if (res && res.data.data && res.data.code === 200) {
                        message.success('Thêm thành công. Nội dung sẽ được kiểm duyệt trước khi được hiển thị đến khách truy cập !')
                        form.resetFields();
                        props.cancel(res.data.data);
                    }
                    else {
                        message.error('Thêm thất bại! Vui lòng thử lại sau.');
                    }
                    setConfirmLoading(false);
                }).catch(() => setConfirmLoading(false));

        }
        setConfirmLoading(false);
    }, [props]);
    return (
        <Modal title={props.data && props.data.serviceId ? "Sửa thông tin " : "Thêm mới"}
            visible={props.visible}
            onOk={form.submit}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Lưu thông tin"
            confirmLoading={confirmLoading}
            className="modal-book-facility"
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            width={700}
            maskClosable={false}
        >
            <Form
                name="basic"
                layout="vertical"
                onFinish={onSubmit}
                onFinishFailed={() => { }}
                form={form}

            >
                <Tabs defaultActiveKey="gt" type="card" size="large" className="tabs-info">
                    <Tabs.TabPane forceRender={true} tab="Thông tin " key="qtdt" className="service-specialty" >
                        <Row gutter={[24]} align="middle">
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                    label="Tên công việc"
                                    tooltip="Tên công việc "
                                    name='serviceName'
                                    rules={[
                                        {
                                            required: true, message: 'Vui lòng nhập Tên công việc',

                                        }, {
                                            type: 'string', max: 100, message: "Tên công việc tối đa 100 ký tự !"
                                        }
                                    ]}>
                                    <Input placeholder="Nhập tên công việc" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item label="Mô tả" name="summary"
                                    rules={[
                                        {
                                            required: true, message: 'Vui lòng nhập mô tả !',
                                        },
                                        {
                                            type: 'string', max: 1000, message: "Mô tả tối đa 1000 ký tự !"
                                        }
                                    ]}>
                                    <TextArea showCount maxLength={1000} style={{ width: '100%' }} rows={5} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={10} md={10}>
                                <Form.Item
                                    label="Giá mặc định"
                                    tooltip="Giá này được áp dụng khi không có bảng giá"
                                    name='price'
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập giá mặc định' },
                                        {
                                            type: 'number', max: 100000000, message: "Giá mặc định tối đa 100.000.000 !"
                                        }
                                    ]}>
                                    <InputNumber placeholder="Nhập mặc định" min={0}
                                        formatter={value => `₫ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace('₫', '').replace(/\\s?|(,*)/g, '')}
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                    label="Ghi chú giá"
                                    tooltip="Ghi chú giá (Bao gồm, không bao gồm ...)"
                                    name='price_note'
                                    rules={[
                                        {
                                            type: 'string', max: 1000, message: "Mô tả tối đa 1000 ký tự !"
                                        }
                                    ]}
                                >
                                    <TextArea showCount maxLength={1000} style={{ width: '100%' }} rows={5} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane forceRender={true} tab="Bảng giá" key="price_list" className="service-specialty" >
                        <Row gutter={[24]} align="middle">
                            <Col xs={24} sm={24} md={24}>
                                <Form.List name="price_list" label="Danh sách bảng giá được áp dụng">
                                    {(fields, { add, remove }) => {
                                        return (
                                            <>
                                                {
                                                    fields.map((field, index) => (
                                                        <Row gutter={24} key={field.key}>
                                                            <Col xs={22} sm={22} md={22}>
                                                                <Form.Item
                                                                    name={[index, 'name']}
                                                                    required tooltip="Tên bảng giá"
                                                                    rules={[
                                                                        {
                                                                            required: true, message: 'Vui lòng nhập tên bảng giá!'
                                                                        }, {
                                                                            type: 'string', max: 100, message: "Tên bảng giá tối đa 100 ký tự !"
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Nhập tên bảng giá" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={2} sm={2} md={2}>
                                                                <Button
                                                                    type="link"
                                                                    danger
                                                                    style={{ width: '100%' }}
                                                                    onClick={() => remove(field.name)}
                                                                    title='Xóa bảng giá này'
                                                                    icon={<DeleteRowOutlined />}
                                                                >
                                                                </Button>
                                                            </Col>
                                                            <Col xs={24} sm={24} md={24}>
                                                                <Form.List name={[field.name, 'list']}>
                                                                    {(list, { add, remove }) => {
                                                                        return (
                                                                            <>
                                                                                {list.map((field1, index1) => (
                                                                                    <Row xs={24} sm={24} md={24}>
                                                                                        <Col xs={2} sm={2} md={2}>
                                                                                            <Button
                                                                                                type="danger"
                                                                                                style={{ width: '100%' }}
                                                                                                onClick={() => remove(field1.name)}
                                                                                                icon={<MinusCircleOutlined />}
                                                                                            >
                                                                                            </Button>
                                                                                        </Col>
                                                                                        <Col xs={11} sm={11} md={11}>
                                                                                            <Form.Item
                                                                                                name={[index1, 'type_name']}
                                                                                                rules={[
                                                                                                    { required: true, message: 'Vui lòng nhập loại giá' },
                                                                                                ]}>
                                                                                                <Input placeholder="Nhập loại giá" />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                        <Col xs={11} sm={11} md={11}>
                                                                                            <Form.Item
                                                                                                name={[index1, 'price']}
                                                                                                rules={[
                                                                                                    { required: true, message: 'Vui lòng nhập giá tiền' },
                                                                                                    {
                                                                                                        type: 'number', max: 100000000, message: "Giá tiền tối đa 100.000.000 !"
                                                                                                    }
                                                                                                ]}>
                                                                                                <InputNumber placeholder="Nhập giá tiền" min={0}
                                                                                                    formatter={value => `₫ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                                    parser={value => value.replace('₫', '').replace(/\\s?|(,*)/g, '')}
                                                                                                    style={{ width: '100%' }} />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    </Row>
                                                                                ))}
                                                                                <Form.Item style={{ width: "100%" }}>
                                                                                    <Button
                                                                                        type="dashed"
                                                                                        onClick={() => add()}
                                                                                        style={{ width: "100%" }}
                                                                                    >
                                                                                        <PlusOutlined /> Thêm giá
                                                                                    </Button>
                                                                                </Form.Item>
                                                                                <Divider></Divider>
                                                                            </>
                                                                        );
                                                                    }}
                                                                </Form.List>

                                                            </Col>

                                                        </Row>
                                                    ))}
                                                <Row gutter={24}>
                                                    <Col xs={24} sm={24} md={24}>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <PlusOutlined /> Thêm bảng giá
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }}
                                </Form.List>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane forceRender={true} tab="Bảo hiểm y tế nhà nước" key="qtct" className="service-specialty" >
                        <Row gutter={[24]} align="middle">
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                    tooltip="Áp dụng bảo hiểm y tế nhà nước"
                                    name='is_government_insurance'
                                    valuePropName="checked"
                                >
                                    <Checkbox>Áp dụng bảo hiểm y tế nhà nước</Checkbox>
                                </Form.Item>
                                <Form.Item label="Ghi chú" name="note_government_insurance"
                                    rules={[
                                        {
                                            type: 'string', max: 300, message: "Ghi chú về áp dụng loại bảo hiểm y tế nhà nước tối đa 300 ký tự !"
                                        }
                                    ]}>
                                    <TextArea showCount maxLength={300} style={{ width: '100%' }} rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane forceRender={true} tab="Bảo hiểm bảo lãnh trực tiếp" key="qtct1" className="service-specialty" >
                        <Row gutter={[24]} align="middle">
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                    tooltip="Áp dụng bảo hiểm bảo lãnh trực tiếp"
                                    name='is_direct_guarantee_insurance'
                                    valuePropName="checked"
                                >
                                    <Checkbox>Áp dụng bảo hiểm bảo lãnh trực tiếp</Checkbox>
                                </Form.Item>
                                <Form.Item label="Ghi chú" name="note_direct_guarantee_insurance"
                                    rules={[
                                        {
                                            type: 'string', max: 300, message: "Ghi chú về áp dụng loại bảo hiểm bảo lãnh trực tiếp tối đa 300 ký tự !"
                                        }
                                    ]}>
                                    <TextArea showCount maxLength={300} style={{ width: '100%' }} rows={4} />
                                </Form.Item>
                                <Divider>Danh sách công ty áp dụng</Divider>
                                <Form.List name="list_direct_guarantee_insurance" label="Danh sách công ty bảo lãnh được áp dụng">
                                    {(fields, { add, remove }) => {
                                        return (
                                            <>
                                                {
                                                    fields.map((field, index) => (
                                                        <Row gutter={24} key={field.key}>
                                                            <Col xs={22} sm={22} md={22}>
                                                                <Form.Item
                                                                    name={[index]}
                                                                    required tooltip="Tên công ty"
                                                                    rules={[
                                                                        {
                                                                            required: true, message: 'Vui lòng nhập tên công ty!'
                                                                        }, {
                                                                            type: 'string', max: 200, message: "Tên công ty tối đa 200 ký tự !"
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Nhập tên công ty" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={2} sm={2} md={2}>
                                                                <Button
                                                                    type="link"
                                                                    danger
                                                                    style={{ width: '100%' }}
                                                                    onClick={() => remove(field.name)}
                                                                    title='Xóa công ty này'
                                                                    icon={<DeleteRowOutlined />}
                                                                >
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                <Row gutter={24}>
                                                    <Col xs={24} sm={24} md={24}>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <PlusOutlined /> Thêm công ty
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }}
                                </Form.List>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </Modal>
    )
}
export default ModalActionService;