import React, { useState, useCallback, useEffect } from 'react';
import {
    Modal, Row, Col, Form, Input, Select, Upload, message, Button, Avatar, InputNumber
} from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import JoditEditor from "jodit-react";
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import * as COMMON from '../../../config/common';
const { confirm } = Modal;
const ModalActionDoctor = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    /*================= Editor ============== */

    //define variable
    const [categoriesData, setCategoriesData] = useState([]);
    const [units, setUnits] = useState([]);
    const [cityData, setCityData] = useState([]);
    const handleCancel = useCallback(() => {
        form.resetFields();
        props.cancel();
    }, [form]);
    useEffect(() => {
        loadCity();
        loadCategoriesDoctor();
        //loadSpecialties();
        selected(props.data?.specialty);
        loadDoctor();
    }, [props.data]);
    const [doctorAvatar, setDoctorAvatar] = useState({
        filename: null,
        status: 'done-add',
        url: null,
        thumbUrl: null
    }
    );
    const beforeUploadAvatar = async file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Bạn chỉ được tải ảnh JPG/PNG!');
        }
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
            message.error('Hình ảnh phải nhỏ hơn 1MB!');
        }
        return isJpgOrPng && isLt1M;
    }
    const changeUploadAvatar = async info => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            const { response } = info.file;
            if (response && response.code === 200) {
                let avatarUp = { ...doctorAvatar };
                avatarUp = {
                    filename: response.data.filename,
                    status: 'done',
                    url: CONSTANT.STATIC_URL + 'tmp/' + response.data.filename,
                    thumbUrl: CONSTANT.STATIC_URL + 'tmp/' + response.data.filename,
                    code: 200
                }
                setDoctorAvatar(avatarUp);
            }
        }
    }

    /*================= Select ============== */
    // const onChangeSelect = (type) => async (value) => {
    //     switch (type) {
    //         case 'type':
    //             break;
    //         case 'area':
    //             setCountryData([]);
    //             setCityData([]);
    //             form.setFieldsValue({
    //                 country: null,
    //                 city: null
    //             })
    //             // load country
    //             await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${value}`)
    //                 .then(res => {
    //                     if (res && res.data && res.data.code === 200) {
    //                         setCountryData(res.data.data.docs);
    //                     }
    //                 });
    //             break;
    //         case 'country':
    //             setCityData([]);
    //             form.setFieldsValue({
    //                 city: null
    //             })
    //             // load city
    //             await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${value}`)
    //                 .then(res => {
    //                     if (res && res.data && res.data.code === 200) {
    //                         setCityData(res.data.data.docs);
    //                     }
    //                 });
    //             break;
    //         default:
    //             break;
    //     }
    // }
    const saveImage = async () => {
        if (doctorAvatar) {
            if (doctorAvatar.status === 'done') {
                if (doctorAvatar.code === 200) {
                    return await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                        filename: doctorAvatar.filename,
                        type: "doctor"
                    }).then(async res => {
                        if (res && res.data && res.data.code === 200) {
                            let avatar = { ...doctorAvatar };
                            avatar.url = res.data.data.url;
                            avatar.status = 'done-add';
                            setDoctorAvatar(avatar);
                            return res.data.data.url;
                        }
                    });
                }
            }
            else {
                return doctorAvatar.filename;
            }
        }
    };
    const onFinishFailed = async (values) => {
        message.error('Vui lòng nhập đầy đủ thông tin theo yêu cầu, cảm ơn !', 5);
    }
    // load Specialties
    // const loadSpecialties = async () => {
    //     await axiosInstance.get(`${CONSTANT.API.HOSPITAL.SPECIALTIES}?perPage=-1`)
    //         .then(res => {
    //             if (res && res.data && res.data.code === 200) {
    //                 setSpecialties(res.data.data.docs);
    //             }
    //         });
    // }
    // load category hospital
    const loadCategoriesDoctor = async () => {
        await axiosInstance.get(`${CONSTANT.API.DOCTOR.CATEGORY}?perPage=-1`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    setCategoriesData(res.data.data.docs);
                }
            });
    }
    // load Area
    const loadCity = async () => {
        await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=5e9ed13e45d6942f9e75f8c4`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    await setCityData(res.data.data.docs);
                }
            });
    }
    const loadDoctor = async () => {
        setLoading(true);
        setConfirmLoading(true);
        if (props.data?.doctorId) {
            await axiosInstance.get(`${CONSTANT.API.DOCTOR.DOCTOR}/${props.data.doctorId}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        const data = res.data.data;
                        form.setFieldsValue({
                            category: data.category ? data.category._id : null,
                            academic_rank: data?.academic_rank,
                            degree: data?.degree,
                            fullname: data?.fullname,
                            specialties: data.specialties && data.specialties.length > 0 ? data.specialties.map(i => i._id) : [],
                            practicing_certificate: data.practicing_certificate,
                            training_process: data.training_process,
                            working_process: data.working_process,
                            email: data.email,
                            phone: data.phone,
                            area: "5e02e341775aa01200bce0df",
                            country: "5e9ed13e45d6942f9e75f8c4",
                            city: data.city ? data.city._id : null,
                            training_places: data?.training_places,
                            home_office: data?.home_office,
                            specialty: props.data?.specialty,
                            unit: props.data?.unit,
                            regency: props.data?.regency,
                            sort: props.data?.sort,
                            status: "publish"
                            //status: data.status && data.status === "publish" ? "approve" : data.status
                        });
                        setDoctorAvatar({
                            filename: data ? data.image : null,
                            status: 'done-add',
                            url: CONSTANT.STATIC_URL + (data ? data.image : null),
                            thumbUrl: CONSTANT.STATIC_URL + (data ? data.image : null)
                        });
                    }
                    else {
                        message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!', 4)
                    }
                })
                .catch(error => message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!' + error.message, 5).then(() => props.history.push('/')))
        }
        else {
            setDoctorAvatar({
                filename: null,
                status: 'done-add',
                url: null,
                thumbUrl: null
            });
            form.setFieldsValue({ home_office: props.hospital?.name })
        }
        setLoading(false);
        setConfirmLoading(false);
    }
    const addDoctorExist = async (doctorId) => {
        setConfirmLoading(true);
        let dataSave = {
            action: 'insAddress',
            hospitalId: props.hospital._id,
            name: props.hospital.name,
            address: props.hospital.address
        };
        await axiosInstance.patch(`${CONSTANT.API.DOCTOR.DOCTOR}/${doctorId}`, dataSave)
            .then(async res => {
                if (res && res.data.data && res.data.code === 200) {
                    let dataSave = {
                        action: 'insDoctor',
                        doctorId: doctorId,
                        specialty: form.getFieldValue('specialty'),
                        unit: form.getFieldValue('unit'),
                        regency: form.getFieldValue('regency'),
                        sort: form.getFieldValue('sort')
                    }
                    await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospital?._id}`, dataSave)
                        .then(res1 => {
                            if (res1 && res1.data && res1.data.code === 200) {
                                message.success('Liên kết bác sĩ thành công !', 5);
                                form.resetFields();
                                props.cancel(true);
                            }
                            else {
                                message.error('Liên kết bác sĩ không thành công ! Vui lòng thử lại sau.', 5);
                            }
                        });
                }
                else {
                    message.error('Liên kết thất bại! Vui lòng thử lại sau.');
                }
                setConfirmLoading(false);
            }).catch(() => setConfirmLoading(false));
    }
    const selected = async (value) => {
        form.setFieldsValue({ unit: null });
        let loadUnits = [];
        if (props.data?.units) {
            for (const item of props.data.units) {
                if (item.specialty === value)
                    loadUnits.push(item);
            }
        }
        setUnits(loadUnits);
    }
    const onFinish = useCallback(async (values) => {
        setConfirmLoading(true);
        let specialtyId = values.specialty;
        values.image = await saveImage();
        values.is_active = true;
        values.status = "publish"
        if (!props.data?.doctorId) {
            values.area = '5e02e341775aa01200bce0df';
            values.country = '5e9ed13e45d6942f9e75f8c4';
            let dataRegister = {
                email: values.email,
                password: 'vivmedic',
                firstname: '',
                lastname: values.fullname,
                dob: null,
                sex: "other",
                dial_code: '84',
                phone: values.phone,
                role: "agency",
                created_by_hospital: true,
                is_doctor: true,
                is_active: true,
            };
            await axiosInstance.post(`${CONSTANT.API.USER.CHECK}`, { email: values.email })
                .then(async res0 => {
                    if (res0 && res0.data && res0.data.code === 200 && res0.data.data.exist) {
                        values.created_by = res0.data.data._id;
                        values.addresses = [{ _id: props.hospital?._id, name: props.hospital?.name, address: props.hospital?.address }];
                        await axiosInstance.get(`${CONSTANT.API.DOCTOR.DOCTOR}?created_by=${res0.data.data._id}`)
                            .then(async res1 => {
                                if (res1.data.data && res1.data.data.docs.length > 0) {
                                    let doctor = res1.data.data.docs[0];
                                    confirm({
                                        title: `${COMMON.createDoctorName(doctor?.academic_rank, doctor?.degree, doctor?.fullname, doctor?.category?.short_name)} đã sử dụng email này`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'Email đã được đăng ký bởi bác sĩ ! Bạn có muốn liên kết thông tin bác sĩ này với cơ sở y tế không ? Sau khi đồng ý bác sĩ sẽ tự động liên kết vào chuyên khoa và kết thúc nhập dữ liệu !',
                                        okText: "Đồng ý liên kết",
                                        cancelText: "Không đồng ý",
                                        onOk() {
                                            addDoctorExist(doctor._id);
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                                }
                                else {
                                    message.error('Email đã được đăng ký, vui lòng chọn email khác, cảm ơn !', 5);
                                }
                            })

                    }
                    else if (res0 && res0.data && res0.data.code === 200 && !res0.data.data.exist) {
                        await axiosInstance.post(`${CONSTANT.API.USER.REGISTER}`, dataRegister)
                            .then(async res => {
                                if (res && res.data && (res.data.code === 200)) {
                                    values.created_by = res.data.data.user._id;
                                    values.addresses = [{ _id: props.hospital?._id, name: props.hospital?.name, address: props.hospital?.address }];
                                    let specialtyDoctor;
                                    for (const item of props.data.specialties) {
                                        if (item._id === specialtyId) {
                                            specialtyDoctor = item.specialty._id;
                                            break;
                                        }
                                    }
                                    values.specialties = [specialtyDoctor];
                                    delete values.specialty;
                                    await axiosInstance.post(`${CONSTANT.API.DOCTOR.DOCTOR}`, values)
                                        .then(async res1 => {
                                            if (res1 && res1.data && res1.data.code === 200) {
                                                let dataSave = {
                                                    action: 'insDoctor',
                                                    doctorId: res1.data.data._id,
                                                    specialty: specialtyId,
                                                    unit: values.unit,
                                                    regency: values.regency,
                                                    sort: values.sort
                                                }
                                                await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospital?._id}`, dataSave)
                                                    .then(res2 => {
                                                        if (res2 && res2.data && res2.data.code === 200) {
                                                            message.success('Thêm mới bác sĩ thành công !', 5);
                                                            form.resetFields();
                                                            props.cancel(true);
                                                        }
                                                        else {
                                                            message.error('Thêm bác sĩ không thành công ! Vui lòng thử lại sau.', 5);
                                                        }
                                                    });
                                            }
                                            else {
                                                message.error('Thêm bác sĩ không thành công ! Vui lòng thử lại sau.', 5);
                                            }
                                        });
                                }
                                else {
                                    message.error('Thêm bác sĩ không thành công ! Vui lòng thử lại sau.', 5);
                                }
                            }).catch(error => {
                                if (error.code === 409)
                                    message.warning('Thêm bác sĩ không thành công ! Vui lòng thử lại sau !', 5);
                                console.log(error);
                            });

                    }
                }).catch(error => {
                    if (error.code === 409)
                        message.error('Thêm mới bác sĩ không thành công ! Vui lòng thử lại sau !', 5);
                    console.log(error);
                });

        }
        else {
            let dataSave = {
                action: 'updDoctor',
                doctorId: props.data?.doctorId,
                specialty: values.specialty,
                unit: values.unit,
                regency: values.regency,
                sort: values.sort
            }
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospital?._id}`, dataSave)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        delete values.specialty;
                        values.academic_rank = values.academic_rank ? values.academic_rank : null;
                        values.degree = values.degree ? values.degree : null;
                        await axiosInstance.patch(`${CONSTANT.API.DOCTOR.DOCTOR}/${props.data?.doctorId}`, values)
                            .then(res2 => {
                                if (res2 && res2.data && res2.data.code === 200) {
                                    message.success('Cập nhật thông tin thành công. Nội dung của bạn sẽ được kiểm duyệt trước khi được công bố đến khách truy cập !', 7)
                                    form.resetFields();
                                    props.cancel(true);
                                }
                                else {
                                    message.error('Cập nhật thông tin thất bại! Vui lòng thử lại sau.', 5);
                                }
                            });
                    }
                    else {
                        message.error('Cập nhật thông tin thất bại! Vui lòng thử lại sau.', 5);
                    }
                }).catch(error => {
                    if (error.code === 409)
                        message.error('Cập nhật thông tin bác sĩ không thành công ! Vui lòng thử lại sau !', 5);
                    console.log(error);
                });

        }
        setConfirmLoading(false);
    }, [props, doctorAvatar]);
    return (
        <Modal title={props.data && props.data.doctorId ? "Sửa thông tin bác sĩ" : "Thêm bác sĩ chuyên môn"}
            visible={props.visible}
            onOk={form.submit}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Lưu thông tin"
            confirmLoading={confirmLoading}
            className="modal-book-facility"
            width={'1000px'}
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    category: null,
                    academic_rank: null,
                    degree: null,
                    fullname: "",
                    specialties: [],
                    practicing_certificate: '',
                    training_process: '',
                    working_process: '',
                    home_office: '',
                    image: null,
                    area: '5e02e341775aa01200bce0df',
                    country: '5e9ed13e45d6942f9e75f8c4',
                    city: null,
                    total_vote: "",
                    status: "publish"
                }}>
                <Row gutter={24}>
                    <Col xs={24} sm={10} md={3}>
                        <Form.Item>
                            <Avatar
                                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                src={doctorAvatar && doctorAvatar.url}
                            />
                            <ImgCrop
                                rotate
                                modalTitle="Sửa ảnh"
                                modalOk="Đồng ý"
                                aspect={800 / 800}
                                quality={0.8}
                                modalWidth={800}
                            >
                                <Upload
                                    name="file"
                                    action={`${CONSTANT.API.EXTRA.UPLOAD_AVATAR}`}
                                    showUploadList={false}
                                    beforeUpload={beforeUploadAvatar}
                                    onChange={changeUploadAvatar}
                                    headers={{
                                        'X-TK': CONSTANT.API.KEY
                                    }}
                                >
                                    <Button type="link">
                                        {loading ? <LoadingOutlined /> : 'Chọn ảnh'}
                                    </Button>
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={10} md={21}>
                        <Row gutter={24}>
                            <Col xs={24} sm={10} md={6}>
                                <Form.Item
                                    label="Chức danh chuyên môn"
                                    name="category"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn chức danh chuyên môn !' }
                                    ]}
                                >
                                    <Select
                                        placeholder="Chọn chức danh chuyên môn"
                                        optionFilterProp="children"
                                        style={{ width: '100%' }}>
                                        {
                                            categoriesData.length > 0 &&
                                            categoriesData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={14} md={5}>
                                <Form.Item
                                    label="Học hàm"
                                    name="academic_rank"
                                >
                                    <Select
                                        placeholder="Học hàm"
                                        optionFilterProp="children"
                                        allowClear
                                        style={{ width: '100%' }}>
                                        <Select.Option key='hh1' value="GS">Giáo sư</Select.Option>
                                        <Select.Option key='hh2' value="PGS">Phó Giáo sư</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={14} md={4}>
                                <Form.Item
                                    label="Học vị"
                                    name="degree"
                                >
                                    <Select
                                        placeholder="Chọn học vị"
                                        optionFilterProp="children"
                                        allowClear
                                        style={{ width: '100%' }}>
                                        <Select.Option key='hv1' value="TS">Tiến sĩ</Select.Option>
                                        <Select.Option key='hv2' value="ThS">Thạc sĩ</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={14} md={9}>
                                <Form.Item
                                    label="Tên đầy đủ"
                                    name="fullname"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập tên đầy đủ !' },
                                        {
                                            type: 'string', max: 100, message: "Tên đầy đủ tối đa 100 ký tự !"
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nhập tên đầy đủ" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6} md={6}>
                                <Form.Item
                                    name="specialty"
                                    label="Chuyên khoa"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn chuyên khoa !' }
                                    ]}>
                                    <Select placeholder="Chọn chuyên khoa" onChange={(value) => selected(value)}>
                                        {
                                            props.data?.specialties.length > 0 &&
                                            props.data.specialties.map(item => <Select.Option key={item._id}>{item.name_other}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Col xs={24} sm={12} md={9}>
                                <Form.Item
                                    name="unit"
                                    label="Đơn vị"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn đơn vị !' }
                                    ]}>
                                    <Select placeholder="Chọn đơn vị">
                                        {
                                            units && units.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={9}>
                                <Form.Item
                                    label="Chức vụ"
                                    name="regency"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        {
                                            required: true, message: 'Vui lòng nhập chức vụ!',
                                            type: 'string', max: 60, message: "Chức vụ tối đa 60 ký tự !"
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nhập số chức vụ" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item
                            label="Số CCHN khám chữa bệnh "
                            name="practicing_certificate"
                            required tooltip="Trường bắt buộc nhập"
                            rules={[
                                {
                                    required: true, message: 'Vui lòng nhập Số chứng chỉ!',
                                    type: 'string', max: 60, message: "Số chứng chỉ tối đa 60 ký tự !"
                                },
                            ]}
                        >
                            <Input placeholder="Nhập số chứng chỉ hành nghề" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item label="Email" name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Email không đúng định dạng',
                                },
                                {
                                    required: true, message: 'Vui lòng nhập email !',
                                },
                                {
                                    type: 'string', max: 100, message: "Email không hợp lệ, tối đa 100 ký tự !"
                                }
                            ]}
                        >
                            <Input placeholder="Nhập email" /></Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item label="Điện thoại" name="phone"
                            rules={[
                                {
                                    type: 'string', max: 13, message: "Số điện thoại tối đa 13 số !"
                                }
                            ]}>
                            <Input placeholder="Nhập số điện thoại" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label="Thành phố"
                            name="city"
                            required tooltip="Trường này bắt buộc chọn"
                            rules={[
                                { required: true, message: 'Vui lòng chọn thành phố!' }
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Chọn thành phố"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                                onFocus={COMMON.offAutoCompleteSelect()}
                            >
                                {
                                    cityData.length > 0 &&
                                    cityData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={13}>
                        <Form.Item
                            label="Nơi công tác"
                            name="home_office"
                            required tooltip="Trường bắt buộc nhập"
                            rules={[
                                {
                                    required: true, message: 'Vui lòng nhập nơi công tác!',
                                    type: 'string', max: 150, message: "Nơi công tác tối đa 150 ký tự !"
                                },
                            ]}
                        >
                            <Input placeholder="Nhập nơi công tác hiện tại" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={5}>
                        <Form.Item
                            label="Thứ tự sắp xếp"
                            name='sort'
                            rules={[
                                { required: true, message: 'Vui lòng nhập thứ tự sắp xếp' },
                                {
                                    type: 'number', max: 1000, message: "Thứ tự sắp xếp tối đa 1000 !"
                                }
                            ]}>
                            <InputNumber placeholder="Nhập thứ tự sắp xếp" min={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\\s?|(,*)/g, '')}
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                        <Form.Item
                            label="Quá trình đào tạo"
                            name="training_process"
                            tooltip="Quá trình đào tạo"

                        >
                            <JoditEditor
                                config={{
                                    height: 300, buttons: [
                                        "bold", "italic", "underline", "strikethrough", "|", "ul", "ol", "|", "center", "left", "right", "justify", "|", "link"
                                    ],
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Quá trình công tác"
                            name="working_process"
                            tooltip="Quá trình công tác"
                        >
                            <JoditEditor
                                config={{
                                    height: 300, buttons: [
                                        "bold", "italic", "underline", "strikethrough", "|", "ul", "ol", "|", "center", "left", "right", "justify", "|", "link"
                                    ]
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Form.Item label="Trạng thái" name="status">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}>
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item> */}
            </Form>
        </Modal>
    )
}
export default ModalActionDoctor;