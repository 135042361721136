import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button, InputNumber, Popconfirm, Modal, Spin, Radio, Divider, TimePicker
} from 'antd';
import { HomeOutlined, UploadOutlined, SaveOutlined, DeleteRowOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import JoditEditor from "jodit-react";
import { getAccessToken } from 'axios-jwt';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import * as COMMON from '../../config/common';
import moment from 'moment';
const status = [
    { value: 'approve', label: 'Cần kiểm duyệt' },
    { value: 'draft', label: 'Bản nháp' },
    { value: 'publish', label: 'Công khai' },
];
const configEditor = {
    height: 300,
    language: 'vi',
    i18n: {
        vi: {
            'Type something': 'Nhập nội dung...',
            'Paste as HTML': 'Dán dưới dạng HTML',
            'Your code is similar to HTML. Keep as HTML?': 'Mã của bạn tương tự như HTML. Giữ dưới dạng HTML?',
            'Keep': 'Giữ',
            'Insert as Text': 'Chèn dưới dạng văn bản',
            'Insert only Text': 'Chỉ chèn văn bản',
            'Cancel': 'Hủy bỏ',
            'Drop image': 'Kéo thả hình ảnh',
            'or click': 'hoặc nhấp vào',
            'Upload': 'Tải lên',
        }
    },
    uploader: {
        url: `${CONSTANT.BASE_API}media/filemanager/uploads`,
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        },
        data: { type: 'hospital_content' },
        filesVariableName: () => {
            return 'files'
        }
    }
}
const { Option, OptGroup } = Select;
const ActionService = (props) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    /*================= Editor ============== */

    //define variable
    const [categoriesData, setcategoriesData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [workPlace, setWorkPlace] = useState([]);
    /*================= Images ============== */
    const [imageList, setImageList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');

    const onChangeImages = ({ fileList }) => {
        setImageList(fileList);
    }
    const onTypeChange = e => {
        setWorkPlace(e.target.value);
    };
    const onPreviewImages = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        setPreviewImage(src);
        setPreviewVisible(true);
        let _previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
        setPreviewTitle(_previewTitle);

    };
    const onRemove = async file => {

        // remove 
    }
    // save image
    const saveImage = async () => {
        let arrImage = [];
        if (imageList.length > 0) {
            for (const item of imageList) {
                if (item.status === 'done') {
                    if (item.response.code === 200 && item.response.data && item.response.data.filename) {
                        let idImage = await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                            filename: item.response.data.filename,
                            type: "service_medical"
                        })
                            .then(async res => {

                                if (res && res.data && res.data.code === 200) {
                                    let filename = res.data.data.url;
                                    if (typeof filename !== 'undefined') {
                                        return await axiosInstance.post(`${CONSTANT.API.SERVICE.IMAGE}`, {
                                            filename
                                        })
                                            .then(mRes => {
                                                if (mRes && mRes.data && mRes.data.code === 200) {
                                                    const { data } = mRes.data;
                                                    if (data) {
                                                        return data._id;
                                                    }
                                                }
                                            }
                                            ).catch(error => {console.log(error.message)});
                                    }
                                }
                            }).catch(error => {console.log(error.message)});
                        arrImage.push(idImage);
                    }
                }
                else {
                    arrImage.push(item.uid);
                }
            }
        }
        return arrImage;
    };

    /*================= Select ============== */
    const onChangeSelect = (type) => async (value) => {
        switch (type) {
            case 'type':
                break;
            case 'area':
                setCountryData([]);
                setCityData([]);
                form.setFieldsValue({
                    country: null,
                    city: null,
                    district: null
                })
                // load country
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCountryData(res.data.data.docs);
                        }
                    }).catch(error => {console.log(error.message)});
                break;
            case 'country':
                setCityData([]);
                form.setFieldsValue({
                    city: null,
                    district: null
                })
                // load city
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCityData(res.data.data.docs);
                        }
                    }).catch(error => {console.log(error.message)});
                break;
            case 'city':
                setDistrictData([]);
                form.setFieldsValue({
                    district: null
                })
                // load city
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.DISTRICT}?perPage=-1&city=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setDistrictData(res.data.data.docs);
                        }
                    }).catch(error => {console.log(error.message)});
                break;
            default:
                break;
        }
    }

    // submit
    const onFinish = async (values) => {
        setLoading(true);
        let images = await saveImage();
        values.images = images;
        //Action service
        if (!id) {
            await axiosInstance.post(`${CONSTANT.API.SERVICE.SERVICE}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Thêm mới dịch vụ thành công !').then(() => props.history.push('/services'));
                    }
                    else {
                        message.error('Thêm mới dịch vụ thất bại! Vui lòng thử lại sau.');
                        setLoading(false);
                    }
                    
                }).catch(error => {console.log(error.message);setLoading(false);});
        }
        else {
            await axiosInstance.patch(`${CONSTANT.API.SERVICE.SERVICE}/${id}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Sửa thông tin dịch vụ thành công!')
                            .then(() => props.history.push('/services'))
                    }
                    else {
                        message.error('Sửa thông tin dịch vụ thất bại! Vui lòng thử lại sau.');
                        setLoading(false);
                    }
                    
                }).catch(error => {console.log(error.message);setLoading(false);});
        }
    };

    // effect
    useEffect(() => {
        // load area
        loadArea();
        // load categorys
        loadCategory();
        //Load service
        if (id && id !== '') {
            loadService();
        }
    }, []);

    // load Area
    const loadArea = async () => {
        await axiosInstance.get(`${CONSTANT.API.DESTINATION.AREA}?perPage=-1&is_parent=true`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setAreaData(res.data.data.docs);
                }
            }).catch(error => {console.log(error.message);setLoading(true);});
    }

    // load category
    const loadCategory = async () => {
        await axiosInstance.get(`${CONSTANT.API.SERVICE.CATEGORY}?perPage=-1`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setcategoriesData(res.data.data.docs);
                }
            }).catch(error => {console.log(error.message);setLoading(true);});;
    }
    const loadService = async () => {
        setLoading(true);
        await axiosInstance.get(`${CONSTANT.API.SERVICE.SERVICE}/${id}`)
            .then(async res => {
                if (res.data.data && res.data.data.area && res.data.data.area._id) {
                    await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${res.data.data.area._id}`)
                        .then(async res1 => {
                            if (res1 && res1.data && res1.data.code === 200) {
                                await setCountryData(res1.data.data.docs);
                            }

                        });
                }
                // load city by country if exist country
                if (res.data.data && res.data.data.area && res.data.data.country._id) {
                    // load city
                    await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${res.data.data.country._id}`)
                        .then(async res2 => {
                            if (res2 && res2.data && res2.data.code === 200) {
                                await setCityData(res2.data.data.docs);
                            }
                        });
                }
                // load districts by city if exist country
                if (res.data.data && res.data.data.area && res.data.data.city._id) {
                    // load district
                    await axiosInstance.get(`${CONSTANT.API.DESTINATION.WARD}?perPage=-1&city=${res.data.data.city._id}`)
                        .then(async res3 => {
                            if (res3 && res3.data && res3.data.code === 200) {
                                await setDistrictData(res3.data.data.docs);
                            }
                        });
                }
                if (res && res.data && res.data.code === 200) {
                    const { data } = res.data;
                    data.time_apply.forEach(item => {
                        item.start_time = moment(item.start_time);
                        item.end_time = moment(item.end_time);
                    });
                    form.setFieldsValue({
                        _id: data._id,
                        name: data?.name,
                        time: data?.time,
                        area: data.area ? data.area._id : '',
                        country: data?.country._id,
                        city: data?.city ? data.city._id : '',
                        district: data?.district ? data.district._id : '',
                        price: data?.price,
                        overtime_fee: data?.overtime_fee,
                        category: data?.category ? data.category._id : '',
                        overview: data?.overview,
                        what_included: data?.what_included,
                        additional_info: data?.additional_info,
                        cancellation_policy: data?.cancellation_policy,
                        // is_active: data.is_active ? 'true' : 'false',
                        work_place: data?.work_place,
                        address_service: data?.address_service,
                        area_apply: data?.area_apply,
                        time_apply: data?.time_apply,
                        status: "publish"
                        //status: data.status && data.status === "publish" ? "approve" : data.status
                    });
                    setWorkPlace(data.work_place);
                    if (res.data.data.images && res.data.data.images.length > 0) {
                        let images = [];
                        for (let i = 0; i < res.data.data.images.length; i++) {
                            let image = res.data.data.images[i];
                            if (image) {
                                images = images.concat({
                                    uid: image._id,
                                    name: CONSTANT.STATIC_URL + image.filename,
                                    status: 'done-add',
                                    url: CONSTANT.STATIC_URL + image.filename,
                                    thumbUrl: CONSTANT.STATIC_URL + image.filename
                                });
                            }
                        }
                        setImageList(images);
                    }
                }
            })
            .catch(error => message.warning('Dịch vụ không tồn tại!' + error.message, 2.5).then(() => props.history.push('/services')))
        setLoading(false);
    }
    const onChangeRenderTime = async (index) => {
        const worklist = form.getFieldValue('time_apply');
        for (let i = 0; i < worklist.length; i++) {
            if (i !== index) {
                let startTime = worklist[i]?.start_time?.hour() * 60 + worklist[i]?.start_time?.minutes();
                let endTime = worklist[i].end_time?.hour() * 60 + worklist[i]?.end_time?.minutes();
                let startCheck = worklist[index]?.start_time?.hour() * 60 + worklist[index]?.start_time?.minutes();
                let endCheck = worklist[index]?.start_time?.hour() * 60 + worklist[index]?.start_time?.minutes();
                let check1 = (startTime <= startCheck && startCheck <= endTime) || (startTime <= endCheck && endCheck <= endTime);
                if (check1) {
                    message.warning('Khoảng thời gian lập lịch trong ngày bị trùng. Vui lòng kiểm tra lại !', 7);
                    return;
                }
            }
        }
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/services">Dịch vụ</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {id && id !== '' ? 'Sửa thông tin' : 'Thêm mới'}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        name: "",
                        time: "",
                        promotion: "",
                        area: null,
                        country: null,
                        city: null,
                        district: null,
                        total_vote: "",
                        price: null,
                        overview: '',
                        what_included: '',
                        additional_info: '',
                        cancellation_policy: '',
                        category: null,
                        images: null,
                        type: "default",
                        is_active: true,
                        status: "publish"
                    }}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Form.Item
                                label="Tên dịch vụ"
                                name="name"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên dịch vụ!' },
                                ]}
                            >
                                <Input placeholder="Nhập tên dịch vụ" />
                            </Form.Item>
                            <Form.Item label="Nơi thực hiện dịch vụ" name="work_place" rules={[
                                { required: true, message: 'Vui lòng chọn nơi thực hiện!' },
                            ]}>
                                <Radio.Group value={workPlace} onChange={onTypeChange}>
                                    <Radio value='at_guest'>Tại nhà khách hàng</Radio>
                                    <Radio value='at_agency'>Tại cơ sở dịch vụ</Radio>
                                    <Radio value='at_both'>Theo tùy chọn khách hàng</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {
                                workPlace != 'at_guest' &&
                                <Form.Item
                                    label="Địa chỉ cơ sở"
                                    name="address_service"
                                    required tooltip="Trường bắt buộc nhập, là nơi khách hàng đến để sử dụng dịch vụ !"
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập địa chỉ cơ sở dịch vụ!' },
                                    ]}
                                >
                                    <Input placeholder="Nhập địa chỉ cơ sở" />
                                </Form.Item>
                            }
                            {
                                (workPlace == 'at_guest' || workPlace == 'at_both') && <>
                                    <Divider>Danh sách khu vực áp dụng dịch vụ tại nhà</Divider>
                                    <Row gutter={24}>
                                        <Col xs={12} sm={14} md={16}>
                                            <div className="ant-col ant-form-item-label">
                                                <label className="ant-form-item-required">Tên khu vực</label>
                                            </div>
                                        </Col>
                                        <Col xs={10} sm={8} md={6}>
                                            <div className="ant-col ant-form-item-label"><label className="ant-form-item-required">Chi phí đi lại</label></div>
                                        </Col>
                                    </Row>
                                    <Form.List name="area_apply">
                                        {(fields, { add, remove }) => {
                                            return (
                                                <>
                                                    {
                                                        fields.map((field, index) => (
                                                            <Row gutter={24} key={field.key}>
                                                                <Col xs={18} sm={18} md={16}>
                                                                    <Form.Item
                                                                        name={[index, 'name']}
                                                                        required
                                                                        rules={[
                                                                            {
                                                                                required: true, message: 'Vui lòng nhập tên khu vực áp dụng',
                                                                                type: 'string', max: 100, message: "Tên khu vực áp dụng tối đa 100 ký tự !"
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Nhập tên khu vực áp dụng" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={18} sm={18} md={6}>
                                                                    <Form.Item
                                                                        name={[index, 'fee']}
                                                                        rules={[
                                                                            { required: true, message: 'Vui lòng nhập phí đi lại' },
                                                                            {
                                                                                type: 'number', max: 10000000, message: "Phí đi lại tối đa 10.000.000 !"
                                                                            }
                                                                        ]}>
                                                                        <InputNumber placeholder="Nhập phí đi lại" min={0}
                                                                            formatter={value => `₫ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                            parser={value => value.replace('₫', '').replace(/\\s?|(,*)/g, '')}
                                                                            style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={2} sm={2} md={2}>
                                                                    <Popconfirm title="Bạn muốn xóa khu vực này ?" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => remove(field.name)}>
                                                                        <Button
                                                                            type="link"
                                                                            danger
                                                                            style={{ width: '100%' }}
                                                                            title='Xóa địa chỉ khám này'
                                                                            icon={<DeleteRowOutlined />}
                                                                        >
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    <Row gutter={24}>
                                                        <Col xs={24} sm={24} md={24}>
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() => add()}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <PlusOutlined /> Thêm khu vực áp dụng
                                                                </Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }}
                                    </Form.List></>
                            }
                            <Divider>Cài đặt thời gian làm việc</Divider>
                            <Row gutter={24}>
                                <Col xs={10} sm={10} md={4}>
                                    <div className="ant-col ant-form-item-label">
                                        <label className="ant-form-item-required">Từ giờ</label>
                                    </div>
                                </Col>
                                <Col xs={10} sm={10} md={4}>
                                    <div className="ant-col ant-form-item-label">
                                        <label className="ant-form-item-required">Đến giờ</label>
                                    </div>
                                </Col>
                                <Col xs={10} sm={10} md={10}>
                                    <div className="ant-col ant-form-item-label">
                                        <label className="ant-form-item-required">Phương thức làm việc</label>
                                    </div>
                                </Col>
                            </Row>
                            <Form.List name="time_apply">
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            {
                                                fields.map((field, index) => (
                                                    <Row gutter={24} key={field.key}>
                                                        <Col xs={10} sm={10} md={4}>
                                                            <Form.Item
                                                                name={[index, 'start_time']}
                                                                required tooltip="Vui lòng chọn trường này"
                                                                rules={[
                                                                    { required: true, message: 'Vui lòng chọn' },
                                                                ]}
                                                            >
                                                                <TimePicker
                                                                    style={{ width: '100%' }}
                                                                    format="HH:mm"
                                                                    onChange={() => { onChangeRenderTime(index) }}
                                                                    showNow={false} placeholder="Từ giờ" minuteStep={5} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={10} sm={10} md={4}>
                                                            <Form.Item
                                                                name={[index, 'end_time']}
                                                                required tooltip="Vui lòng chọn trường này"
                                                                rules={[
                                                                    { required: true, message: 'Vui lòng chọn' },
                                                                ]}
                                                            >
                                                                <TimePicker style={{ width: '100%' }}
                                                                    format="HH:mm"
                                                                    showNow={false}
                                                                    onChange={() => { onChangeRenderTime(index) }}
                                                                    placeholder="Đến giờ"
                                                                    minuteStep={5} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} sm={14} md={14}>
                                                            <Form.Item name={[index, 'method']}
                                                                rules={[
                                                                    { required: true, message: 'Phương thức làm việc' },
                                                                ]}>
                                                                <Radio.Group>
                                                                    <Radio value={true}>Trong giờ hành chính</Radio>
                                                                    <Radio value={false}>Ngoài giờ hành chính</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={2} md={2}>
                                                            <Popconfirm title="Bạn muốn xóa khu vực này ?" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => remove(field.name)}>
                                                                <Button
                                                                    type="link"
                                                                    danger
                                                                    style={{ width: '100%' }}
                                                                    title='Xóa cài đặt'
                                                                    icon={<DeleteRowOutlined />}
                                                                >
                                                                </Button>
                                                            </Popconfirm>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            <Row gutter={24}>
                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <PlusOutlined /> Thêm cài đặt giờ làm việc
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }}
                            </Form.List>
                            <Form.Item
                                name="overview"
                                label="Tổng quan"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập mô tả tổng quan về dịch vụ' },
                                ]}>
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                            <Form.Item
                                label="Dịch vụ bao gồm"
                                name="what_included"
                            >
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                            <Form.Item label="Thông tin khác"
                                name="additional_info"
                            >
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                            <Form.Item
                                label="Chính sách hủy"
                                name="cancellation_policy"
                            >
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            {/* Begin Images */}
                            <Form.Item label="Hình ảnh">
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={800 / 600}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        listType="picture"
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                        fileList={imageList}
                                        onPreview={onPreviewImages}
                                        onChange={onChangeImages}
                                        headers={{
                                            'X-TK': CONSTANT.API.KEY
                                        }}
                                        onRemove={onRemove}
                                    >
                                        {
                                            imageList.length < 15 &&
                                            <Button icon={<UploadOutlined />}>Chọn ảnh tải lên</Button>
                                        }
                                    </Upload>
                                </ImgCrop>
                                <Modal
                                    visible={previewVisible}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={() => setPreviewVisible(false)}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            {/* End Images */}
                            <Form.Item label="Khu vực"
                                name="area"
                                required tooltip="Khu vực: Châu Á, Châu Âu, Châu Mỹ"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn khu vực!' }
                                ]}>
                                <Select
                                    placeholder="Chọn khu vực"
                                    onChange={onChangeSelect('area')}
                                    style={{ width: '100%' }}>
                                    {
                                        areaData && areaData.length > 0 &&
                                        areaData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Quốc gia"
                                name="country"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn quốc gia!' }
                                ]}>
                                <Select
                                    showSearch
                                    placeholder="Chọn quốc gia"
                                    optionFilterProp="children"
                                    onChange={onChangeSelect('country')}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}>
                                    {
                                        countryData && countryData.length > 0 &&
                                        countryData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Thành phố"
                                name="city"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn thành phố!' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn thành phố"
                                    optionFilterProp="children"
                                    onChange={onChangeSelect('city')}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}
                                >
                                    {
                                        cityData && cityData.length > 0 &&
                                        cityData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Quận/huyện"
                                name="district"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn quận huyện !' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn quận huyện"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}
                                >
                                    {
                                        districtData && districtData.length > 0 &&
                                        districtData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Danh mục"
                                tooltip="Chọn danh mục của dịch vụ"
                                name="category"
                                placeholder="Chọn danh mục của dịch vụ"
                                required
                                rules={[
                                    { required: true, message: 'Vui lòng chọn danh mục của dịch vụ !' }
                                ]}
                            >
                                <Select
                                    placeholder="Vui lòng chọn danh mục"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        categoriesData && categoriesData.length > 0 && categoriesData.map(item =>
                                            <OptGroup key={item._id} label={item.name}>
                                                {
                                                    item.childs && item.childs.map(child => <Option key={child._id}>{'   └ ' + child.name}</Option>)
                                                }
                                            </OptGroup>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Giá"
                                name="price"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập giá' },
                                ]}

                            >
                                <InputNumber
                                    placeholder="Nhập giá"
                                    formatter={value => `₫ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace('₫', '').replace(/\\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}
                                    min={0} />
                            </Form.Item>
                            <Form.Item
                                label="Phí làm ngoài giờ"
                                name="overtime_fee"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập phí làm ngoài giờ' },
                                ]}

                            >
                                <InputNumber
                                    placeholder="Nhập phí làm ngoài giờ"
                                    formatter={value => `₫ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace('₫', '').replace(/\\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}
                                    min={0} />
                            </Form.Item>
                            <Form.Item label="Trạng thái" name="status">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}>
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />}
                                htmlType="submit" loading={loading}
                            >
                                Lưu thông tin
                            </Button>
                        </div>
                    </div>
                </Form>

            </Layout.Content>

        </div>
    )

}

export default ActionService;
