import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button, Modal, Spin, DatePicker, AutoComplete,
} from 'antd';
import { HomeOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
// import Autocomplete from "react-google-autocomplete";
import JoditEditor from "jodit-react";
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import * as COMMON from '../../config/common';
import moment from 'moment';
import axios from 'axios';
import { getAccessToken } from 'axios-jwt';
import ModalShowMap from '../../components/modal-show-map';
const status = [
    { value: 'approve', label: 'Cần kiểm duyệt' },
    { value: 'draft', label: 'Bản nháp' },
];
const configEditor = {
    height: 300,
    language: 'vi',
    i18n: {
        vi: {
            'Type something': 'Nhập nội dung...',
            'Paste as HTML': 'Dán dưới dạng HTML',
            'Your code is similar to HTML. Keep as HTML?': 'Mã của bạn tương tự như HTML. Giữ dưới dạng HTML?',
            'Keep': 'Giữ',
            'Insert as Text': 'Chèn dưới dạng văn bản',
            'Insert only Text': 'Chỉ chèn văn bản',
            'Cancel': 'Hủy bỏ',
            'Drop image': 'Kéo thả hình ảnh',
            'or click': 'hoặc nhấp vào',
            'Upload': 'Tải lên',
        }
    },
    uploader: {
        url: `${CONSTANT.BASE_API}media/filemanager/uploads`,
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        },
        data: { type: 'hospital_content' },
        filesVariableName: () => {
            return 'files'
        }
    }
}
const InfoHospital = (props) => {
    const [form] = Form.useForm();
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);
    /*================= Editor ============== */

    //define variable
    const [typeData, setTypeData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [loc, setLoc] = useState(null);
    /*================= Images ============== */
    const [imageList, setImageList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [addressHereMaps, setAddressHereMaps] = useState(null);
    const [isModalMapVisible, setIsModalMapVisible] = useState(false);
    const onChangeImages = ({ fileList }) => {
        setImageList(fileList);
    }
    const onPreviewImages = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        setPreviewImage(src);
        setPreviewVisible(true);
        let _previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
        setPreviewTitle(_previewTitle);

    };
    const onRemove = async file => {
        // remove 
    }
    // save image
    const saveImage = async () => {
        let arrImage = [];
        if (imageList.length > 0) {
            for (const item of imageList) {
                if (item.status === 'done') {
                    if (item.response.code === 200 && item.response.data && item.response.data.filename) {
                        let idImage = await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                            filename: item.response.data.filename,
                            type: "hospital"
                        })
                            .then(async res => {
                                if (res && res.data && res.data.code === 200) {
                                    let filename = res.data.data.url;
                                    if (typeof filename !== 'undefined') {
                                        return await axiosInstance.post(`${CONSTANT.API.HOSPITAL.IMAGE}`, {
                                            filename
                                        })
                                            .then(mRes => {
                                                if (mRes && mRes.data && mRes.data.code === 200) {
                                                    const { data } = mRes.data;
                                                    if (data) {
                                                        return data._id;
                                                    }
                                                }
                                            }
                                            )
                                    }
                                }
                            });
                        arrImage.push(idImage);
                    }
                }
                else {
                    arrImage.push(item.uid);
                }
            }
        }
        return arrImage;
    };

    /*================= Select ============== */
    const onChangeSelect = (type) => async (value) => {
        switch (type) {
            case 'type':
                break;
            case 'area':
                setCountryData([]);
                setCityData([]);
                form.setFieldsValue({
                    country: null,
                    city: null,
                    district: null
                })
                // load country
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCountryData(res.data.data.docs);
                        }
                    });
                break;
            case 'country':
                setCityData([]);
                form.setFieldsValue({
                    city: null,
                    district: null
                })
                // load city
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCityData(res.data.data.docs);
                        }
                    });
                break;
            case 'city':
                setDistrictData([]);
                form.setFieldsValue({
                    district: null
                })
                // load district
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.DISTRICT}?perPage=-1&city=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setDistrictData(res.data.data.docs);
                        }
                    });
                break;
            default:
                break;
        }
    }
    const onFinishFailed = async (values) => {
        message.error('Vui lòng nhập đầy đủ thông tin theo yêu cầu, cảm ơn !', 5);
    }
    // submit
    const onFinish = async (values) => {
        values.images = await saveImage();
        values.loc = loc;
        values.is_active = true;
        values.status = "publish";
        if (!id) {
            await axiosInstance.post(`${CONSTANT.API.HOSPITAL.HOSPITAL}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        //message.success('Cập nhật thông tin thành công. Nội dung của bạn sẽ được kiểm duyệt trước khi được công bố đến khách truy cập !')
                        message.success('Cập nhật thông tin thành công.')
                            .then(async () => {
                                await setId(res.data.data._id);
                                loadHospital();
                            })
                    }
                    else {
                        message.error('Cập nhật thông tin thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
        else {
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${id}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        //message.success('Cập nhật thông tin thành công. Nội dung của bạn sẽ được kiểm duyệt trước khi được công bố đến khách truy cập !')
                        message.success('Cập nhật thông tin thành công.')
                            .then(() => loadHospital())
                    }
                    else {
                        message.error('Cập nhật thông tin thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
    };

    // effect
    useEffect(() => {
        // load area
        loadArea();
        // load type
        loadTypeHospital();
        //Load hospital
        loadHospital();
    }, []);

    // load category hospital
    const loadTypeHospital = async () => {
        await axiosInstance.get(`${CONSTANT.API.HOSPITAL.TYPE}?perPage=-1`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    setTypeData(res.data.data.docs);
                }
            }).catch(error => message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!' + error.message, 5));
    }
    // load Area
    const loadArea = async () => {
        await axiosInstance.get(`${CONSTANT.API.DESTINATION.AREA}?perPage=-1&is_parent=true`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setAreaData(res.data.data.docs);
                }
            }).catch(error => message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!' + error.message, 5));
    }

    // load Address
    const loadAddressHereMap = async (query) => {
        await axios.get('https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json', {
            'params': {
                'apiKey': 'VqOELDbMHoG-Sf9ygZqSHKnrBGcH1m4PcV0xbjCIHqM',
                'country': 'VNM',
                'query': query,
            }
        }).then(response => {
            if (response && response.data.suggestions) {
                let opts = [];

                for (const obj of response.data.suggestions) {
                    debugger;
                    let array = [];
                    if (obj.address.street) array.push(obj.address.houseNumber ? obj.address.houseNumber + ' ' + obj.address.street : obj.address.street);
                    if (obj.address.district) array.push(obj.address.district);
                    if (obj.address.city) array.push(obj.address.city);
                    if (obj.address.county) array.push(obj.address.county);
                    if (obj.address.country) array.push(obj.address.country);
                    opts.push({ value: array.join(', '), id: obj.locationId });
                }
                setAddressHereMaps(opts);
            }
            else {
                setAddressHereMaps([]);
            }

        });
    }
    const onSelectAddress = async (value, option) => {
        await axios.get('https://geocoder.api.here.com/6.2/geocode.json', {
            params: {
                'app_id': 'erWElBPTdcSVJhWkLQJJ',
                'app_code': 'iYhTbrpb25W671WE_MK7Cw',
                'locationId': option.id
            }
        }).then(async response => {
            const view = response.data.Response.View
            if (view.length > 0 && view[0].Result.length > 0) {
                const location = view[0].Result[0].Location;
                await setLoc({
                    "coordinates": [
                        location.DisplayPosition.Latitude,
                        location.DisplayPosition.Longitude
                    ],
                    "type": "Point",
                    "locationId": option.id
                });
            }
        });
    };
    const showMap = async () => {
        debugger;
        setIsModalMapVisible(true);
    }
    const loadHospital = async () => {
        setLoading(true);
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_hospital) {
            await axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?created_by=${user._id}`)
                .then(async res => {
                    if (res.data.data && res.data.data.docs.length > 0) {
                        if (res.data.data.docs[0].area) {
                            await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${res.data.data.docs[0].area}`)
                                .then(async res => {
                                    if (res && res.data && res.data.code === 200) {
                                        await setCountryData(res.data.data.docs);
                                    }

                                }).catch(error => message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!' + error.message, 5));
                        }
                        // load city by country if exist country
                        if (res.data.data.docs[0].country) {
                            // load city
                            await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${res.data.data.docs[0].country}`)
                                .then(async res => {
                                    if (res && res.data && res.data.code === 200) {
                                        await setCityData(res.data.data.docs);
                                    }
                                }).catch(error => message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!' + error.message, 5));
                        }
                        // load district by city if exist city
                        if (res.data.data.docs[0].city) {
                            // load city
                            await axiosInstance.get(`${CONSTANT.API.DESTINATION.DISTRICT}?perPage=-1&city=${res.data.data.docs[0].city}`)
                                .then(async res => {
                                    if (res && res.data && res.data.code === 200) {
                                        await setDistrictData(res.data.data.docs);
                                    }
                                }).catch(error => message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!' + error.message, 5));
                        }
                        if (res && res.data && res.data.code === 200) {
                            const data = res.data.data.docs[0];
                            setId(data._id);
                            setLoc(data.loc);
                            form.setFieldsValue({
                                type: data?.type._id,
                                name: data?.name,
                                license_operating: data?.license_operating,
                                license_issue_date: moment(data?.license_issue_date),
                                person_in_charge: data?.person_in_charge,
                                timeserving: data?.timeserving,
                                scope_of_specialty: data?.scope_of_specialty,
                                description: data?.description,
                                introduction: data?.introduction,
                                strengths: data?.strengths,
                                equipment: data?.equipment,
                                ground_plan: data?.ground_plan,
                                process_medical_examination: data?.process_medical_examination,
                                phone: data?.phone,
                                address: data?.address,
                                area: data?.area,
                                country: data?.country,
                                city: data?.city,
                                district: data?.district,
                                total_vote: data?.total_vote,
                                status: "publish"
                                //status: data.status && data.status === "publish" ? "approve" : data.status
                            });
                            if (data.images && data.images.length > 0) {
                                let images = [];
                                for (let i = 0; i < data.images.length; i++) {
                                    let image = data.images[i];
                                    images = images.concat({
                                        uid: image._id,
                                        name: CONSTANT.STATIC_URL + image.filename,
                                        status: 'done-add',
                                        url: CONSTANT.STATIC_URL + image.filename,
                                        thumbUrl: CONSTANT.STATIC_URL + image.filename
                                    });
                                }
                                setImageList(images);
                            }
                        }
                    }
                    else {
                        message.warning('Thông tin cơ sở y tế của bạn chưa được tạo. Vui lòng cập nhật thông tin cho cơ sở y tế của bạn !', 8)
                    }
                })
                .catch(error => message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!', 5).then(() => props.history.push('/')))
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác cơ sở y tế với Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
        }
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cơ sở y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thông tin đăng ký
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        _id: "",
                        name: "",
                        description: "",
                        introduction: '',
                        strengths: '',
                        list_specialty: '',
                        equipment: '',
                        ground_plan: '',
                        process_medical_examination: "",
                        address: "",
                        area: null,
                        country: null,
                        city: null,
                        total_vote: "",
                        loc: null,
                        related: [],
                        facilities: [],
                        specialties: [],
                        images: null,
                        type: null,
                        status: "publish",
                        content: ''
                    }}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Row gutter={24}>
                                <Col xs={24} sm={10} md={6}>
                                    <Form.Item
                                        label="Loại cơ sở y tế"
                                        name="type"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng chọn loại cơ sở y tế !' }
                                        ]}
                                    >
                                        <Select
                                            placeholder="Chọn loại cơ sở y tế"
                                            optionFilterProp="children"
                                            onChange={onChangeSelect('type')}
                                            style={{ width: '100%' }}>
                                            {
                                                typeData.length > 0 &&
                                                typeData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={14} md={18}>
                                    <Form.Item
                                        label="Tên cơ sở y tế"
                                        name="name"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập tên cơ sở y tế!' },
                                        ]}
                                    >
                                        <Input placeholder="Tên cơ sở y tế" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xs={24} sm={14} md={6}>
                                    <Form.Item
                                        label="Giấy phép hoạt động"
                                        name="license_operating"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập giấy phép !' },
                                            { max: 30, message: 'Tối đa 30 ký tự !' }
                                        ]}
                                    >
                                        <Input placeholder="Nhập giấy phép hoạt động" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={14} md={6}>
                                    <Form.Item label="Ngày cấp" name="license_issue_date">
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            showNow={false}
                                            placeholder="Chọn ngày cấp"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={10} md={6}>
                                    <Form.Item
                                        label="Thời gian làm việc"
                                        name="timeserving"
                                        rules={[
                                            { max: 50, message: 'Tối đa 50 ký tự !' }
                                        ]}
                                    >
                                        <Input placeholder="Nhập thời gian làm việc" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={10} md={6}>
                                    <Form.Item
                                        label="Điện thoại liên hệ"
                                        name="phone"
                                        rules={[
                                            { max: 25, message: 'Điện thoại tối đa 25 ký tự !' }
                                        ]}
                                    >
                                        <Input placeholder="Điện thoại liên hệ" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={10} md={12}>
                                    <Form.Item label="Địa chỉ" name="address">
                                        <AutoComplete
                                            options={addressHereMaps}
                                            style={{ width: '100%' }}
                                            onSelect={(value, option) => {
                                                onSelectAddress(value, option);
                                            }}
                                            onSearch={(value) => {
                                                loadAddressHereMap(value);
                                            }}
                                            placeholder="Nhập từ khóa"
                                        >
                                        </AutoComplete>
                                    </Form.Item>

                                    <Button
                                        type="link"
                                        onClick={() => showMap()}
                                    >Show map
                                    </Button>
                                </Col>
                                <Col xs={24} sm={10} md={12}>
                                    <Form.Item
                                        label="Người phụ trách chuyên môn"
                                        name="person_in_charge"
                                        rules={[
                                            { required: true, message: 'Vui lòng chọn loại cơ sở y tế !' }
                                        ]}
                                    >
                                        <Input placeholder="Người phụ trách chuyên môn" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Phạm vị chuyên môn"
                                name="scope_of_specialty"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập phạm vị chuyên môn !' }
                                ]}
                            >
                                <Input placeholder="Nhập phạm vị chuyên môn" />
                            </Form.Item>
                            <Form.Item
                                label="Mô tả" name="description"
                                tooltip="Mô tả ngắn gọn"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập mô tả ngắn gọn' },
                                ]}>
                                <Input.TextArea placeholder="Nhập mô tả ngắn gọn" rows={2} />
                            </Form.Item>
                            <Form.Item label="Giới thiệu chung"
                                name="introduction"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập giới thiệu chung' },
                                ]}>
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                            <Form.Item label="Thế mạnh chuyên môn"
                                name="strengths"
                            >
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                            <Form.Item
                                label="Trang thiết bị"
                                name="equipment"
                            >
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                            <Form.Item
                                label="Sơ đồ mặt bằng"
                                name="ground_plan"
                            >
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                            <Form.Item
                                label="Quy trình khám"
                                name="process_medical_examination"
                            >
                                <JoditEditor config={configEditor} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            {/* Begin Images */}
                            <Form.Item label="Hình ảnh">
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={800 / 600}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        listType="picture-card"
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                        fileList={imageList}
                                        onPreview={onPreviewImages}
                                        onChange={onChangeImages}
                                        headers={{
                                            'X-TK': CONSTANT.API.KEY
                                        }}
                                        onRemove={onRemove}
                                    >
                                        {
                                            imageList.length < 15 &&
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Chọn hình</div>
                                            </div>
                                        }
                                    </Upload>
                                </ImgCrop>
                                <Modal
                                    visible={previewVisible}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={() => setPreviewVisible(false)}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            {/* End Images */}
                            <Form.Item label="Khu vực"
                                name="area"
                                required tooltip="Khu vực: Châu Á, Châu Âu, Châu Mỹ"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn khu vực!' }
                                ]}>
                                <Select
                                    placeholder="Chọn khu vực"
                                    onChange={onChangeSelect('area')}
                                    style={{ width: '100%' }}>
                                    {
                                        areaData.length > 0 &&
                                        areaData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Quốc gia"
                                name="country"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn quốc gia!' }
                                ]}>
                                <Select
                                    showSearch
                                    placeholder="Chọn quốc gia"
                                    optionFilterProp="children"
                                    onChange={onChangeSelect('country')}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}>
                                    {
                                        countryData.length > 0 &&
                                        countryData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Thành phố"
                                name="city"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn thành phố!' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn thành phố"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onChange={onChangeSelect('city')}
                                    onFocus={COMMON.offAutoCompleteSelect()}
                                >
                                    {
                                        cityData.length > 0 &&
                                        cityData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Quận/huyện"
                                name="district"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn quận/huyện!' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn quận/huyện"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}
                                >
                                    {
                                        districtData.length > 0 &&
                                        districtData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>

                            {/* <Form.Item label="Trạng thái" name="status">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}>
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item> */}
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />}
                                htmlType="submit"
                            >
                                Lưu thông tin
                            </Button>
                        </div>

                    </div>
                </Form>
                <ModalShowMap
                    visible={isModalMapVisible}
                    cancel={(data) =>{
                        setIsModalMapVisible(false); 
                        debugger;
                        console.log(data)}}
                    data={loc}
                />
            </Layout.Content>
        </div>
    )

}

export default InfoHospital;
