import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Row, Col, Input, Select, message, InputNumber } from 'antd';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
const ModalActionSpecialty = (props) => {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [specialties, setSpecialties] = useState([]);
    const handleCancel = useCallback(() => {
        form.resetFields();
        props.cancel(false);
    }, [form]);
    useEffect(() => {
        loadSpecialties();
        form.setFieldsValue({
            summary: props.data?.summary,
            name_other: props.data?.name_other,
            sort: props.data?.sort
        })
    }, [props.data]);

    const selected = async (value) => {
        for (let item of props.choosed) {
            if (item === value) {
                message.warning('Khoa này đã được thêm vào danh sách khoa.');
                break;
            }
        }

    }
    // Xử lý lưu
    const onSubmit = useCallback(async (values) => {
        setConfirmLoading(true);
        if (props.data) {
            debugger;
            let dataSave = {
                action: 'updSpecialty',
                specialtyId: props.data._id,
                summary: values.summary,
                name_other: values.name_other,
                sort: values.sort,
            }
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospitalId}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Sửa thông tin thành công. Nội dung của bạn sẽ được kiểm duyệt trước khi được công bố đến khách truy cập !')
                        form.resetFields();
                        props.cancel(true);
                    }
                    else {
                        message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                    }
                    setConfirmLoading(false);
                });
        }
        else {
            for (let item of props.choosed) {
                if (item.specialty._id === values.specialty) {
                    message.warning('Phân loại chuyên khoa đã được thêm vào danh sách chuyên khoa.');
                    setConfirmLoading(false);
                    return;
                }
            }
            let dataSave = {
                action: 'insSpecialty',
                specialtyId: values.specialty,
                summary: values.summary,
                name_other: values.name_other,
                sort: values.sort,
            }
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${props.hospitalId}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.data && res.data.code === 200) {
                        message.success('Thêm khoa thành công. Nội dung của bạn sẽ được kiểm duyệt trước khi được công bố đến khách truy cập !')
                        form.resetFields();
                        props.cancel(true);
                    }
                    else {
                        message.error('Thêm khoa thất bại! Vui lòng thử lại sau.');
                    }
                    setConfirmLoading(false);
                });

        }
        setConfirmLoading(false);
    }, [props]);
    // load Specialties
    const loadSpecialties = async () => {
        await axiosInstance.get(`${CONSTANT.API.HOSPITAL.SPECIALTIES}?perPage=-1`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setSpecialties(res.data.data.docs);
                }
            });
    }
    return (
        <Modal title={props.data ? props.data.name : 'Thêm khoa'}
            visible={props.visible}
            onOk={form.submit}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Lưu thông tin"
            confirmLoading={confirmLoading}
            className="modal-book-facility"
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            okButtonProps={{ className: "btn-global bg" }}
            maskClosable={false}
        >
            <Form
                name="basic"
                layout="vertical"
                onFinish={onSubmit}
                onFinishFailed={() => { }}
                form={form}
            >
                <Row gutter={[24]} align="middle">
                    <Col xs={24} lg={24}>
                        {
                            !props.data &&
                            <Form.Item label="Phân loại chuyên khoa" name="specialty" required tooltip="Vui lòng chọn trường này">
                                <Select placeholder="Chọn chuyên khoa" className="select-location" size='large'
                                    onChange={(value) => selected(value)}>
                                    {
                                        specialties.length > 0 &&
                                        specialties.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        }
                        <Form.Item label="Tên khoa" name="name_other" required tooltip="Vui lòng nhập trường này"
                            rules={[
                                {
                                    required: true, message: 'Vui lòng nhập tên khoa',

                                }, {
                                    type: 'string', max: 100, message: "Tên khoa tối đa 100 ký tự !"
                                }
                            ]}>
                            <Input placeholder="Nhập tên khoa" />
                        </Form.Item>
                        <Form.Item
                            label="Mô tả"
                            tooltip="Mô tả ngắn về khoa"
                            name='summary'
                            rules={[
                                { required: true, message: 'Vui lòng nhập mô tả ngắn gọn' }
                                ,
                                {
                                    type: 'string', max: 1000, message: "Mô tả ngắn gọn tối đa 1000 ký tự !"
                                }
                            ]}>
                            <Input.TextArea placeholder="Nhập mô tả ngắn gọn về khoa" rows={8} />
                        </Form.Item>
                        <Form.Item
                            label="Thứ tự sắp xếp"
                            name='sort'
                            rules={[
                                { required: true, message: 'Vui lòng nhập thứ tự sắp xếp' },
                                {
                                    type: 'number', max: 10000, message: "Thứ tự sắp xếp tối đa 10000 !"
                                }
                            ]}>
                            <InputNumber placeholder="Nhập thứ tự sắp xếp" min={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\\s?|(,*)/g, '')}
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}
export default ModalActionSpecialty;