import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, message, Space, Button, Tag, Form, Select, DatePicker, Input
} from 'antd';
import moment from 'moment';
import { HomeOutlined, EyeOutlined, SearchOutlined, RedoOutlined } from '@ant-design/icons';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';
import { axiosInstance } from '../../../config/api';
import ModalShopDetailOrder from './detail';
const arrStatus = {
    'wait-paid': { text: "Chờ thanh toán", color: "orange" },
    'paid': { text: 'Đã thanh toán', color: 'green' },
    'confirmed': { text: 'Đã xác nhận', color: 'blue' },
    'packing': { text: 'Đang đóng gói', color: 'orange' },
    'delivery-in-progress': { text: 'Đang giao hàng', color: 'orange' },
    'customer-received': { text: 'Khách đã nhận hàng', color: 'green' },
    'completed': { text: 'Đã hoàn thành', color: 'green' },
    'abandoned': { text: 'Khách đã hủy', color: 'red' },
    'cancelled': { text: 'Bạn đã hủy đơn này', color: 'red' },
    'refunded': { text: 'Đã hoàn hoàn tiền', color: 'orange' },
};
const ShopListOrder = (props) => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: '#',
            dataIndex: 'uid',
        },

        {
            title: 'Họ tên',
            width: '170px',
            dataIndex: 'shipping',
            render: shipping => <label> {shipping.fullname}</label>
        },
        {
            title: 'Email',
            dataIndex: 'shipping',
            render: shipping => shipping.email
        },
        {
            title: 'Điện thoại',
            dataIndex: 'shipping',
            render: shipping => '+' + shipping.phone
        },
        {
            title: 'Ngày đặt hàng', dataIndex: 'created_at', width: '170px',
            render: created_at => `${moment(new Date(created_at)).format("DD/MM/YYYY HH:mm")}`
        },
        {
            title: 'Ngày thanh toán', dataIndex: 'date_paymented', width: '170px',
            render: date_paymented => date_paymented ? `${moment(new Date(date_paymented)).format("DD/MM/YYYY HH:mm")}` : ""
        },
        {
            title: 'Tổng tiền', width: '170px', align: 'right',
            render: (text, record) => COMMON.currencyFormat(parseFloat(record.shop[0]?.shop_total)) + ' ' + COMMON.getCurrency(record.currency)
        },
        {
            title: 'Trạng thái',
            render: (text, record) => <Tag color={arrStatus[record.shop[0]?.status_order]?.color}>{arrStatus[record.shop[0]?.status_order]?.text}</Tag>
        },

        {
            title: 'Hành động', width: '150px',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => openOrderService(record._id)}><EyeOutlined /> Xem chi tiết</Button>
                </Space>
            )
        },
    ];
    const [listService, setListService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalServiceVisible, setIsModalServiceVisible] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0,
        perPage: 10,
        hideOnSinglePage: true,
        showLessItems: true
    });
    const openOrderService = async (data) => {
        await setOrderId(data);
        setIsModalServiceVisible(true);
    }
    // effect
    useEffect(() => {
        loadOrder({ page: 1, perPage: 10 });
    }, []);
    // Xử lý sự kiện search
    const onSearch = async (values) => {
        let options = {};
        if (values.q !== '' && values.q !== undefined) {
            if (values.field === 'fullname') {
                options.fullname = values.q;
            }
            else if (values.field === 'email') {
                options.email = values.q;
            }
            else if (values.field === 'phone') {
                options.phone = values.q;
            }
            else if (values.field === 'uid') {
                options.uid = values.q.replace('VIVMEDIC-P', '');
                if (isNaN(+options.uid)) {
                    message.warning('Mã đặt không đúng định dạng. Vui lòng kiểm tra lại !');
                    return;
                }
            }
        }
        if (values.date_range) {
            options.date_range = JSON.stringify({
                start: moment(values.date_range[0]).format("YYYY-MM-DD"),
                end: moment(values.date_range[1]).format("YYYY-MM-DD")
            });
        }
        loadOrder({
            page: 1,
            perPage: pagination.perPage,
            ...options
        });
    }
    const reload = () => {
        loadOrder({ page: 1, perPage: pagination.perPage });
        form.resetFields();
    }
    const loadOrder = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_seller) {
            await axiosInstance.get(`${CONSTANT.API.PRODUCT.ORDER}?${query}&agency=${user._id}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        setTotal(res.data.data.total);
                        setListService(res.data.data.docs);
                    }
                })
                .catch(error => message.warning('Không thể truy cập danh sách đơn hàng!' + error.message, 5).then(() => props.history.push('/')))
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác bán hàng trên Vivmedic, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
        }
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Bán hàng y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Danh sách đơn hàng
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    style={{ marginBottom: 20 }}
                    initialValues={{
                        field: 'fullname'
                    }}
                    style={{ margin: '0px -8px 20px -8px' }}
                    form={form}
                >
                    <Form.Item name="q"
                        // required rules={[
                        //     { required: true, message: 'Vui lòng nhập thông tin' }
                        // ]}
                        extra="(VD: Nguyễn Văn A hoặc VIVMEDIC-P123)"
                    >
                        <Input
                            type="text"
                            placeholder="Nhập tên người đặt hoặc mã đặt"
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select
                            style={{ width: 150 }}
                        >
                            <Select.Option value="fullname">Tên người đặt</Select.Option>
                            <Select.Option value="phone">Điện thoại</Select.Option>
                            <Select.Option value="email">Email</Select.Option>
                            <Select.Option value="uid">Mã đặt</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range">
                        <DatePicker.RangePicker format="DD-MM-YYYY" />
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Tải lại trang
                        </Button>
                    </Form.Item>
                </Form>
                {/* End form search */}
                {
                    listService && <Table
                        dataSource={listService}
                        columns={columns}
                        loading={loading}
                        pagination={{
                            onChange: async (page, pageSize) => {
                                loadOrder(page, pageSize);
                            },
                            pageSize: 10,
                            total: total,
                            hideOnSinglePage: true
                        }}
                        rowKey={record => record._id}
                    />
                }

            </Layout.Content>
            <ModalShopDetailOrder visible={isModalServiceVisible} orderId={orderId}
                cancel={(reload) => {
                    setIsModalServiceVisible(false);
                    if (reload) {
                        loadOrder({ page: 1, perPage: pagination.perPage });
                        form.resetFields();
                    }
                }} />
        </div >
    )

}

export default ShopListOrder;