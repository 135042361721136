import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, message, Space, Button, Popconfirm, Form, Select, Input,Divider,Avatar
} from 'antd';
import moment from 'moment';
import { HomeOutlined, SearchOutlined, RedoOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
const ListService = (props) => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: '#',
            width: '50px',
            dataIndex: 'uid',
        },
        {
            title: 'Ảnh', dataIndex: 'images',
            render: images => images && images.length > 0 && <Avatar size={48} shape="square" src={`${CONSTANT.STATIC_URL}${images[0].filename}`} />,
            width: '50px'
        },
        {
            title: 'Tên dịch vụ',
            width: '170px',
            dataIndex: 'name'
        },
        { title: 'Tỉnh/Thành phố', dataIndex: 'city', width: '150px', render: city => city ? `${city.name}` : "" },
        { title: 'Quận/Huyện', dataIndex: 'district', width: '150px', render: district => district ? `${district.name}` : "" },
        {
            title: 'Ngày tạo', dataIndex: 'created_at', width: '170px',
            render: created_at => `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`
        },
        {
            title: 'Hành động', width: '200px',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/service/edit/${record._id}`}><EditOutlined /> Sửa</Link>
                    <Divider type="vertical" />
                    <Popconfirm title="Bạn muốn xóa dịch vụ này?" onConfirm={() => this.handleDelete(record._id)}>
                        <Button type="link"><DeleteOutlined /> Xóa</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const [listService, setListService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0,
        perPage: 1,
        hideOnSinglePage: true,
        showLessItems: true
    });
    const delService = async (id) => {
        setLoading(true);
        await axiosInstance.delete(`${CONSTANT.API.SERVICE.SERVICE}/${id}`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Xóa dịch vụ thành công', 5)
                }
                setLoading(false)
            })
            .catch(error => { message.error('Xóa dịch vụ không thành công', 5); setLoading(false) })
    }
    // effect
    useEffect(() => {
        loadService({ page: 1, perPage: 10 });
    }, []);
    // Xử lý sự kiện search
    const onSearch = async (values) => {
        let options = {};
        if (values.q !== '' && values.q !== undefined) {
            if (values.field === 'name') {
                options.name = values.q;
            }
            else if (values.field === 'uid') {
                if (isNaN(+values.q)) {
                    message.warning('Mã dịch vụ là kiểu số. Vui lòng kiểm tra lại !');
                    return;
                }
                else {
                    options.uid = values.q
                }
            }
        }
        loadService({
            page: 1,
            perPage: pagination.pageSize,
            ...options
        });
    }
    const reload = () => {
        loadService({ page: 1, perPage: pagination.pageSize });
        form.resetFields();
    }
    const loadService = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        await axiosInstance.get(`${CONSTANT.API.SERVICE.SERVICE}?${query}&created_by=${user._id}`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    let _pagination = { ...pagination, current: queryParam.page };
                    _pagination.total = res.data.data.total;
                    setPagination(_pagination);
                    setListService(res.data.data.docs);
                }
            })
            .catch(error => message.warning('Không thể truy cập danh sách dịch vụ!' + error.message, 5))
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Dịch vụ y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Danh sách dịch vụ
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    initialValues={{
                        field: 'name'
                    }}
                    style={{ margin: '0px -8px 20px -8px' }}
                    form={form}
                >
                    <Form.Item name="q">
                        <Input
                            type="text"
                            placeholder="Nhập mã hoặc tên dịch vụ"
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select
                            style={{ width: 150 }}
                        >
                            <Select.Option value="name">Tên dịch vụ</Select.Option>
                            <Select.Option value="uid">Mã dịch vụ</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Tải lại trang
                        </Button>
                    </Form.Item><Form.Item>
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => props.history.push('/service/add')} >
                            Thêm mới
                        </Button>
                    </Form.Item>
                </Form>
                {
                    listService && <Table
                        dataSource={listService}
                        columns={columns}
                        loading={loading}
                        pagination={{
                            onChange: async (page, pageSize) => {
                                let para = { page, perPage: pageSize };
                                loadService(para);
                            },
                            ...pagination
                        }}
                        rowKey={record => record._id}
                    />
                }

            </Layout.Content>            
        </div >
    )

}

export default ListService;