import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Divider, message, InputNumber, Form, DatePicker, TimePicker, Select, Button, Row, Col, Tag
} from 'antd';
import { HomeOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import moment from 'moment';
const { RangePicker } = DatePicker;
const CalendarSetting = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState([]);
    const [hackValue, setHackValue] = useState();
    const [value, setValue] = useState();
    const [existDate, setExistDate] = useState(null);
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return current && current < moment().startOf("day");
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 15;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 15;
        return tooEarly || tooLate || current < moment().startOf("day");
    };

    const onOpenChange = open => {
        if (open) {
            setHackValue([]);
            setDates([]);
        } else {
            setHackValue(undefined);
        }
    };
    const onFinish = async (values) => {
        let calendars = [];
        let calendarsSave = [];
        if (existDate) {
            message.error('Vui lòng kiểm tra lại lịch cài đặt bị trùng !');
            return;
        }
        if (values.worklist) {
            for (let work of values.worklist) {
                let check = false;
                let calendar = {
                    service_id: work.service_id,
                    day_of_week: 1, day: 1,
                    month: 1, year: 1,
                    times: work.times
                }
                for (let i = 0; i < calendars.length; i++) {
                    //kiểm tra có trùng dịch vụ thì concat vào dịch vụ đã được lập lịch
                    if (calendars[i].service_id === work.service_id) {
                        calendars[i].times = calendars[i].times.concat(work.times);
                        check = true;
                        break;
                    }
                }
                if (!check) {
                    //nếu không trùng thì push mới lịch
                    calendars.push(calendar);
                }
            }

            for (let m = moment(values.dates[0]); m.isSameOrBefore(values.dates[1]); m.add(1, 'days')) {
                //duyệt ngày để thêm lịch cài đặt
                if (values.freeDay.indexOf(m.day().toString()) === -1) {
                    for (let item of calendars) {
                        item.day_of_week = m.day();
                        item.day = m.date();
                        item.month = m.month() + 1;
                        item.year = m.year();
                        calendarsSave.push({ ...item });
                    }
                }

            }
            await axiosInstance.patch(`${CONSTANT.API.DOCTOR.DOCTOR}/${props.location.state._id}`, { calendarsSave: calendarsSave, action: 'insCalendar' })
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Lập lịch thành công !');
                        form.resetFields();
                    }
                    else {
                        message.error('Lập lịch thất bại ! Vui lòng thử lại sau !');
                    }
                }).catch(error => message.error('Lập lịch thất bại ! Vui lòng thử lại sau !').then(console.log(error)));
        }
        else {
            message.warning('Vui lòng thêm cài đặt thời gian làm việc trong ngày cho lần lập lịch này, cảm ơn !', 7);
        }
    }
    const onChangeRenderTime = async (value, index) => {
        const service = props.location.state.services ? props.location.state.services.find(x => x._id === value) : null;
        const worklist = form.getFieldValue('worklist');
        const updatedWorklist = await worklist.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    times: getTimesArray(item.start_time, item.end_time, service && service.duration ? service.duration + (item.free_time ? item.free_time : 0) : 0, item.address)
                }
            }
            return item;
        })
        form.setFieldsValue({ worklist: updatedWorklist });
    }
    function getTimesArray(start, end, length, address) {
        let times = [];
        if (length > 5) {
            let startMin = start.hour() * 60 + start.minutes()
            let endMin = end.hour() * 60 + end.minutes()
            while (startMin <= endMin) {
                let mins = startMin % 60
                let hours = Math.floor(startMin / 60)
                times.push({ time_h: hours, time_m: mins, is_order: false, address: address })
                startMin += length
            }
        }
        return times
    }
    const checkExistDate = async (datesRange) => {
        await setExistDate(null);
        if (datesRange && datesRange.length > 1) {
            let startDate = datesRange[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            let endDate = datesRange[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            let check = false;
            for (let service of props.location.state.services) {
                for (let calendar of service.calendars) {
                    let dateCheck = moment(calendar.day + '-' + calendar.month + '-' + calendar.year, 'DD-MM-YYYY');
                    check = dateCheck.isSameOrAfter(startDate) && dateCheck.isSameOrBefore(endDate);
                    if (check) {
                        await setExistDate(service.name + ' - ' + calendar.day + '/' + calendar.month + '/' + calendar.year);
                        break;
                    }
                    if (check) break;
                }
                if (check) break;
            }
            return check;
        }
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Chuyên môn
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cài đặt lịch dịch vụ
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        freeDay: []
                    }}
                >
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item
                                label="Ngày cài đặt (từ ngày, đến ngày)"
                                required tooltip="Vui lòng chọn trường này"
                                name="dates"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn từ ngày đến ngày cài đặt' },
                                ]}
                            >
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    value={hackValue || value}
                                    disabledDate={disabledDate}
                                    onCalendarChange={val => setDates(val)}
                                    onChange={val => {
                                        if (props.location.state) {
                                            checkExistDate(val);
                                            if (!existDate)
                                                setValue(val);
                                        }
                                    }}
                                    onOpenChange={onOpenChange}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            {
                                existDate && <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert">{'Lịch cài đặt bị trùng ngày với lịch ' + existDate}</div></div>
                            }

                        </Col>
                        <Col xs={24} sm={24} md={18}>
                            <Form.Item
                                label="Ngày nghỉ" name="freeDay"
                                tooltip="Ngày nghỉ sẽ không xếp lịch làm việc"
                            >
                                <Select
                                    mode="multiple"
                                    optionFilterProp="children"
                                    placeholder="Vui lòng chọn ngày nghỉ"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        [1, 2, 3, 4, 5, 6, 0].map(item => <Select.Option key={item}>{item > 0 ? "Thứ " + (item + 1) + "" : "Chủ nhật"}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}> <Divider>Cài đặt ngày làm việc</Divider></Col>
                    </Row>
                    <Form.List name="worklist">
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {
                                        fields.map((field, index) => (
                                            <Row gutter={24} key={index}>
                                                <Col xs={6} sm={3} md={3}>
                                                    <Form.Item
                                                        label="Từ giờ"
                                                        name={[index, 'start_time']}
                                                        required tooltip="Vui lòng chọn trường này"
                                                        rules={[
                                                            { required: true, message: 'Vui lòng chọn' },
                                                        ]}
                                                    >
                                                        <TimePicker format="HH:mm" showNow={false} placeholder="Từ giờ" minuteStep={5} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={6} sm={3} md={3}>
                                                    <Form.Item
                                                        label="Đến giờ"
                                                        name={[index, 'end_time']}
                                                        required tooltip="Vui lòng chọn trường này"
                                                        rules={[
                                                            { required: true, message: 'Vui lòng chọn' },
                                                        ]}
                                                    >
                                                        <TimePicker format="HH:mm" showNow={false} placeholder="Đến giờ" minuteStep={5} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={6} sm={3} md={3}>
                                                    <Form.Item
                                                        label="Nghỉ"
                                                        required tooltip="Thời gian nghỉ giữa 2 ca liên tiếp"
                                                        name={[index, 'free_time']}
                                                    >
                                                        <InputNumber
                                                            max={120} min={0}
                                                            style={{ width: '100%' }}
                                                            defaultValue={0}
                                                            formatter={value => `Phút ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\Phút\s?|(,*)/g, '')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={6} sm={6} md={7}>
                                                    <Form.Item
                                                        label="Chọn nơi làm việc"
                                                        name={[index, 'address']}
                                                        required tooltip="Vui lòng chọn trường này"
                                                        rules={[
                                                            { required: true, message: 'Vui lòng chọn nơi làm việc' },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder="Chọn nơi làm việc"
                                                            optionFilterProp="children"
                                                            style={{ width: '100%' }}>
                                                            {
                                                                props.location.state && props.location.state.addresses.length > 0 &&
                                                                props.location.state.addresses.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={6} sm={6} md={6}>
                                                    <Form.Item
                                                        label="Chọn dịch vụ"
                                                        name={[index, 'service_id']}
                                                        required tooltip="Vui lòng chọn trường này"
                                                        rules={[
                                                            { required: true, message: 'Vui lòng chọn dịch vụ' },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder="Chọn dịch vụ"
                                                            optionFilterProp="children"
                                                            onChange={e => onChangeRenderTime(e, field.key)}
                                                            style={{ width: '100%' }}>
                                                            {
                                                                props.location.state && props.location.state.services.length > 0 &&
                                                                props.location.state.services.map(item => <Select.Option key={item._id}>{item.name + ' (' + item.duration + ' phút)'}</Select.Option>)
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={6} sm={6} md={2}>
                                                    <Button
                                                        type="link"
                                                        danger
                                                        style={{ marginTop: 30 }}
                                                        onClick={() => remove(field.name)}
                                                        icon={<MinusCircleOutlined />}
                                                    >
                                                        Xóa
                                                    </Button>
                                                </Col>
                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.List name={[field.name, 'times']}>
                                                        {(times, { add, remove }) => {
                                                            return (
                                                                <>
                                                                    {times.map((field1, index1) => (
                                                                        <Tag key={field1.key} closable style={{ marginBottom: 20 }} onClose={() => remove(field.name)}>{form.getFieldValue('worklist')[index].times[index1].time_h + ':' + form.getFieldValue('worklist')[index].times[index1].time_m} </Tag>
                                                                    ))}
                                                                </>
                                                            );
                                                        }}
                                                    </Form.List>
                                                </Col>
                                            </Row>
                                        ))}
                                    <Row gutter={24}>
                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{ width: "100%" }}
                                                >
                                                    <PlusOutlined /> Thêm cài đặt
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </>
                            )
                        }}
                    </Form.List>
                    <Button type="primary"
                        htmlType="submit"
                    >
                        Lưu thông tin
                    </Button>
                </Form>

            </Layout.Content>
        </div>
    )

}

export default CalendarSetting;
