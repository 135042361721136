import React, { useState, useCallback, useEffect } from 'react';
import {
    Modal, message, Tabs, Table,Tag
} from 'antd';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import moment from 'moment';
const ModalHistoryVoucher = (props) => {
    const [loading, setLoading] = useState(false);
    const [voucher, setVoucher] = useState();
    const columnVoucher_Codes = [
        {
            title: 'Mã voucher',
            dataIndex: 'code',
        },
        {
            title: 'Ngày sử dụng',
            dataIndex: 'use_date',
            render: use_date => use_date? `${moment(new Date(use_date)).format("DD/MM/YYYY HH:mm")}`:''
        },
        {
            title: 'Trạng thái',
            dataIndex: 'is_used',
            render: is_used => is_used ? <Tag color="red">Đã sử dụng</Tag> : <Tag color="green">Chưa sử dụng</Tag>
        },
        {
            title: 'Người dùng',
            dataIndex: 'user_apply.email',
        },
        {
            title: 'Đơn hàng áp dụng',
            dataIndex: 'order_apply',
        }
    ];
    const columnBonus_Codes = [
        {
            title: 'Mã khuyến mãi thêm',
            dataIndex: 'code',
        },
        {
            title: 'Mã voucher đã sử dụng',
            dataIndex: 'parent_code',
        },
        {
            title: 'Ngày cấp thêm',
            dataIndex: 'release_date',
            render: release_date => release_date? `${moment(new Date(release_date)).format("DD/MM/YYYY HH:mm")}`:''
        },
        {
            title: 'Ngày sử dụng',
            dataIndex: 'use_date',
            render: use_date => use_date? `${moment(new Date(use_date)).format("DD/MM/YYYY HH:mm")}`:''
        },
        {
            title: 'Trạng thái',
            dataIndex: 'is_used',
            render: is_used => is_used ? <Tag color="red">Đã sử dụng</Tag> : <Tag color="green">Chưa sử dụng</Tag>
        },
        {
            title: 'Người dùng',
            dataIndex: 'user_apply',
        },
        {
            title: 'Đơn hàng áp dụng',
            dataIndex: 'order_apply',
        }
    ];
    const handleCancel = useCallback(() => {
        props.cancel();
    }, []);
    useEffect(() => {
        loadVoucher();
    }, [props.voucherId]);
    const loadVoucher = async () => {
        setLoading(true);
        if (props?.voucherId) {
            await axiosInstance.get(`${CONSTANT.API.SERVICE.VOUCHER}/${props?.voucherId}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        setVoucher(res.data.data);
                    }
                    else {
                        message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!', 4)
                    }
                })
                .catch(error => { message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!'); console.log(error) })
        }
        setLoading(false);
    }
    return (
        <Modal title={'Lịch sử chương trình'}
            visible={props.visible}
            onCancel={handleCancel}
            cancelText="Hủy"
            className="modal-book-facility"
            width={'1000px'}
            centered
            cancelButtonProps={{ className: "btn-global bg btn-cancel" }}
            footer={null}
            maskClosable={false}
            loading={loading}
        >
            <Tabs defaultActiveKey="gt" type="card" size="large" className="tabs-info">
                <Tabs.TabPane forceRender={true} tab="Mã voucher" key="vouchers">
                    {
                        voucher && <Table dataSource={voucher.voucher_codes}
                            columns={columnVoucher_Codes} loading={loading}
                            pagination={true}
                            rowKey={record => record.code} />
                    }
                </Tabs.TabPane>
                <Tabs.TabPane forceRender={true} tab="Mã khuyến mãi thêm" key="voucher_bonus">
                    {
                        voucher && <Table dataSource={voucher.code_bonus_extra}
                            columns={columnBonus_Codes} loading={loading}
                            pagination={true}
                            rowKey={record => record.code} />
                    }
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    )
}
export default ModalHistoryVoucher;