import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, message, Popconfirm, Divider, Space, Button
} from 'antd';
import { HomeOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import ModalActionSpecialty from './components/action-spectialty'
const Specialties = (props) => {
    const columns = [
        {
            title: 'STT',
            key: 'index',
            width: '30px',
            render : (text, record, index) => index+1,
          },
        {
            title: 'Tên khoa',
            dataIndex: 'name_other',
        },
        {
            title: 'Sắp xếp',
            width: '100px',
            dataIndex: 'sort',
        },
        {
            title: (<div style={{ textAlign: 'center' }}>Hành động</div>), width: '100px',
            render: (record) => (
                <Space size="middle">
                    <Button
                        type='link'
                        onClick={() => openActionSpecialty({ _id: record?._id, name: record?.specialty?.name, name_other: record.name_other, summary: record.summary, sort: record.sort, hospitalId: hospital?._id })}
                    ><EditOutlined /> Sửa</Button>
                    <Divider type="vertical" />
                    <Popconfirm title="Bạn muốn xóa khoa này? Các thông tin liên quan đến khoa sẽ bị xóa không thể khôi phục !" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => delSpecialty(record._id)}>
                        <Button type="link"><DeleteOutlined /> Xóa</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const [hospital, setHospital] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [specialties, setSpecialties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [specialtyData, setSpecialtyData] = useState(null);
    const openActionSpecialty = async (data) => {
        await setSpecialtyData(data);
        setIsModalVisible(true);
    }
    // effect
    useEffect(() => {
        loadSpecialties();
    }, []);

    const loadSpecialties = async () => {
        setLoading(true);
        let user = JSON.parse(localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`));
        if (user.is_hospital) {
            await axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}?created_by=${user._id}&action=specialties`)
                .then(async res => {
                    if (res.data.data) {
                        setHospital(res.data.data.hospital);
                        setSpecialties(res.data.data.docs.sort((a, b) => (a.sort > b.sort) ? 1 : -1));
                    }
                    setLoading(false);
                })
                .catch(error => message.warning('Cơ sở y tế không tồn tại!' + error.message, 5).then(() => { setLoading(false); props.history.push('/') }))
        }
        else {
            message.warning('Bạn chưa đăng ký làm đối tác cơ sở y tế với Tatinta, Vui lòng đăng ký và kích hoạt trước khi sử dụng tính năng này, cảm ơn!', 10).then(() => props.history.push('/'))
            setLoading(false);
        }

    }
    const delSpecialty = async (specialtyId) => {
        setLoading(true);
        let dataSave = {
            action: 'delSpecialty',
            specialtyId: specialtyId
        }
        await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${hospital?._id}`, dataSave)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    loadSpecialties();
                    message.success('Xóa khoa thành công !');
                }
                else {
                    message.error('Xóa khoa thất bại! Vui lòng thử lại sau.');
                }
                setLoading(false);
            }).catch(() => {
                message.error('Xóa thất bại! Vui lòng thử lại sau.');
                setLoading(false)
            });;
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cơ sở y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Quản lý chuyên khoa
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                <Button type="primary" primary="true" icon={<PlusOutlined />}
                    onClick={() => openActionSpecialty(null)}
                >
                    Thêm khoa
                </Button>
                {
                    specialties && <Table dataSource={specialties}

                        columns={columns} loading={loading}
                        pagination={false}
                        rowKey={record => record._id} />
                }

            </Layout.Content>
            <ModalActionSpecialty visible={isModalVisible} data={specialtyData} choosed={specialties} hospitalId={hospital?._id}
                cancel={(data) => {
                    setIsModalVisible(false);
                    if (data) {
                        loadSpecialties();
                    }
                }} />
        </div >
    )

}

export default Specialties;